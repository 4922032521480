
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import GamesSlide from "@/components/HomePage/GamesSlide.vue";
import FeedbackBlock from "@/components/HomePage/FeedbackBlock.vue";
import PasswordUI from "@/components/UI/PasswordUI.vue";
import ButtonUI from "@/components/UI/ButtonUI.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import { defineComponent } from "vue";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

import "swiper/css/navigation";
import "swiper/css/pagination";

export default defineComponent({
  name: "GamesBlock",
  components: {
    PasswordUI,
    FeedbackBlock,
    Swiper,
    SwiperSlide,
    GamesSlide,
    ButtonUI,
  },
  data: () => ({
    popupFlag: false as boolean,
    password: "" as string,
    currentSubitems: { id: 1, tags: [] } as any,
  }),
  computed: {
    ...mapState("room", ["games", "excludedTags"]),
    ...mapState("profile", ["profile_data"]),
  },
  watch: {
    excludedTags: {
      handler(excludedTags: any[]) {
        !excludedTags.find((tag) => tag === 18) && (this.popupFlag = true);
      },
      deep: true,
    },
  },
  setup() {
    return {
      modules: [Navigation, Autoplay, Pagination],
    };
  },
  methods: {
    ...mapActions({
      createRoom: "room/createRoom",
      getGames: "room/getGames",
      auth: "profile/auth",
    }),
    ...mapMutations({
      setPopupData: "SET_POPUP_DATA",
    }),
    goPromo() {
      this.profile_data.unique_id ? this.$router.push("/profile") : this.auth();
      this.setPopupData({ root_status: "promocode" });
    },
    disableCond(item: any): boolean {
      return (
        !this.profile_data.unique_id ||
        (!item.is_free && !this.profile_data.is_signed)
      );
    },
    onCreate() {
      this.createRoom({
        password: this.password,
        selected_game: this.currentSubitems,
      });

      this.popupFlag = false;
      this.password = "";
    },
    popupMgr(item: any, popupFlag: boolean, checkAgePopUp: boolean) {
      if (item) {
        if (this.disableCond(item)) {
          this.profile_data.unique_id
            ? this.setPopupData({ status: "need_auth" })
            : this.auth();

          return;
        } else {
          this.currentSubitems.tags[0] = item;
        }
      }

      if (checkAgePopUp) {
        this.excludedTags.find((tag: number) => tag === 18)
          ? this.setPopupData({ status: "18+" })
          : (this.popupFlag = true);
      } else {
        this.popupFlag = popupFlag;
      }
    },
  },
  created() {
    this.getGames();
  },
});
