<template>
  <div class="RightGroupImg">
    <img
      v-for="(img, index) in images"
      :key="index"
      :src="img.src"
      :alt="img.alt"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "RightGroupImg",
  data: () => ({
    images: [
      {
        src: require("@/assets/icons/rightGroupImgHeader/img1.svg"),
        alt: "разнообразные сценарии",
      },
      {
        src: require("@/assets/icons/rightGroupImgHeader/img2.svg"),
        alt: "тимбилдинг онлайн",
      },
      {
        src: require("@/assets/icons/rightGroupImgHeader/img3.svg"),
        alt: "мини игры на русском языке",
      },
      {
        src: require("@/assets/icons/rightGroupImgHeader/img4.svg"),
        alt: "крутые игры онлайн",
      },
      { src: null },
      {
        src: require("@/assets/icons/rightGroupImgHeader/img5.svg"),
        alt: "квиз игра",
      },
      {
        src: require("@/assets/icons/rightGroupImgHeader/img6.svg"),
        alt: "многопользовательские онлайн игры",
      },
      {
        src: require("@/assets/icons/rightGroupImgHeader/img7.svg"),
        alt: "русские онлайн игры",
      },
    ],
  }),
});
</script>

<style lang="sass" scoped>
.RightGroupImg
  position: relative
  display: grid
  grid-template-columns: repeat(2, 150px)
  gap: 20px
  align-items: center

  & > :nth-child(1n)
    align-self: flex-start
    padding: 0 0 20px 0

  & > :nth-child(2n)
    align-self: flex-end
    padding: 30px 0 0 0

@media (max-width: 1300px)
  .RightGroupImg
    gap: unset
    grid-template-columns: repeat(2, 100px)
@media (max-width: 1100px)
  .RightGroupImg
    grid-template-columns: unset
    width: 100%
    display: flex
    justify-content: space-between
@media (max-width: 750px)
  .RightGroupImg
    img
      width: 55px
@media (max-width: 400px)
  .RightGroupImg
    &>:last-child
      display: none
</style>
