import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79451768"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "CalcUI-Tip Tip"
}
const _hoisted_2 = { class: "CalcUI-Inner" }
const _hoisted_3 = { class: "CalcUI-Text" }
const _hoisted_4 = ["value", "disabled"]
const _hoisted_5 = {
  key: 0,
  class: "CalcUI-Unit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _directive_autowidth = _resolveDirective("autowidth")!

  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["CalcUI", [
      _ctx.row && 'CalcUI_row',
      _ctx.disabled && 'CalcUI_disabled',
      !_ctx.validation && 'CalcUI_error',
    ]])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.disabled && _ctx.isButtons)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["CalcUI-Icon Icon Icon_black", _ctx.value <= _ctx.min && 'Icon Icon_disabled']),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onIncrement(false)))
          }, [
            _createVNode(_component_inline_svg, {
              title: "Уменьшить",
              class: "Icon-Image",
              src: require('@/assets/icons/decrement.svg')
            }, null, 8, ["src"])
          ], 2))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          ref: "input",
          class: "CalcUI-Input",
          type: "number",
          value: _ctx.value,
          disabled: _ctx.disabled,
          onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
        }, null, 40, _hoisted_4), [
          [_directive_autowidth]
        ]),
        (_ctx.unit)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.unit), 1))
          : _createCommentVNode("", true)
      ]),
      (!_ctx.disabled && _ctx.isButtons)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["CalcUI-Icon Icon Icon_black", _ctx.value >= _ctx.max && 'Icon Icon_disabled']),
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onIncrement(true)))
          }, [
            _createVNode(_component_inline_svg, {
              title: "Увеличить",
              class: "Icon-Image",
              src: require('@/assets/icons/add.svg')
            }, null, 8, ["src"])
          ], 2))
        : _createCommentVNode("", true)
    ])
  ], 2))
}