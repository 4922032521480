
import { defineComponent } from "vue";
import { mapState, mapActions } from "vuex";

import ButtonUI from "@/components/UI/ButtonUI.vue";
import PopupUI from "@/components/UI/PopupUI.vue";

export default defineComponent({
  name: "PopupNeedAuth",
  emits: ["close"],
  components: {
    PopupUI,
    ButtonUI,
  },
  computed: {
    ...mapState("profile", ["profile_data"]),
  },
  methods: {
    ...mapActions({
      auth: "profile/auth",
    }),
    onKeydown(event: any) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.onSubmit();
      }
    },
    onSubmit() {
      this.$emit("close");
      !this.profile_data.unique_id
        ? this.auth()
        : this.$router.push("/profile");
    },
  },
  mounted() {
    window.addEventListener("keydown", this.onKeydown);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.onKeydown);
  },
});
