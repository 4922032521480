
import { defineComponent } from "vue";
import { mapMutations, mapState } from "vuex";

import PopupUI from "@/components/UI/PopupUI.vue";
import ButtonUI from "@/components/UI/ButtonUI.vue";

export default defineComponent({
  name: "PopupSubscribe",
  emits: ["close"],
  components: { PopupUI, ButtonUI },
  data: () => ({
    isMain: false,
  }),
  computed: {
    ...mapState("profile", ["profile_data", "sponsor_link_result"]),
  },
  methods: {
    ...mapMutations({
      setPopupData: "SET_POPUP_DATA",
    }),
    goOut(where: string) {
      this.$emit("close");
      this.$router.push(where);
    },
    onKeydown(event: any) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.goOut("/profile");
      }
    },
  },
  mounted() {
    setTimeout(() => (this.isMain = true), 500);

    window.addEventListener("keydown", this.onKeydown);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.onKeydown);
  },
});
