import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d1c0a1f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "CloudUI" }
const _hoisted_2 = {
  key: 0,
  class: "CloudUI-Buttons"
}
const _hoisted_3 = {
  key: 1,
  class: "CloudUI-MainList"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 2,
  class: "CloudUI-SubList"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 3,
  class: "CloudUI-Empty"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonUI = _resolveComponent("ButtonUI")!
  const _component_HintUI = _resolveComponent("HintUI")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.controls)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_ButtonUI, {
            class: "CloudUI-Button",
            text: "Очистить выбор",
            styled: "",
            icon: require('@/assets/icons/cancel.svg'),
            onClick: _ctx.disableAll
          }, null, 8, ["icon", "onClick"]),
          _createVNode(_component_ButtonUI, {
            class: "CloudUI-Button",
            text: "Выбрать всё",
            styled: "",
            icon: require('@/assets/icons/checked.svg'),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.checkAll(_ctx.currentItem)))
          }, null, 8, ["icon"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.list.length > 1)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
            return (_openBlock(), _createElementBlock("li", {
              class: _normalizeClass(["CloudUI-MainItem", _ctx.currentItem.id === item.id && 'CloudUI-MainItem_selected']),
              key: item.id,
              onClick: ($event: any) => (_ctx.setCurrentItem(item))
            }, _toDisplayString(item.name), 11, _hoisted_4))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.currentItem.tags?.length)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentItem.tags, (item) => {
            return (_openBlock(), _createElementBlock("li", {
              class: _normalizeClass(["CloudUI-SubItem SubItem", [
          _ctx.currentSubitems.findIndex((i) => i.id === item.id) !== -1 && 'SubItem_selected',
          _ctx.isDisabledItem(item) && 'SubItem_disabled'
        ]]),
              key: item.id,
              onClick: ($event: any) => (_ctx.setCurrentSubitem(item))
            }, [
              _createTextVNode(_toDisplayString(item.name) + " ", 1),
              (!_ctx.mobile_flag && item.short_description)
                ? (_openBlock(), _createBlock(_component_HintUI, { key: 0 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.short_description) + " ", 1),
                      (_ctx.isDisabledItem(item))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, "Только по подписке"))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true)
            ], 10, _hoisted_6))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_8, "Загрузка..."))
  ]))
}