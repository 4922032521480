import router from "@/router/index";
import {
  RoomData,
  PlayerData,
  SelectedGame,
  AnsweredPlayers,
  RoomState,
  RoomContext,
  RoomConnectionData,
  ProfileAuth,
} from "@/types/types";
import { HTTP, checkRoom, checkName } from "@/api/api";
import { useCookies } from "vue3-cookies";
import { clearTimeout, setTimeout } from "worker-timers";

const { cookies } = useCookies();
const cookiesConfig = JSON.stringify({
  expireTimes: "30d",
  path: "/",
  domain: "",
  secure: true,
  sameSite: "Lax",
});
let socket = {} as any;
let questionTimeout = NaN;
let reconnectionsCount = 0;

const room = {
  namespaced: true,
  state: (): RoomState => ({
    rooms: [], // Данные об активных комнатах
    room_data: {
      room_id: "",
      password: null,
      max_players: 10,
    }, // Данные о комнате
    player_data: {
      client_id: 0,
      active_player: true,
      name: "",
      role: "",
      avatar: "",
      is_ready: false,
    }, // Данные о текущем игроке
    players: [], // Все игроки в комнате
    games: [], // Все доступные игры
    excludedTags: [18], // Игровые теги с предупреждением
    selected_game: {
      tags: [],
      level: null,
    }, // Выбранная игра
    game_options: {}, // Опции для выбранной игры
    round_data: {}, // Данные о текущем раунде
    question_data: {}, // Данные о текущем вопросе
    correct_answer: {}, // Данные о правильном ответе
    player_answer: {}, // Данные об ответе игрока
    answered_players: {
      count: 0,
      max_count: 0,
    }, // Количество ответивших игроков
    answer_voice: "", // Озвучка правильности ответа игрока
    timer: 100, // Время в мсек. оставшееся на ответ
    timer_default: 0,
    timer_ticks: 100,
    leaderboard: null, // Таблица результатов
    game_status: false, // Флаг статуса игры
    is_room_private: true, // Флаг приватности комнаты
    emojis: [], // Массив смайлов-реакций
    chat_messages: [], // Массив сообщений чата
  }),
  getters: {
    // Фильтр по дашбордам
    dashboardsOnly: (state: RoomState): PlayerData[] => {
      return state.players.filter(
        (player: PlayerData) => player.role === "dashboard"
      );
    },
    // Фильтр только по играющим
    playersOnly: (state: RoomState): PlayerData[] => {
      return state.players.filter((player: PlayerData) => player.active_player);
    },
    // Фильтр только по играющим (без дашборда)
    playersOnlyClean: (state: RoomState, getters: any): PlayerData[] => {
      return getters.playersOnly.filter(
        (player: PlayerData) => player.role === "player"
      );
    },
    // Фильтр по неответившим игрокам
    slowPlayers: (state: RoomState): PlayerData[] => {
      return state.players.filter(
        (player: PlayerData) => player.active_player && !player.is_answered
      );
    },
    // Проверка на готовность всех игроков
    isReadyAll: (state: RoomState, getters: any): boolean => {
      return getters.playersOnly.every((player: PlayerData) => player.is_ready);
    },
    // Фильтр только готовых игроков
    readyPlayers: (state: RoomState, getters: any): PlayerData[] => {
      return getters.playersOnly.filter(
        (player: PlayerData) => player.is_ready
      );
    },
    // Проверка на дашборда
    isDashboard: (state: RoomState): boolean => {
      return state.player_data.role === "dashboard";
    },
    // Проверка игрока на возможность играть
    isActivePlayer: (state: RoomState): boolean => {
      return state.player_data.active_player;
    },
    // Проверка на локального игрока
    isLocal:
      (state: RoomState) =>
      (player: PlayerData): boolean => {
        return state.player_data.name === player?.name;
      },
    // Проверка на наличие тегов
    isStartDisabled: (state: RoomState): boolean => {
      return !state.selected_game.tags?.length;
    },
    // Проверка на нахождение игрока в какой-то из комнат
    checkInRoom: (): boolean => {
      return Boolean(
        (cookies.get("room_data") as unknown as RoomData)?.room_id
      );
    },
    // Проверка на роль зрителя (удалено из архитектуры)
    // isViewer:
    //   (state: RoomState) =>
    //   (player: PlayerData = state.player_data): boolean => {
    //     return player?.role === "viewer";
    //   },
  },
  actions: {
    // Создание подключения к поиску по комнатам
    initSearchConnection(context: RoomContext) {
      try {
        socket = new WebSocket(
          `${process.env.VUE_APP_CURRENT_WS_ENV}find-game`
        );

        socket.onopen = () => {
          context.dispatch(
            "sendData",
            JSON.stringify({
              event: "init_connection",
            })
          );
        };
        socket.onerror = (error: any) => {
          console.log(error);
        };
        socket.onmessage = (event: any) => {
          const response = JSON.parse(event.data);

          if (response) {
            const rooms = Object.values(response) as any[];

            for (let i = 0; i < rooms.length; i++) {
              rooms[i].room_id = Object.keys(response)[i];
            }

            context.commit("SET_ROOMS", rooms);
            context.commit(
              "SET_POPUP_DATA",
              {
                root_status: "rooms",
              },
              { root: true }
            );
          } else {
            context.commit(
              "SET_POPUP_DATA",
              {
                status: "error",
                title: "Ошибка",
                description: "Отсутствуют данные в принятом сообщении.",
              },
              { root: true }
            );
          }
        };
      } catch (error: any) {
        context.commit(
          "SET_POPUP_DATA",
          {
            status: "error",
            title: "Ошибка",
            description: "Ошибка создания подключения.",
          },
          { root: true }
        );
      }
    },
    // Фильтр комнат по тегам
    filterGames(context: RoomContext, tags: any[]) {
      const data = JSON.stringify({
        tags,
        event: "filter_out",
      });

      context.dispatch("sendData", data);
    },
    // Получение списка всех доступных игр
    async getGames(context: RoomContext) {
      let errorMsg = "";

      console.log("Получение списка игр");

      try {
        await HTTP("GET", "games")
          .then((data: any) => {
            const games = data?.message;

            games?.length
              ? context.commit("SET_GAMES", games)
              : (errorMsg = "Список игр пуст.");
          })
          .catch(() => {
            errorMsg = "Не удалось получить список игр.";
          });
      } catch (error: any) {
        errorMsg = "Не удалось получить список игр.";
      }

      errorMsg &&
        context.commit(
          "SET_POPUP_DATA",
          {
            status: "error",
            title: "Ошибка",
            description: errorMsg,
          },
          { root: true }
        );
    },
    // Создание комнаты
    async createRoom(context: RoomContext, payload: any) {
      const password = payload.password || null;
      const selected_game = payload.selected_game;
      const fast_game = payload.fast_game || "";
      const currentRoom = (cookies.get("room_data") as unknown as RoomData)
        ?.room_id;
      let errorMsg = "";

      if (currentRoom) {
        errorMsg = `Ты уже находишься в комнате ${currentRoom}`;
      } else {
        try {
          await HTTP("POST", `room/create${fast_game}`, {
            room_settings: {
              password,
            },
            selected_game,
          })
            .then((data) => {
              const room_settings = data?.message?.room_settings || {};

              if (room_settings.room_id) {
                context.commit("SET_ROOM_DATA", room_settings);

                const dashboard = {
                  name: "dashboard",
                  role: "dashboard",
                  active_player: true,
                };

                const data = {
                  room_settings,
                  player: dashboard,
                };

                context.dispatch("initRoomConnection", data);
              } else {
                errorMsg = "Отсутствует id комнаты.";
              }
            })
            .catch(() => {
              errorMsg = "Не удалось создать комнату.";
            });
        } catch (error: any) {
          errorMsg = "Не удалось создать комнату.";
        }
      }

      errorMsg &&
        context.commit(
          "SET_POPUP_DATA",
          {
            status: "error",
            title: "Ошибка",
            description: errorMsg,
          },
          { root: true }
        );
    },
    // Вход в комнату
    joinToRoom(context: RoomContext, payload: any) {
      if ((cookies.get("room_data") as unknown as RoomData)?.room_id) {
        context.commit(
          "SET_POPUP_DATA",
          {
            status: "error",
            title: "Ошибка",
            description: "Ты уже находишься в другой комнате",
          },
          { root: true }
        );
      } else {
        const room_id = payload.room_id;
        const password = payload.password || null;
        const name = payload.name;

        const data = {
          room_settings: { room_id, password },
          player: { name },
        };

        context.dispatch("initRoomConnection", data);
      }
    },
    // Создание подключения к комнате
    initRoomConnection(context: RoomContext, data: RoomConnectionData) {
      const token = (cookies.get("profile_auth") as unknown as ProfileAuth)
        ?.access_token;

      context.commit(
        "SET_POPUP_DATA",
        {
          status: "error",
          title: "Загрузка",
          description: "Идёт подключение к комнате...",
          autoclose: 10000,
        },
        { root: true }
      );

      try {
        const room_id = String(data.room_settings?.room_id)?.trim();

        console.log(`Попытка создать подключение к комнате ${room_id}`);

        socket = new WebSocket(
          `${process.env.VUE_APP_CURRENT_WS_ENV}${room_id}${
            token ? "?token=" + token : ""
          }`
        );

        socket.onopen = () => {
          reconnectionsCount = 0;

          context.commit(
            "SET_POPUP_DATA",
            {
              root_status: "",
              status: "",
            },
            { root: true }
          );

          context.dispatch(
            "sendData",
            JSON.stringify({
              room_settings: data.room_settings,
              player: data.player,
              event: "init_connection",
            })
          );
        };
        socket.onclose = (event: any) => {
          console.log(`Закрытие соединения с кодом ${event.code}`);

          if (event.code === 1001) {
            reconnectionsCount = 0;
          } else {
            if (
              [1000, 1008, 1013].includes(event.code) ||
              reconnectionsCount >= 3
            ) {
              context.dispatch("clearAll");
            } else {
              reconnectionsCount++;
              context.dispatch("checkRoomConnection");
            }
          }
        };
        socket.onerror = (error: any) => {
          console.log(error);
        };
        socket.onmessage = (event: any) => {
          const currentPath = router.currentRoute.value?.path;
          const response = JSON.parse(event.data);

          console.log(response || null, "Что приходит");

          if (response) {
            const message = response.message;
            const status = response.status;

            if (status === "error") {
              context.commit(
                "SET_POPUP_DATA",
                {
                  status: "error",
                  title: "Ошибка",
                  description: message,
                },
                { root: true }
              );
            } else {
              if (message !== null && message !== undefined) {
                if (status === "game_status") {
                  context.commit("SET_GAME_STATUS", message);
                }

                if (status === "preview") {
                  context.commit("SET_ROUND_DATA", message);

                  if (currentPath !== "/preview") {
                    context.commit(
                      "SET_POPUP_DATA",
                      {
                        root_status: "",
                        status: "",
                      },
                      { root: true }
                    );
                    router.push("/preview");
                  }
                }

                if (status === "voice") {
                  context.commit("SET_VOICE", message, { root: true });
                }

                if (status === "music") {
                  context.commit("SET_MUSIC", message, { root: true });
                  context.commit("SET_MUSIC_FLAG", true, { root: true });
                }

                if (status === "question") {
                  context.commit("SET_ANSWERED_PLAYERS", null);
                  context.commit("SET_CORRECT_ANSWER", null);
                  context.commit("SET_PLAYER_ANSWER", null);
                  context.commit("SET_QUESTION_DATA", message);

                  currentPath !== "/game" && router.push("/game");
                }

                if (status === "answers_after_hints") {
                  context.commit("SET_QUESTION_DATA", {
                    answers: message.answers,
                  });
                  context.commit("SET_PLAYER_DATA", message.current_player);
                }

                if (status === "timer") {
                  context.commit("SET_TIMER_DEFAULT", message);
                  context.dispatch("setTimer", message);
                }

                if (status === "answer") {
                  context.commit("SET_CORRECT_ANSWER", message.correct_answer);
                  context.commit("SET_PLAYER_ANSWER", message.player_answer);
                }

                if (status === "answer_voice") {
                  context.commit("SET_ANSWER_VOICE", message);
                }

                if (status === "player_answer") {
                  context.commit("SET_PLAYER_ANSWER", message);
                }

                if (status === "answered_players") {
                  context.commit("SET_ANSWERED_PLAYERS", message);
                }

                if (status === "reaction") {
                  context.commit("SET_EMOJIS", message);
                }

                if (status === "chat") {
                  context.commit("SET_CHAT_MESSAGES", message);
                }

                if (message.room_settings) {
                  context.commit("SET_ROOM_DATA", message.room_settings);
                }

                if (message.players) {
                  context.commit("SET_PLAYERS", message.players);
                }

                if (message.current_player) {
                  context.commit("SET_PLAYER_DATA", message.current_player);
                }

                if (message.main_game) {
                  context.commit("SET_GAME_OPTIONS", message.main_game);
                }

                if (message.selected_game) {
                  context.commit("SET_SELECTED_GAME", message.selected_game);
                }

                if (message.leaderboard) {
                  context.commit("SET_LEADERBOARD", message.leaderboard);

                  if (currentPath !== "/total") {
                    context.commit(
                      "SET_POPUP_DATA",
                      {
                        root_status: "",
                        status: "",
                      },
                      { root: true }
                    );
                    router.push("/total");
                  }
                }
              }

              if (status === "info") {
                if (!["/room", "/total"].includes(currentPath)) {
                  context.commit(
                    "SET_POPUP_DATA",
                    {
                      root_status: "",
                      status: "",
                    },
                    { root: true }
                  );
                  context.commit("SET_MUSIC_FLAG", false, { root: true });
                  context.commit("SET_VOICE", "", { root: true });
                  router.push("/room");
                }
              }

              if (status === "pause") {
                context.commit(
                  "SET_POPUP_DATA",
                  {
                    root_status: "pause",
                    status: "",
                  },
                  { root: true }
                );
                context.dispatch("setTimer", -1);
              }

              if (status === "resume") {
                context.commit(
                  "SET_POPUP_DATA",
                  {
                    root_status: "",
                    status: "",
                  },
                  { root: true }
                );
                context.dispatch("setTimer", context.state.timer_ticks);
              }

              if (status === "to_lobby") {
                if (currentPath !== "/room") {
                  context.commit(
                    "SET_POPUP_DATA",
                    {
                      root_status: "",
                      status: "",
                    },
                    { root: true }
                  );
                  context.commit("SET_MUSIC_FLAG", false, { root: true });
                  context.commit("SET_VOICE", "", { root: true });
                  router.push("/room");
                }
              }

              if (status === "exit") {
                context.commit(
                  "SET_POPUP_DATA",
                  {
                    root_status: "",
                    status: "exit",
                    description: "Комната покинута.",
                    autoclose: 1000,
                  },
                  { root: true }
                );
              }

              if (
                status === "change_player_activity" &&
                context.state.player_data.active_player &&
                context.state.player_data.name === "dashboard"
              ) {
                context.commit(
                  "SET_POPUP_DATA",
                  {
                    root_status: "nickname",
                  },
                  { root: true }
                );
              }
            }
          }
        };
      } catch (error: any) {
        context.commit(
          "SET_POPUP_DATA",
          {
            status: "error",
            title: "Ошибка",
            description: "Ошибка создания подключения.",
          },
          { root: true }
        );
      }
    },
    // Отправка данных на сервер
    sendData(context: RoomContext, data: any) {
      if (socket.readyState === WebSocket.OPEN) {
        console.log(JSON.parse(data), "Что уходит");

        socket.send(data);
      } else {
        context.commit(
          "SET_POPUP_DATA",
          {
            status: "error",
            title: "Ошибка",
            description: "Соединение закрыто. Не удалось отправить данные.",
          },
          { root: true }
        );
      }
    },
    // Переподключение к комнате
    async checkRoomConnection(context: RoomContext) {
      const room_data = cookies.get("room_data") as unknown as RoomData;
      const player_data = cookies.get("player_data") as unknown as PlayerData;
      const query = router.currentRoute.value?.query;
      let errorsCount = 0;

      if (
        room_data?.room_id &&
        player_data?.client_id &&
        query?.room_id !== room_data.room_id
      ) {
        await checkRoom(room_data.room_id)
          .then(async (result: any) => {
            if (result) {
              const data = {
                room_settings: room_data,
                player: player_data,
              };

              const sameName = await checkName(
                room_data.room_id,
                player_data.name || "",
                player_data.client_id
              );

              sameName
                ? errorsCount++
                : context.dispatch("initRoomConnection", data);
            } else {
              errorsCount++;
            }
          })
          .catch(() => {
            errorsCount++;
          });
      } else {
        errorsCount++;
      }

      if (errorsCount > 0) {
        context.dispatch("clearAll");
      }
    },
    // Добавление игры к списку выбранных игр
    addGame(context: RoomContext, game: any) {
      const data = JSON.stringify({
        player: context.state.player_data,
        games: [game],
        event: "add_game",
      });

      context.dispatch("sendData", data);
    },
    // Удаление игры из списка выбранных игр
    deleteGame(context: RoomContext) {
      const data = JSON.stringify({
        player: context.state.player_data,
        games: [context.state.selected_game],
        event: "delete_game",
      });

      context.dispatch("sendData", data);
    },
    // Начало игрового процесса
    startGame(context: RoomContext) {
      if (
        context.getters.playersOnly.length < 1 ||
        !context.getters.isReadyAll
      ) {
        context.commit(
          "SET_POPUP_DATA",
          {
            status: "error",
            title: "Ошибка",
            description: !context.getters.isReadyAll
              ? "Не все игроки готовы к игре!"
              : "В комнате нет игроков!",
            autoclose: 2000,
          },
          { root: true }
        );
      } else {
        const data = JSON.stringify({
          player: context.state.player_data,
          event: "start_game",
        });

        context.dispatch("sendData", data);
      }
    },
    // Подтверждение готовности игрока
    playerReady(context: RoomContext, is_ready: boolean) {
      const data = JSON.stringify({
        player: { ...context.state.player_data, is_ready },
        event: "player_ready",
      });

      context.dispatch("sendData", data);
    },
    // Изменение имени игрока
    playerSettings(context: RoomContext, payload: any) {
      const data = JSON.stringify({
        player: { ...context.state.player_data, ...payload },
        event: "player_settings",
      });

      context.dispatch("sendData", data);
    },
    // Отправка ответа на вопрос
    answersPipeline(context: RoomContext, answer: any) {
      const data = JSON.stringify({
        player: context.state.player_data,
        answer,
        event: "answers_pipeline",
      });

      context.dispatch("sendData", data);
    },
    // Отправка реакции на вопрос
    addReactions(context: RoomContext, grade_question: string) {
      const data = JSON.stringify({
        player: { ...context.state.player_data, grade_question },
        event: "add_reactions",
      });

      context.dispatch("sendData", data);
    },
    // Отправка реакции на игру
    addRating(context: RoomContext, grade_game: number) {
      const data = JSON.stringify({
        player: { ...context.state.player_data, grade_game },
        event: "add_rating",
      });

      context.dispatch("sendData", data);
    },
    // Отправка буста ответов от игрока
    setBoost(context: RoomContext) {
      const data = JSON.stringify({
        player: { ...context.state.player_data, boosted_round: true },
        event: "is_boost",
      });

      context.dispatch("sendData", data);
    },
    // Использование игровых подсказок
    usedHints(context: RoomContext) {
      const data = JSON.stringify({
        player: context.state.player_data,
        event: "used_hints",
      });

      context.dispatch("sendData", data);
    },
    sendChatMessage(context: RoomContext, player_message: string) {
      const data = JSON.stringify({
        room_settings: context.state.room_data,
        player: context.state.player_data,
        event: "chat_messages",
        player_message,
      });

      context.dispatch("sendData", data);
    },
    sendChatReaction(context: RoomContext, reaction: string) {
      const data = JSON.stringify({
        room_settings: context.state.room_data,
        player: context.state.player_data,
        event: "player_reaction",
        reaction,
      });

      context.dispatch("sendData", data);
    },
    // Игровая пауза
    pause(context: RoomContext) {
      const data = JSON.stringify({
        player: context.state.player_data,
        event: "pause_game",
      });

      context.dispatch("sendData", data);
    },
    // Возобновление игрового процесса
    resume(context: RoomContext) {
      const data = JSON.stringify({
        player: context.state.player_data,
        event: "resume_game",
      });

      context.dispatch("sendData", data);
    },
    // Всех в лобби
    reset(context: RoomContext) {
      const data = JSON.stringify({
        player: context.state.player_data,
        event: "to_lobby",
      });

      context.dispatch("sendData", data);
    },
    // Дисконнект
    disconnect(context: RoomContext) {
      const data = JSON.stringify({
        player: context.state.player_data,
        event: "exit",
      });

      context.dispatch("sendData", data);
    },
    clearAll(context: RoomContext) {
      reconnectionsCount = 0;

      const currentPath = router.currentRoute.value?.path;

      if (["/room", "/preview", "/game", "/total"].includes(currentPath)) {
        router.push({
          path: "/",
          query: { ...router.currentRoute.value?.query },
        });
      }

      context.commit("SET_EMOJIS", "");
      context.commit("SET_CHAT_MESSAGES", null);
      context.commit("SET_ROOM_DATA", null);
      context.commit("SET_PLAYER_DATA", null);
      context.commit("SET_MUSIC", "", { root: true });
      context.commit("SET_MUSIC_FLAG", false, { root: true });
    },
    // Смена настроек комнаты
    changeRoomSettings(context: RoomContext, payload: any) {
      const room_settings = context.state.room_data as any;

      room_settings[payload.field] = payload.value;

      ["undefined", "null", undefined].includes(room_settings.password) &&
        (room_settings.password = null);

      const data = JSON.stringify({
        room_settings,
        player: context.state.player_data,
        event: "change_room_s",
      });

      context.dispatch("sendData", data);
    },
    // Смена настроек игры
    changeGameSettings(context: RoomContext, payload: any) {
      const selected_game = context.state.selected_game as any;

      selected_game[payload.field] = payload.value;

      !["E", "H", null].includes(selected_game.level) &&
        (selected_game.level = null);

      const data = JSON.stringify({
        selected_game,
        player: context.state.player_data,
        event: "change_game",
      });

      context.dispatch("sendData", data);
    },
    // Установка приватности / публичности комнаты
    makeRoomPrivate(context: RoomContext, is_open: boolean) {
      const data = JSON.stringify({
        selected_game: context.state.selected_game,
        player: context.state.player_data,
        room_settings: { ...context.state.room_data, is_open },
        event: is_open ? "make_room_public" : "make_room_private",
      });

      context.dispatch("sendData", data);
    },
    // Установка максимального количества рандомных игроков в комнате
    changeMaxRandomPlayers(context: RoomContext, max_random_players: boolean) {
      const data = JSON.stringify({
        selected_game: context.state.selected_game,
        player: context.state.player_data,
        room_settings: { ...context.state.room_data, max_random_players },
        event: "change_max_random_players",
      });

      context.dispatch("sendData", data);
    },
    // Кик игрока
    kickPlayer(context: RoomContext, player: PlayerData) {
      const data = JSON.stringify({
        dashboard: context.state.player_data,
        player_kicked: player,
        event: "kick_player",
      });

      context.dispatch("sendData", data);
    },
    // Назначение дашборда
    setDashboard(context: RoomContext, player: PlayerData) {
      const data = JSON.stringify({
        dashboard: context.state.player_data,
        new_dash: player,
        event: "change_player_role",
      });

      context.dispatch("sendData", data);
    },
    // Смена роли игрока (удалено из архитектуры)
    // changePlayerRole(context: RoomContext, index: number) {
    //   const players = context.state.players;
    //   const data = JSON.stringify({
    //     room_settings: context.state.room_data,
    //     players: [
    //       players.find((player: PlayerData) => player.name === "dashboard"),
    //       players[index],
    //     ],
    //     event: "change_player_role",
    //   });

    //   context.dispatch("sendData", data);
    // },
    // Расчёт времени таймера текущего вопроса
    setTimer(context: RoomContext, timer: number) {
      !isNaN(questionTimeout) && clearTimeout(questionTimeout);

      if (timer) {
        let value = Math.trunc(timer / 100);

        const onUpdate = () => {
          questionTimeout = setTimeout(() => {
            if (value > 0) {
              const timer_default = context.state.timer_default / 100;

              value--;
              context.commit("SET_TIMER", (value / timer_default) * 100);
              context.commit("SET_TIMER_TICKS", value * 100);
              onUpdate();
            } else {
              clearTimeout(questionTimeout);
              questionTimeout = NaN;
            }
          }, 100);
        };

        onUpdate();
      }
    },
  },
  mutations: {
    // Установка данных об активных комнатах
    SET_ROOMS(state: RoomState, rooms: any[]) {
      state.rooms = rooms || [];
    },
    // Установка данных о комнате
    SET_ROOM_DATA(state: RoomState, room_data: RoomData) {
      if (room_data) {
        state.room_data = {
          ...state.room_data,
          ...room_data,
        };

        console.log("Установка данных в room_data", room_data);

        cookies.set(
          "room_data",
          JSON.stringify({
            room_id: room_data.room_id,
            password: room_data.password || null,
          }),
          cookiesConfig
        );
      } else {
        state.room_data = {
          room_id: "",
          password: null,
          max_players: 10,
        };

        cookies.remove("room_data");
      }
    },
    // Установка данных о текущем игроке
    SET_PLAYER_DATA(state: RoomState, player_data: PlayerData) {
      if (player_data) {
        state.player_data = {
          ...state.player_data,
          ...player_data,
        };

        console.log("Установка данных в player_data", player_data);

        cookies.set(
          "player_data",
          JSON.stringify({
            client_id: player_data.client_id,
            name: player_data.name,
            role: player_data.role,
            active_player: player_data.active_player,
            is_ready: player_data.is_ready,
            avatar: player_data.avatar,
          }),
          cookiesConfig
        );
      } else {
        state.player_data = {
          client_id: 0,
          active_player: true,
          name: "",
          role: "",
          avatar: "",
          is_ready: false,
        };

        cookies.remove("player_data");
      }
    },
    // Установка игровых тегов с предупреждением
    SET_EXCLUDED_TAGS(state: RoomState, tag: number) {
      state.excludedTags.splice(state.excludedTags.indexOf(tag), 1);
    },
    // Установка всех выбранных игр в комнате
    SET_SELECTED_GAME(state: RoomState, selected_game: SelectedGame) {
      if (selected_game) {
        state.selected_game = {
          ...state.selected_game,
          ...selected_game,
        };
      } else {
        state.selected_game = {
          tags: [],
          level: null,
        };
      }
    },
    // Установка всех опций для выбранной игры
    SET_GAME_OPTIONS(state: RoomState, game_options: any) {
      state.game_options = game_options || {};
    },
    // Установка озвучки правильности ответа
    SET_ANSWER_VOICE(state: RoomState, answer_voice: string) {
      state.answer_voice = answer_voice;
    },
    // Установка всех игроков в комнате
    SET_PLAYERS(state: RoomState, players: PlayerData[]) {
      state.players = players || [];
    },
    // Установка всех доступных игр
    SET_GAMES(state: RoomState, games: any[]) {
      state.games = games || [];
    },
    // Установка данных о текущем раунде
    SET_ROUND_DATA(state: RoomState, round_data: any) {
      state.round_data = round_data || {};
    },
    // Установка данных о текущем вопросе
    SET_QUESTION_DATA(state: RoomState, question_data: any) {
      if (question_data) {
        state.question_data = {
          ...state.question_data,
          ...question_data,
        };
      } else {
        state.question_data = {};
      }
    },
    // Установка данных о правильном ответе на текущий вопрос
    SET_CORRECT_ANSWER(state: RoomState, correct_answer: any) {
      state.correct_answer = correct_answer || {};
    },
    // Установка данных об ответе игрока на текущий вопрос
    SET_PLAYER_ANSWER(state: RoomState, player_answer: any) {
      state.player_answer = player_answer || {};
    },
    // Установка данных о количестве ответивших пользователей
    SET_ANSWERED_PLAYERS(state: RoomState, answered_players: AnsweredPlayers) {
      state.answered_players = answered_players || {
        count: 0,
        max_count: 0,
      };
    },
    // Установка таймера на текущий вопрос
    SET_TIMER(state: RoomState, timer: number) {
      state.timer = timer;
    },
    // Установка таймера по умолчанию
    SET_TIMER_DEFAULT(state: RoomState, timer_default: number) {
      state.timer_default = timer_default;
    },
    // Установка прошедших тиков таймера на текущий вопрос
    SET_TIMER_TICKS(state: RoomState, timer_ticks: number) {
      state.timer_ticks = timer_ticks;
    },
    // Установка таблицы результатов
    SET_LEADERBOARD(state: RoomState, leaderboard: any) {
      state.leaderboard = leaderboard;
    },
    // Установка флага статуса игры
    SET_GAME_STATUS(state: RoomState, game_status: boolean) {
      state.game_status = game_status;
    },
    // Установка флага приватности комнаты
    SET_IS_ROOM_PRIVATE(state: RoomState, is_room_private: boolean) {
      state.is_room_private = is_room_private;
    },
    // Установка массива смайлов-реакций
    SET_EMOJIS(state: RoomState, emoji: string) {
      if (emoji) {
        state.emojis = [...state.emojis, emoji];
        setTimeout(() => state.emojis.unshift(), 2000);
      } else {
        state.emojis = [];
      }
    },
    // Установка массива сообщений чата
    SET_CHAT_MESSAGES(state: RoomState, chat_messages: any[]) {
      state.chat_messages = chat_messages || [];
    },
  },
};

export default room;
