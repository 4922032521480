
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { useMeta } from "vue-meta";

import BackgroundUI from "@/components/UI/BackgroundUI.vue";
import HeaderHome from "@/components/HeaderBlock/HeaderHome.vue";

const siteData = {
  title:
    "Стримы с квизами от Partygames.lol: Как проводить стримы, которые не хочется выключать",
  description: "Как проводить стримы, которые не хочется выключать",
  keywords:
    "стрим, аудитория, квиз, квиз кино, марвел, гарри поттер, Игры для стрима, играть вместе, Как играть с другом по сети, кто играет, игры поиграть",
  href: process.env.VUE_APP_CURRENT_ADDRESS + "blog/stream_partygames_lol",
};

export default defineComponent({
  name: "DescriptionPage",
  setup() {
    useMeta({
      title: siteData.title,
      description: siteData.description,
      keywords: siteData.keywords,
      link: [
        {
          rel: "canonical",
          href: siteData.href,
        },
      ],
      og: {
        title: siteData.title,
        description: siteData.description,
      },
    });
  },
  components: {
    BackgroundUI,
    HeaderHome,
  },
  computed: {
    ...mapState(["mobile_flag"]),
  },
});
