
import { defineComponent } from "vue";
import { mapState } from "vuex";

import InputBlock from "@/components/UI/ChatUI/InputBlock.vue";
import TextBlock from "@/components/UI/ChatUI/TextBlock.vue";
import EmojisBlock from "@/components/UI/ChatUI/EmojisBlock.vue";

export default defineComponent({
  name: "ChatUI",
  components: {
    InputBlock,
    TextBlock,
    EmojisBlock,
  },
  data: () => ({
    isChat: false,
    isNotice: false,
  }),
  computed: {
    ...mapState("room", ["chat_messages"]),
  },
  watch: {
    isChat() {
      this.isNotice = false;
    },
    chat_messages: {
      handler(chat_messages: string[]) {
        this.isNotice = Boolean(chat_messages?.length && !this.isChat);
      },
      deep: true,
    },
  },
  methods: {
    setIsChat(isChat: boolean) {
      this.isChat = isChat;
    },
  },
});
