
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { useMeta } from "vue-meta";

import BackgroundUI from "@/components/UI/BackgroundUI.vue";
import HeaderHome from "@/components/HeaderBlock/HeaderHome.vue";

const siteData = {
  title: "Как играть в квизы Partygames.lol",
  description:
    "Собирай друзей, коллег или всех котов с округи. Выбирай квиз на свой вкус: от Гарри Поттера до рекламы из 90х. Читай вопрос, хихикай и выбирай ответ. Никаких скачиваний и регистраций, сборная солянка из всех тем бесплатно!",
  keywords:
    "квиз - викторина, бесплатный квиз, викторина бесплатно, играть с друзьями, играть с игроками, найти игру, квиз, играть, онлайн викторина, вопросы обо всем, все вместе, темы квизов, игра на одного игрока, во что поиграть, играть на телефоне",
  href: process.env.VUE_APP_CURRENT_ADDRESS + "how_to_play",
};

export default defineComponent({
  name: "HowToPlayPage",
  components: {
    BackgroundUI,
    HeaderHome,
  },
  setup() {
    useMeta({
      title: siteData.title,
      description: siteData.description,
      keywords: siteData.keywords,
      link: [
        {
          rel: "canonical",
          href: siteData.href,
        },
      ],
      og: {
        title: siteData.title,
        description: siteData.description,
      },
    });
  },
  computed: {
    ...mapState(["mobile_flag"]),
  },
});
