
import { defineComponent } from "vue";
import { mapState, mapMutations } from "vuex";
import { HTTP } from "@/api/api";

import { useMeta } from "vue-meta";

import BackgroundUI from "@/components/UI/BackgroundUI.vue";
import HeaderHome from "@/components/HeaderBlock/HeaderHome.vue";
import ButtonUI from "@/components/UI/ButtonUI.vue";

const siteData = {
  title: "Рейтинг игроков квизов Partygames.lol",
  description:
    "Попади в топ, играя в квиз! Отвечай на вопросы в онлайн-викторине и соревнуйся в крутости с другими игроками. Начинай играть, а вопросы мы отдадим бесплатно. Брат, в рейтинге сила.",
  keywords:
    "топ игроков, популярные игры, статистика, играть онлайн, Игры онлайн бесплатно, кто играет, играть онлайн бесплатно на русском, Викторина с ответами, Квизы, Игры для стрима, онлайн игры без скачивания, игры на время",
  href: process.env.VUE_APP_CURRENT_ADDRESS + "statistic",
};

export default defineComponent({
  name: "HowToPlayPage",
  components: {
    BackgroundUI,
    HeaderHome,
    ButtonUI,
  },
  setup() {
    useMeta({
      title: siteData.title,
      description: siteData.description,
      keywords: siteData.keywords,
      link: [
        {
          rel: "canonical",
          href: siteData.href,
        },
      ],
      og: {
        title: siteData.title,
        description: siteData.description,
      },
    });
  },
  computed: {
    ...mapState(["mobile_flag"]),
  },
  data: () => ({
    statistic: {} as any,
  }),
  methods: {
    ...mapMutations({
      setPopupData: "SET_POPUP_DATA",
    }),
  },
  async created() {
    let errorMsg = "";

    try {
      await HTTP("GET", "room/stat")
        .then((data: any) => {
          this.statistic = data || {};
        })
        .catch(() => {
          errorMsg = "Не удалось получить статистические данные.";
        });
    } catch (error: any) {
      errorMsg = "Не удалось получить статистические данные.";
    }

    errorMsg &&
      this.setPopupData({
        status: "error",
        title: "Ошибка",
        description: errorMsg,
      });
  },
});
