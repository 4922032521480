
import { defineComponent } from "vue";
import clone from "clone";

import ButtonUI from "@/components/UI/ButtonUI.vue";

export default defineComponent({
  name: "SwitchUI",
  components: { ButtonUI },
  emits: ["update:value", "warning"],
  props: {
    list: {
      type: Array as any,
      required: true,
    },
    value: {
      type: Array as any,
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
    excluded: {
      type: Array as any,
      default: () => [],
    },
    condition: {
      type: Boolean,
      default: true,
    },
    max: {
      type: Number,
      default: 4,
    },
  },
  data: () => ({
    localList: [] as any,
    currentExcluded: null,
  }),
  computed: {
    isMax(): boolean {
      return (
        this.localList.filter((item: any) => item.checked).length >= this.max
      );
    },
  },
  watch: {
    localList: {
      handler() {
        this.$emit(
          "update:value",
          this.list.filter(
            (item: any, index: number) => this.localList[index]?.checked && item
          )
        );
      },
      deep: true,
    },
    excluded: {
      handler() {
        this.currentExcluded && this.setItemChecked(this.currentExcluded);
      },
      deep: true,
    },
  },
  created() {
    this.localList = clone(this.list);
    this.localList.forEach((item: any) => {
      item.checked = Boolean(this.value.find((el: any) => item.id === el.id));
    });
  },
  methods: {
    // checkAll() {
    //   this.localList.forEach((item: any) => {
    //     if (this.excluded.indexOf(item.id) === -1) {
    //       item.checked = true;
    //     }
    //   });
    // },
    disableAll() {
      this.localList.forEach((item: any) => (item.checked = false));
    },
    setItemChecked(payload: any) {
      this.localList.forEach((item: any) => {
        if (payload.id === item.id) {
          if (this.excluded.indexOf(item.id) !== -1 && !item.checked) {
            this.currentExcluded = item;
            this.$emit("warning", item);
          } else {
            setTimeout(() => {
              if ((!item.checked && !this.isMax) || item.checked) {
                item.checked = !item.checked;
              }
            }, 0);
          }
        }
      });
    },
  },
});
