
import { defineComponent } from "vue";

export default defineComponent({
  name: "TextBlock",
  props: {
    chat_messages: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    colorCache: {} as any,
  }),
  computed: {
    messages(): any[] {
      return [...this.chat_messages].reverse();
    },
  },
  watch: {
    chat_messages: {
      handler() {
        setTimeout(() => this.scroolChat(), 500);
      },
      deep: true,
    },
  },
  methods: {
    randomColor(name: string) {
      const r = () => Math.floor(256 * Math.random());

      return (
        this.colorCache[name] ||
        (this.colorCache[name] = `rgb(${r()}, ${r()}, ${r()})`)
      );
    },
    scroolChat() {
      const container = this.$refs.container as HTMLDivElement;

      container.scrollTop = container.scrollHeight;
    },
  },
});
