
import { defineComponent } from "vue";

import { mapState } from "vuex";

import BackgroundUI from "@/components/UI/BackgroundUI.vue";

export default defineComponent({
  name: "PopupUI",
  components: {
    BackgroundUI,
  },
  emits: ["close"],
  props: {
    autoclose: {
      type: Number,
      default: 0,
    },
    isClose: {
      type: Boolean,
      default: true,
    },
    noparallax: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(["mobile_flag"]),
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    onKeydown(event: any) {
      if (event.keyCode === 27) {
        this.onClose();
      }
    },
  },
  mounted() {
    document.documentElement.style.overflow = "hidden";

    this.isClose && this.autoclose && setTimeout(this.onClose, this.autoclose);
    window.addEventListener("keydown", this.onKeydown);
  },
  beforeUnmount() {
    document.documentElement.removeAttribute("style");
    window.removeEventListener("keydown", this.onKeydown);
  },
});
