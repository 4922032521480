
import { defineComponent } from "vue";
import { mapState } from "vuex";

import ButtonUI from "@/components/UI/ButtonUI.vue";
import HintUI from "@/components/UI/HintUI.vue";

export default defineComponent({
  name: "CloudUI",
  emits: ["update:value", "warning"],
  components: {
    ButtonUI,
    HintUI,
  },
  props: {
    value: {
      type: Object as any,
      default: () => ({}),
    },
    list: {
      type: Array as any,
      required: true,
    },
    excluded: {
      type: Array as any,
      default: () => [],
    },
    max: {
      type: Number,
      default: 4,
    },
    condition: {
      type: Boolean,
      default: true,
    },
    controls: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    currentItem: {} as any,
    currentSubitems: [] as any,
    currentExcluded: null,
  }),
  computed: {
    ...mapState(["mobile_flag"]),
    isMax(): boolean {
      return this.currentSubitems.length >= this.max;
    },
  },
  watch: {
    list: {
      handler() {
        this.disableAll();
      },
      deep: true,
    },
    excluded: {
      handler() {
        this.currentExcluded && this.setCurrentSubitem(this.currentExcluded);
      },
      deep: true,
    },
    currentItem(v: any) {
      if (!this.currentSubitems.length) {
        let index = Math.floor(Math.random() * (v.tags.length - 1));

        if (this.excluded.indexOf(v.tags[index]?.id) !== -1) {
          v.tags[index + 1] ? index++ : v.tags[index - 1] ? index-- : "";
        }

        v.tags[index] && this.setCurrentSubitem(v.tags[index]);
      }
    },
  },
  methods: {
    setCurrentItem(item: any) {
      this.currentSubitems = [];

      if (this.currentItem.id !== item.id) {
        this.currentItem = item;
      }

      this.$emit("update:value", {
        id: this.currentItem.id,
        tags: this.currentSubitems,
      });
    },
    setCurrentSubitem(payload: any) {
      const index = this.currentSubitems.findIndex(
        (item: any) => item.id === payload.id
      );

      if (index === -1 && !this.isDisabledItem(payload)) {
        if (this.excluded.indexOf(payload.id) !== -1) {
          this.currentExcluded = payload;
          this.$emit("warning", payload);
        } else if (!this.isMax) {
          this.currentSubitems.push(payload);
        }
      } else {
        this.currentSubitems.splice(index, 1);
      }

      this.$emit("update:value", {
        id: this.currentItem.id,
        tags: this.currentSubitems,
      });
    },
    isDisabledItem(item: any): boolean {
      return !item.is_free && !this.condition;
    },
    checkAll(item: any) {
      this.currentSubitems = [];

      item?.tags?.length &&
        item.tags.forEach((item: any) => this.setCurrentSubitem(item));
    },
    disableAll() {
      this.list.length && this.setCurrentItem(this.list[0]);
    },
  },
  created() {
    this.disableAll();
    this.checkAll(this.value);
  },
});
