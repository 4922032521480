import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10e2bc98"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "GamesSlide-Wrapper" }
const _hoisted_2 = { class: "GamesSlide-Title" }
const _hoisted_3 = { class: "GamesSlide-Description" }
const _hoisted_4 = { class: "GamesSlide-Inner" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "GamesSlide-Price" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["GamesSlide", !_ctx.disabledCondition && 'GamesSlide_free']),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setCurrentItem(_ctx.slide, !_ctx.slide.check_age, _ctx.slide.check_age)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.slide.name), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.slide.description), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        class: "GamesSlide-Button",
        disabled: _ctx.disabledCondition
      }, _toDisplayString(_ctx.slide.is_free ? "Начать игру" : "Играть..."), 9, _hoisted_5),
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.itemPrice(_ctx.slide)), 1)
    ])
  ], 2))
}