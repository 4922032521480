
import { defineComponent } from "vue";

import PopupUI from "@/components/UI/PopupUI.vue";
import ButtonUI from "@/components/UI/ButtonUI.vue";

export default defineComponent({
  name: "PopupAdult",
  emits: ["close", "taged"],
  components: {
    PopupUI,
    ButtonUI,
  },
  methods: {
    onKeydown(event: any) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.$emit("taged", 18);
      }
    },
  },
  mounted() {
    window.addEventListener("keydown", this.onKeydown);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.onKeydown);
  },
});
