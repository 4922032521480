
import { defineComponent } from "vue";

import HintUI from "@/components/UI/HintUI.vue";

export default defineComponent({
  name: "CheckboxUI",
  emits: ["update:value"],
  components: {
    HintUI,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    hint: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      required: true,
    },
    row: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setValue() {
      setTimeout(() => this.$emit("update:value", !this.value), 0);
    },
  },
});
