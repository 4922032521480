
import { defineComponent } from "vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

import ButtonUI from "@/components/UI/ButtonUI.vue";
import CardRP from "@/components/RoomPage/CardRP.vue";
import CheckboxUI from "@/components/UI/CheckboxUI.vue";
import CalcUI from "@/components/UI/CalcUI.vue";

export default defineComponent({
  name: "TableRP",
  components: {
    ButtonUI,
    CardRP,
    CheckboxUI,
    CalcUI,
  },
  data: () => ({
    tableMode: false,
  }),
  computed: {
    ...mapState(["mobile_flag"]),
    ...mapState("room", ["room_data", "player_data"]),
    ...mapGetters({
      isDashboard: "room/isDashboard",
      playersOnly: "room/playersOnly",
      readyPlayers: "room/readyPlayers",
    }),
    emptyPlayers(): null[] {
      const result = new Array(2);

      this.playersOnly.forEach(() => result.pop());

      return result;
    },
    cardClass(): string {
      return this.tableMode
        ? this.playersOnly.length > 3
          ? "TableRP-Main_grid"
          : "TableRP-Main_few"
        : "TableRP-Main_list";
    },
    is_open: {
      get(): boolean | null {
        const is_open = this.room_data.is_open;

        return typeof is_open === "boolean" ? is_open : null;
      },
      set(is_open: boolean) {
        this.makeRoomPrivate(is_open);
      },
    },
    max_random_players: {
      get(): number {
        return this.room_data.max_random_players;
      },
      set(max_random_players: number) {
        this.changeMaxRandomPlayers(max_random_players);
      },
    },
  },
  watch: {
    mobile_flag(mobile_flag: boolean) {
      mobile_flag && this.setTableMode(false);
    },
  },
  methods: {
    ...mapMutations({
      setPopupData: "SET_POPUP_DATA",
    }),
    ...mapActions({
      startGame: "room/startGame",
      playerReady: "room/playerReady",
      makeRoomPrivate: "room/makeRoomPrivate",
      changeMaxRandomPlayers: "room/changeMaxRandomPlayers",
    }),
    setTableMode(flag: boolean) {
      this.tableMode = flag;
    },
  },
});
