import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2500e4a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "AnswerImagesGP" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = {
  key: 1,
  class: "AnswerImagesGP-Checkbox"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["AnswerImagesGP-Item", [
        _ctx.value.id === item.id && 'AnswerImagesGP-Item_selected',
        !_ctx.isAbled && 'AnswerImagesGP-Item_disabled',
      ]]),
        key: item.id,
        onClick: ($event: any) => (_ctx.onSelect(item))
      }, [
        (item.file)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "AnswerImagesGP-Image",
              src: item.file,
              alt: item.text
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true),
        (_ctx.isDone && item.id === _ctx.correctAnswer?.id)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, " ✓ "))
          : _createCommentVNode("", true)
      ], 10, _hoisted_2))
    }), 128))
  ]))
}