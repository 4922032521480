
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import ReviewTP from "./ReviewTP.vue";

export default defineComponent({
  name: "TableTP",
  components: { ReviewTP },
  props: {
    leaderboard: {
      type: Array as any,
      required: true,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isList: false,
  }),
  computed: {
    ...mapGetters({
      isLocal: "room/isLocal",
    }),
    titleText(): string {
      return this.isLast ? "Зачет по всем играм" : "Таблица баллов";
    },
  },
  mounted() {
    setTimeout(() => (this.isList = true), 250);
  },
});
