
import { defineComponent } from "vue";
import { mapState, mapActions, mapMutations } from "vuex";

import MusicUI from "@/components/UI/MusicUI.vue";
import ChatUI from "@/components/UI/ChatUI.vue";
import PopupLeaderboard from "@/components/Popups/PopupLeaderboard.vue";
import PopupCreate from "@/components/Popups/PopupCreate.vue";
import PopupJoin from "@/components/Popups/PopupJoin.vue";
import PopupToLobby from "@/components/Popups/PopupToLobby.vue";
import PopupLogout from "@/components/Popups/PopupLogout.vue";
import PopupPause from "@/components/Popups/PopupPause.vue";
import PopupKick from "@/components/Popups/PopupKick.vue";
import PopupReset from "@/components/Popups/PopupReset.vue";
import PopupAdult from "@/components/Popups/PopupAdult.vue";
import PopupNeedAuth from "@/components/Popups/PopupNeedAuth.vue";
import PopupError from "@/components/Popups/PopupError.vue";
import PopupExit from "@/components/Popups/PopupExit.vue";
import PopupAuth from "@/components/Popups/PopupAuth.vue";
import PopupAvatars from "@/components/Popups/PopupAvatars.vue";
import PopupSubscribe from "@/components/Popups/PopupSubscribe.vue";
import PopupContacts from "@/components/Popups/PopupContacts.vue";

import NoSleep from "nosleep.js";

const noSleep = new NoSleep();

export default defineComponent({
  name: "App",
  components: {
    MusicUI,
    ChatUI,
    PopupLeaderboard,
    PopupCreate,
    PopupJoin,
    PopupToLobby,
    PopupLogout,
    PopupPause,
    PopupKick,
    PopupReset,
    PopupAdult,
    PopupNeedAuth,
    PopupError,
    PopupExit,
    PopupAuth,
    PopupAvatars,
    PopupSubscribe,
    PopupContacts,
  },
  computed: {
    ...mapState(["popup_data", "voice"]),
  },
  watch: {
    voice() {
      if (["/preview", "/game", "/total"].includes(this.$route.path)) {
        const audio = new Audio();

        audio.autoplay = true;
        audio.src = this.voice;
      }
    },
  },
  created() {
    this.checkRoomConnection();
    this.checkAuthConnection();
  },
  mounted() {
    document.addEventListener("click", () => {
      !noSleep.isEnabled && noSleep.enable();
    });

    this.checkMobile();

    setTimeout(() => {
      this.checkSponsorToken();
    }, 2000);

    // Установка флага мобильного устройства (добавление слушателя)
    window.addEventListener("resize", this.checkMobile);
  },
  beforeUnmount() {
    // Установка флага мобильного устройства (удаление слушателя)
    window.removeEventListener("resize", this.checkMobile);

    noSleep.isEnabled && noSleep.disable();
  },
  methods: {
    ...mapActions({
      checkAuthConnection: "profile/checkAuthConnection",
      checkSponsorToken: "profile/checkSponsorToken",
      checkRoomConnection: "room/checkRoomConnection",
      reset: "room/reset",
      resume: "room/resume",
      disconnect: "room/disconnect",
      kickPlayer: "room/kickPlayer",
    }),
    ...mapMutations({
      setMobileFlag: "SET_MOBILE_FLAG",
      setPopupData: "SET_POPUP_DATA",
      setExcludedTags: "room/SET_EXCLUDED_TAGS",
    }),
    checkMobile() {
      this.setMobileFlag(window.innerWidth < 1200);
    },
    onChangeTags(id: number) {
      this.setExcludedTags(id);
      this.setPopupData({ status: "" });
    },
  },
});
