
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { useMeta } from "vue-meta";

import BackgroundUI from "@/components/UI/BackgroundUI.vue";
import HeaderHome from "@/components/HeaderBlock/HeaderHome.vue";

const siteData = {
  title: "Как провести вечер: друзья, команды и любовь на Partygames.lol",
  description:
    "На Partygames.lol одинокие вечера превращаются в незабываемое приключение с нашими онлайн-квизами! Найдите новых друзей, соберите команду и, возможно, встретите свою любовь. Веселые вопросы и комедийные моменты гарантированы!",
  keywords:
    "вечеринка, квиз, онлайнигра, викторина, логическая игра, тайны любви, друзья, команда, любовь, играть вместе, квизлет, счастливый конец",
  href:
    process.env.VUE_APP_CURRENT_ADDRESS +
    "blog/druzia_i_komandi_partygames_lol",
};

export default defineComponent({
  name: "DescriptionPage",
  setup() {
    useMeta({
      title: siteData.title,
      description: siteData.description,
      keywords: siteData.keywords,
      link: [
        {
          rel: "canonical",
          href: siteData.href,
        },
      ],
      og: {
        title: siteData.title,
        description: siteData.description,
      },
    });
  },
  components: {
    BackgroundUI,
    HeaderHome,
  },
  computed: {
    ...mapState(["mobile_flag"]),
  },
});
