import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21bc68d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "PreviewPage-Main Frame"
}
const _hoisted_2 = { class: "PreviewPage-Title" }
const _hoisted_3 = { class: "PreviewPage-Subtitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackgroundUI = _resolveComponent("BackgroundUI")!
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["PreviewPage", _ctx.mobile_flag && 'GridBg'])
  }, [
    (!_ctx.mobile_flag)
      ? (_openBlock(), _createBlock(_component_BackgroundUI, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_Transition, { name: "slide-left" }, {
      default: _withCtx(() => [
        (_ctx.isButton)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "Pause Pause_pulse Icon Icon_black",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onPause && _ctx.onPause(...args)))
            }, [
              _createVNode(_component_inline_svg, {
                title: "Приостановить игру",
                class: "Icon-Image",
                src: 
            require(_ctx.isDashboard
              ? '@/assets/icons/game-pause.svg'
              : '@/assets/icons/exit.svg')
          
              }, null, 8, ["src"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.isMain)
          ? (_openBlock(), _createElementBlock("main", _hoisted_1, [
              _createElementVNode("h1", _hoisted_2, " Раунд " + _toDisplayString(_ctx.round_data.current_round) + " / " + _toDisplayString(_ctx.round_data.rounds_quantity), 1),
              _createElementVNode("h2", _hoisted_3, " В раунде " + _toDisplayString(_ctx.round_data.question_in_current_round) + " вопросов ", 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}