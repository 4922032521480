import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-728d55f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "HintUI" }
const _hoisted_2 = { class: "HintUI-Dropdown Frame" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_inline_svg, {
      class: "HintUI-Icon",
      src: require('@/assets/icons/hint.svg')
    }, null, 8, ["src"]),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}