
import { defineComponent } from "vue";

import { mapState } from "vuex";

import PopupUI from "@/components/UI/PopupUI.vue";

export default defineComponent({
  name: "PopupExit",
  emits: ["close"],
  components: {
    PopupUI,
  },
  computed: {
    ...mapState(["popup_data"]),
  },
});
