
import { defineComponent } from "vue";
import ButtonUI from "@/components/UI/ButtonUI.vue";

export default defineComponent({
  name: "HintsGP",
  components: { ButtonUI },
  emits: ["use"],
  props: {
    images: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isPopup: false,
  }),
  methods: {
    setIsPopup(flag: boolean) {
      this.isPopup = flag;
    },
    useHint() {
      this.setIsPopup(false);
      this.$emit("use");
    },
  },
});
