
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { useMeta } from "vue-meta";

import BackgroundUI from "@/components/UI/BackgroundUI.vue";
import HeaderHome from "@/components/HeaderBlock/HeaderHome.vue";
import ButtonUI from "@/components/UI/ButtonUI.vue";

const siteData = {
  title: "Отзывы о квиз викторине Partygames.lol",
  description:
    "Почитайте, что говорят настоящие фанаты о наших веселых квиз-викторинах на Partygames.lol! Узнайте, как наши онлайн викторины превращают обычные вечера в мозговые бури и веселье. Присоединяйтесь к тусовке, где каждый отзыв – это маленькая история о больших победах!",
  keywords:
    "игры квизы, онлайн викторины, квиз-викторины, отзывы о Partygames.lol, квиз вечеринки, соревновательные игры, интеллектуальные викторины, топ игроков квиз, увлекательные вопросы, вопросы с ответами",
  href: process.env.VUE_APP_CURRENT_ADDRESS + "reviews",
};

export default defineComponent({
  name: "ReviewsPage",
  setup() {
    useMeta({
      title: siteData.title,
      description: siteData.description,
      keywords: siteData.keywords,
      link: [
        {
          rel: "canonical",
          href: siteData.href,
        },
      ],
      og: {
        title: siteData.title,
        description: siteData.description,
      },
    });
  },
  data: () => ({
    loaded: false,
    users: [
      {
        id: 1,
        avatar: require("@/assets/img/ava1.png"),
        name: "Дмитрий, 40 лет",
        date: "14.05.24",
        text: "наконец то нашел сайт с крутыми квизами. почти каждый вечер играем с друзьями семьей и даже со случайными людьми))",
      },
      {
        id: 2,
        avatar: require("@/assets/img/ava2.png"),
        name: "Оля, 28 лет",
        date: "13.05.24",
        text: "уже месяц играю, и каждый раз новые интересные вопросы. очень нравится! и один парень подарил мне подписку! а я его по чату игры только знаю",
      },
      {
        id: 3,
        avatar: require("@/assets/img/ava3.png"),
        name: "Вано, 34 года",
        date: "06.07.24",
        text: "Зашло! Вопросы интересные, можно соревноваться с друзьями и другими игроками. Участвовал в розыгрыше подписки, очень напряженная и смешная битва. Рекомендую!",
      },
      {
        id: 4,
        avatar: require("@/assets/img/ava4.png"),
        name: "Мария, 25 года",
        date: "18.06.24",
        text: "Люблю интеллектуальные игры, а тут их просто море... Теперь это моя новая зависимость.",
      },
      {
        id: 5,
        avatar: require("@/assets/img/ava5.png"),
        name: "Сергей, 29 лет",
        date: "13.06.24",
        text: "Вопросы супер. Каждый вечер с девушкой устраиваем квизы, очень увлекательно)) Есть даже 18+",
      },
      {
        id: 6,
        avatar: require("@/assets/img/ava6.png"),
        name: "weirdcxt, 19 лет",
        date: "15.07.24",
        text: "патигеймс рили лучший способ на данный момент провести вечер с друзьями и не только. база короче",
      },
      {
        id: 7,
        avatar: require("@/assets/img/ava7.png"),
        name: "Леха, 27 лет",
        date: "30.05.24",
        text: "крутая развлекуха после работы всегда интересно проверить себя ну или узнать что то новое) тем более когда такой большой выбор)",
      },
      {
        id: 8,
        avatar: require("@/assets/img/ava8.png"),
        name: "Наталья, 40 лет",
        date: "29.05.24",
        text: "С детьми проводим время на вашем сайтею И весело, и познавательно! Рекомендую всем семьям!",
      },
      {
        id: 9,
        avatar: require("@/assets/img/ava9.png"),
        name: "маминфонкер, 21 год",
        date: "16.05.24",
        text: "Норм игры. Есть и кринжовые вопросы, есть и рофельные, но в основном всегда весело + коммьюнити приятное) Очень спасает от одиночества.",
      },
      {
        id: 10,
        avatar: require("@/assets/img/ava10.png"),
        name: "taoetaoe, 25 лет",
        date: "24.04.24",
        text: "база, парни. все заходим, играем, не стесняемся",
      },
      {
        id: 11,
        avatar: require("@/assets/img/ava11.png"),
        name: "Виктор, 31 год",
        date: "08.05.24",
        text: "Люблю квизы спасибо за новое место. Здорово, что можно играть и одному, и с друзьями",
      },
      {
        id: 12,
        avatar: require("@/assets/img/ava12.png"),
        name: "Елена, 31 лет",
        date: "02.05.24",
        text: "На Partygames всегда интересно, много нового узнаешь и соревнуешься с другими. Агонь!",
      },
      {
        id: 13,
        avatar: require("@/assets/img/ava13.png"),
        name: "Екатерина, 35 лет",
        date: "09.06.24",
        text: "Нашли с коллегами отличное развлечение на обеденных перерывах. Интересные вопросы и здоровая конкуренция. :)",
      },
    ],
  }),
  components: {
    BackgroundUI,
    HeaderHome,
    ButtonUI,
  },
  computed: {
    ...mapState(["mobile_flag"]),
    loadedUsers(): any[] {
      return this.loaded ? this.users : this.users.slice(0, 4);
    },
    buttonText(): string {
      return this.loaded ? "Скрыть" : "Загрузить ещё";
    },
  },
});
