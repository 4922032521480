<template>
  <div class="LeftGroupImg">
    <template v-for="(img, index) in images">
      <img
        v-if="img.src"
        :key="index"
        :src="img.src"
        :alt="img.alt || 'image'"
      />
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "LeftGroupImg",
  data: () => ({
    images: [
      { src: null },
      {
        src: require("@/assets/icons/leftGroupImgHeader/img1.svg"),
        alt: "Игры онлайн квиз комнату",
      },
      {
        src: require("@/assets/icons/leftGroupImgHeader/img2.svg"),
        alt: "Создай комнату номер комнаты",
      },
      {
        src: require("@/assets/icons/leftGroupImgHeader/img3.svg"),
        alt: "игр для компании ",
      },
      {
        src: require("@/assets/icons/leftGroupImgHeader/img4.svg"),
        alt: "играть вместе",
      },

      {
        src: require("@/assets/icons/leftGroupImgHeader/img5.svg"),
        alt: "прямо в браузере",
      },
      {
        src: require("@/assets/icons/leftGroupImgHeader/img6.svg"),
        alt: "войти в комнату наблюдать за другими персонажами",
      },
      {
        src: require("@/assets/icons/leftGroupImgHeader/img7.svg"),
        alt: "веселые игры для компании.",
      },
    ],
  }),
});
</script>

<style lang="sass" scoped>
.LeftGroupImg
  position: relative
  top: -50px
  display: grid
  grid-template-columns: repeat(2, 150px)
  gap: 20px
  align-items: center

  & > :nth-child(1n)
    align-self: flex-start
    padding: 0 0 20px 0

  & > :nth-child(2n)
    align-self: flex-end
    padding: 30px 0 0 0

@media (max-width: 1300px)
  .LeftGroupImg
    gap: unset
    grid-template-columns: repeat(2, 100px)
@media (max-width: 1100px)
  .LeftGroupImg
    grid-template-columns: unset
    width: 100%
    display: flex
    justify-content: space-between
@media (max-width: 750px)
  img
    width: 55px
@media (max-width: 400px)
  .LeftGroupImg
    &>:last-child
      display: none
</style>
