
import { defineComponent } from "vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";

import BackgroundUI from "@/components/UI/BackgroundUI.vue";

import AudioUI from "@/components/UI/AudioUI.vue";
import VideoUI from "@/components/UI/VideoUI.vue";
// import AnswerInputGP from "@/components/GamePage/AnswerInputGP.vue";
import AnswerSelectGP from "@/components/GamePage/AnswerSelectGP.vue";
import AnswerImagesGP from "@/components/GamePage/AnswerImagesGP.vue";
import AnswerDraggableGP from "@/components/GamePage/AnswerDraggableGP.vue";
import HintsGP from "@/components/GamePage/HintsGP.vue";
import ButtonUI from "@/components/UI/ButtonUI.vue";
import HintUI from "@/components/UI/HintUI.vue";

import PopupAnsweredPlayers from "@/components/Popups/PopupAnsweredPlayers.vue";

export default defineComponent({
  name: "GamePage",
  components: {
    CircleProgress,
    BackgroundUI,
    AudioUI,
    VideoUI,
    // AnswerInputGP,
    AnswerSelectGP,
    AnswerImagesGP,
    AnswerDraggableGP,
    HintsGP,
    ButtonUI,
    HintUI,
    PopupAnsweredPlayers,
  },
  data: () => ({
    isMain: false,
    isButton: false,
    isInfo: false,
    isApply: false,
    isDone: false,
    answer: {},
    currentReaction: "",
    reactions: [
      {
        value: "L",
        text: "Нравится",
        icon: require("@/assets/icons/like.svg"),
      },
      {
        value: "D",
        text: "Не нравится",
        icon: require("@/assets/icons/dislike.svg"),
      },
      {
        value: "E",
        text: "Ошибка в вопросе",
        icon: require("@/assets/icons/error.svg"),
      },
      {
        value: "R",
        text: "Восторг",
        icon: require("@/assets/icons/rapture.svg"),
      },
    ],
  }),
  computed: {
    ...mapState(["mobile_flag", "popup_data"]),
    ...mapState("room", [
      "player_data",
      "selected_game",
      "question_data",
      "correct_answer",
      "player_answer",
      "answered_players",
      "timer",
      "answer_voice",
      "game_status",
    ]),
    ...mapGetters({
      isDashboard: "room/isDashboard",
      isActivePlayer: "room/isActivePlayer",
    }),
    noNeedConfirm(): boolean {
      return !this.isApply && this.question_data.answer_format === "C";
    },
    answeredCount(): number {
      const result = this.answered_players.max_count
        ? this.answered_players.count / this.answered_players.max_count
        : 0;

      return !isNaN(result) ? result * 100 : 0;
    },
    isQuestionImage(): boolean {
      return (
        this.question_data.question_format === "I" && this.question_data.file
      );
    },
    isQuestionVideo(): boolean {
      return (
        this.question_data.question_format === "V" && this.question_data.file
      );
    },
    isQuestionAudio(): boolean {
      return (
        this.question_data.question_format === "M" && this.question_data.audio
      );
    },
    isAnswerImage(): boolean {
      return (
        this.question_data.answer_format === "I" &&
        this.question_data.answers[0]?.file
      );
    },
    isCorrectImage(): boolean {
      const file = this.correct_answer.file_to_response;

      return file && file.format === "picture" && file.correct_file;
    },
    isCorrectVideo(): boolean {
      const file = this.correct_answer.file_to_response;

      return file && file.format === "video" && file.correct_file;
    },
    isCorrectAudio(): boolean {
      const file = this.correct_answer.file_to_response;

      return file && file.format === "audio" && file.correct_file;
    },
    isCorrectAnswer(): boolean {
      return !!Object.keys(this.correct_answer)?.length;
    },
    isMedia(): boolean {
      return Boolean(
        this.isCorrectAnswer
          ? this.isCorrectImage || this.isCorrectVideo
          : this.isAnswerImage || this.isQuestionImage || this.isQuestionVideo
      );
    },
    imageSrc(): string {
      return this.isCorrectImage
        ? this.correct_answer.file_to_response?.correct_file || ""
        : !this.isCorrectAnswer && this.isQuestionImage
        ? this.question_data.file
        : "";
    },
    videoSrc(): string {
      return this.isCorrectVideo
        ? this.correct_answer.file_to_response?.correct_file || ""
        : !this.isCorrectAnswer && this.isQuestionVideo
        ? this.question_data.file
        : "";
    },
    audioSrc(): string {
      return this.isCorrectAudio
        ? this.correct_answer.file_to_response?.correct_file || ""
        : !this.isCorrectAnswer && this.isQuestionAudio
        ? this.question_data.audio
        : "";
    },
    largeQuestion(): boolean {
      return this.question_data.text.length > 65;
    },
  },
  watch: {
    question_data: {
      handler() {
        this.setIsApply(false);
        this.setIsDone(false);
        this.isMain = false;
        this.isInfo = false;
        this.answer = {};
        this.currentReaction = "";

        setTimeout(() => {
          this.isMain = true;
        }, 500);
        setTimeout(() => {
          this.isInfo = true;
        }, 1000);
      },
      deep: true,
    },
    player_answer: {
      handler() {
        this.answer = this.player_answer;
        Object.keys(this.player_answer)?.length && this.setIsApply(true);
      },
      deep: true,
    },
    correct_answer: {
      handler() {
        this.isCorrectAnswer && this.setIsDone(true);
      },
      deep: true,
    },
    answer_voice() {
      const audio = new Audio();

      audio.autoplay = true;
      audio.src = this.answer_voice;
    },
  },
  methods: {
    ...mapMutations({
      setPopupData: "SET_POPUP_DATA",
    }),
    ...mapActions({
      pause: "room/pause",
      answersPipeline: "room/answersPipeline",
      addReactions: "room/addReactions",
      setBoost: "room/setBoost",
      usedHints: "room/usedHints",
    }),
    onBoost() {
      !this.player_data.boosted_round && this.setBoost();
    },
    onPause() {
      this.isDashboard
        ? this.pause()
        : this.setPopupData({ status: "to_lobby" });
    },
    onAnswer() {
      this.setIsApply(!this.isApply);
      this.isApply && this.answersPipeline(this.answer);
    },
    setIsApply(isApply: boolean) {
      this.isActivePlayer && (this.isApply = isApply);
    },
    setIsDone(isDone: boolean) {
      this.isDone = isDone;
      isDone && this.setPopupData({ root_status: "", status: "" });
    },
    setReactions(reaction: string) {
      this.currentReaction = reaction;
      this.addReactions(reaction);
    },
  },
  created() {
    this.answer = this.player_answer;

    !this.game_status && this.$router.push("/room");
  },
  mounted() {
    setTimeout(() => (this.isMain = true), 500);
    setTimeout(() => (this.isButton = true), 750);
    setTimeout(() => (this.isInfo = true), 1000);
  },
});
