import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-83199cc8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Footer" }
const _hoisted_2 = { class: "Footer-Wrapper" }
const _hoisted_3 = { class: "Footer-Inner" }
const _hoisted_4 = {
  href: "https://vk.com/public221729607",
  class: "Footer-Link",
  "aria-label": "vk",
  target: "_blank"
}
const _hoisted_5 = {
  href: "https://dzen.ru/id/66a3c78bba5e8b1a46dafd1d",
  class: "Footer-Link",
  "aria-label": "dzen",
  target: "_blank"
}
const _hoisted_6 = {
  href: "https://discord.com/channels/1217304632049795112/1217304632049795114",
  class: "Footer-Link",
  "aria-label": "discord",
  target: "_blank"
}
const _hoisted_7 = {
  href: "https://t.me/partygameslol_quize",
  class: "Footer-Link",
  "aria-label": "tg",
  target: "_blank"
}
const _hoisted_8 = { class: "Footer-Info" }
const _hoisted_9 = ["href"]
const _hoisted_10 = ["href"]
const _hoisted_11 = ["href"]
const _hoisted_12 = ["href"]
const _hoisted_13 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_ReportSupport = _resolveComponent("ReportSupport")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("footer", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("a", _hoisted_4, [
            _createVNode(_component_inline_svg, {
              class: "Footer-LinkIcon",
              src: require('@/assets/icons/vk.svg')
            }, null, 8, ["src"])
          ]),
          _createElementVNode("a", _hoisted_5, [
            _createVNode(_component_inline_svg, {
              class: "Footer-LinkIcon",
              src: require('@/assets/icons/yazen.svg')
            }, null, 8, ["src"])
          ]),
          _createElementVNode("a", _hoisted_6, [
            _createVNode(_component_inline_svg, {
              class: "Footer-LinkIcon",
              src: require('@/assets/icons/discord.svg')
            }, null, 8, ["src"])
          ]),
          _createElementVNode("a", _hoisted_7, [
            _createVNode(_component_inline_svg, {
              class: "Footer-LinkIcon",
              src: require('@/assets/icons/telegram.svg')
            }, null, 8, ["src"])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("span", {
            class: "Footer-InfoItem",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setOpenPopUp(true)))
          }, "Сообщить об ошибке / Поддержка"),
          _createElementVNode("a", {
            class: "Footer-InfoItem",
            target: "_blank",
            download: "",
            href: _ctx.privacy_policy
          }, "Политика конфиденциальности", 8, _hoisted_9),
          _createElementVNode("a", {
            class: "Footer-InfoItem",
            target: "_blank",
            download: "",
            href: _ctx.terms_of_use
          }, "Пользовательское соглашение", 8, _hoisted_10),
          _createElementVNode("span", {
            class: "Footer-InfoItem",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setPopupData({ root_status: 'contacts' })))
          }, "Реквизиты и контакты"),
          _createElementVNode("a", {
            class: "Footer-InfoItem",
            target: "_blank",
            download: "",
            href: _ctx.paygine_security_policy
          }, "Политика безопасности", 8, _hoisted_11),
          _createElementVNode("a", {
            class: "Footer-InfoItem",
            target: "_blank",
            download: "",
            href: _ctx.subscription_agreement
          }, "Оферта", 8, _hoisted_12),
          _createElementVNode("a", {
            class: "Footer-InfoItem",
            target: "_blank",
            download: "",
            href: _ctx.payment_and_refund
          }, "Оплата и возврат", 8, _hoisted_13)
        ])
      ])
    ]),
    (_ctx.rsPopup)
      ? (_openBlock(), _createBlock(_component_ReportSupport, {
          key: 0,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setOpenPopUp(false)))
        }))
      : _createCommentVNode("", true)
  ], 64))
}