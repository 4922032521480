
import { defineComponent } from "vue";
import { mapState } from "vuex";

import NavBar from "@/components/HeaderBlock/NavBar.vue";
import BurgerMenu from "@/components/HeaderBlock/BurgerMenu.vue";

export default defineComponent({
  name: "HeaderHome",
  components: {
    NavBar,
    BurgerMenu,
  },
  props: {
    yellow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(["mobile_flag"]),
  },
});
