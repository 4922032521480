
import { defineComponent } from "vue";

import PopupUI from "@/components/UI/PopupUI.vue";

export default defineComponent({
  name: "PasswordUI",
  components: {
    PopupUI,
  },
  emits: ["update:value", "done", "close"],
  props: {
    value: {
      type: String,
      required: true,
    },
    length: {
      type: Number,
      default: 4,
    },
  },
  methods: {
    onKeyup(event: any, index: number) {
      const inputs = this.$refs.inputs as HTMLInputElement[];

      if (event.keyCode === 8) {
        if (index === 0) {
          inputs[inputs.length - 1].focus();
        } else {
          inputs[index - 1].focus();
        }
      }
    },
    onInput(event: any, index: number) {
      const inputs = this.$refs.inputs as HTMLInputElement[];
      const current_el = event.target as HTMLInputElement;

      current_el.value = "";

      if (event.data?.match(/\d/)) {
        current_el.value = event.data;

        const new_value = inputs
          .map((input: HTMLInputElement) => input.value)
          .join("");

        if (index === inputs.length - 1) {
          inputs[0]?.focus();
        } else {
          inputs[index + 1]?.focus();
        }

        this.$emit("update:value", new_value);

        if (new_value.length === this.length) {
          this.$emit("done");
        }
      }
    },
  },
  mounted() {
    const inputs = this.$refs.inputs as HTMLInputElement[];

    if (this.value.length === this.length) {
      inputs.forEach(
        (input: HTMLInputElement, index: number) =>
          (input.value = this.value[index])
      );
    }

    this.$nextTick(() => inputs[0].focus());
  },
});
