
import { defineComponent } from "vue";

import { mapGetters } from "vuex";

import PopupUI from "@/components/UI/PopupUI.vue";
import ButtonUI from "@/components/UI/ButtonUI.vue";

export default defineComponent({
  name: "PopupSetDashboard",
  emit: ["close", "set"],
  components: {
    PopupUI,
    ButtonUI,
  },
  computed: {
    ...mapGetters({
      playersOnlyClean: "room/playersOnlyClean",
    }),
  },
});
