import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5adb8a00"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "TableTP" }
const _hoisted_2 = { class: "TableTP-Title" }
const _hoisted_3 = {
  key: 0,
  class: "TableTP-List List"
}
const _hoisted_4 = { class: "List-ItemWrapper" }
const _hoisted_5 = { class: "List-ItemInner" }
const _hoisted_6 = { class: "List-ItemNumber" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "List-ItemInner" }
const _hoisted_9 = { class: "List-ItemText" }
const _hoisted_10 = {
  key: 0,
  class: "List-ItemLocal Status Status_local"
}
const _hoisted_11 = { class: "List-ItemPoints" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReviewTP = _resolveComponent("ReviewTP")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.titleText), 1),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.isList)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leaderboard, (player) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: _normalizeClass(["TableTP-ListItem List-Item", _ctx.isLocal(player) && 'List-Item_local']),
                  key: player.client_id
                }, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("span", _hoisted_6, _toDisplayString(player.place), 1),
                      _createElementVNode("img", {
                        src: player.avatar,
                        alt: "Avatar",
                        class: "List-ItemImage"
                      }, null, 8, _hoisted_7),
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("div", _hoisted_9, _toDisplayString(player.name), 1),
                        (_ctx.isLocal(player))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_10, " Вы "))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_11, _toDisplayString(player.points), 1)
                  ]),
                  (_ctx.isLocal(player) && _ctx.isLast)
                    ? (_openBlock(), _createBlock(_component_ReviewTP, {
                        key: 0,
                        class: "TableTP-Review"
                      }))
                    : _createCommentVNode("", true)
                ], 2))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}