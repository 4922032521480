
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { useMeta } from "vue-meta";

import BackgroundUI from "@/components/UI/BackgroundUI.vue";
import HeaderHome from "@/components/HeaderBlock/HeaderHome.vue";

const siteData = {
  title: "Блог обо всем на Partygames.lol",
  description:
    "Квиз от partygames.lol не даст скучать: идеи для создания креативных вечеринок, последние новости из мира квизов и советы по проведению топовых стримов!",
  keywords:
    "блог, новости, последние новости, стрим, вечеринки, квиз, играть онлайн",
  href: process.env.VUE_APP_CURRENT_ADDRESS + "blog",
};

export default defineComponent({
  name: "ArticlesPage",
  setup() {
    useMeta({
      title: siteData.title,
      description: siteData.description,
      keywords: siteData.keywords,
      link: [
        {
          rel: "canonical",
          href: siteData.href,
        },
      ],
      og: {
        title: siteData.title,
        description: siteData.description,
      },
    });
  },
  data: () => ({
    articles: [
      {
        id: 1,
        image: require("@/assets/img/article1.png"),
        title:
          "Креативные вечеринки с квизами от Partygames.lol: Расследование",
        description:
          "Откройте тайну незабываемых вечеринок с квизами Partygames.lol! Наш детектив выяснил, как...",
        link: "blog/rassledovanie_partygames_lol",
      },
      {
        id: 2,
        image: require("@/assets/img/article2.png"),
        title: "Стримы с квизами от Partygames.lol",
        description: "Как проводить стримы, которые не хочется выключать",
        link: "blog/stream_partygames_lol",
      },
      {
        id: 3,
        image: require("@/assets/img/article3.png"),
        title: "Квиз в мемах и приколах",
        description: "Как оставаться в теме, играя в квизы",
        link: "blog/memi_i_prikoli_partygames_lol",
      },
      {
        id: 4,
        image: require("@/assets/img/article4.png"),
        title: "Как провести вечер: друзья, команды и любовь на Partygames.lol",
        description:
          "На Partygames.lol одинокие вечера превращаются в незабываемое приключение с нашими...",
        link: "blog/druzia_i_komandi_partygames_lol",
      },
      {
        id: 5,
        image: require("@/assets/img/article5.png"),
        title: "Семейные онлайн викторины",
        description: "Как провести интересный вечер с семьей, играя в квизы",
        link: "blog/semia_partygames_lol",
      },
    ],
  }),
  components: {
    BackgroundUI,
    HeaderHome,
  },
  computed: {
    ...mapState(["mobile_flag"]),
  },
});
