import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-41b3a083"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "MusicUI"
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonUI = _resolveComponent("ButtonUI")!

  return (_ctx.music && _ctx.isAudio)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("audio", {
          ref: "music",
          class: "MusicUI-Audio",
          volume: "0.2",
          src: _ctx.music
        }, null, 8, _hoisted_2),
        _createVNode(_Transition, { name: "slide-right" }, {
          default: _withCtx(() => [
            _createVNode(_component_ButtonUI, {
              class: "MusicUI-Control",
              text: "Радио",
              "opacity-dark": "",
              icon: 
          require(!_ctx.music_flag
            ? '@/assets/icons/play.svg'
            : '@/assets/icons/pause.svg')
        ,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setMusicFlag(!_ctx.music_flag)))
            }, null, 8, ["icon"])
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}