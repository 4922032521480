import { createSSRApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import InlineSvg from "vue-inline-svg";
import { plugin as VueInputAutowidth } from "vue-input-autowidth";
import { createMetaManager, defaultConfig } from "vue-meta";

import "./registerServiceWorker";

const app = createSSRApp(App);
const metaManager = createMetaManager(false, {
  ...defaultConfig,
  ...{
    keywords: {
      tag: "meta",
      keyAttribute: "name",
      valueAttribute: "content",
    },
  },
});
const docs_path =
  process.env.VUE_APP_AWS_S3_ENDPOINT_URL +
  "/" +
  process.env.VUE_APP_AWS_STORAGE_BUCKET_NAME +
  "/" +
  process.env.VUE_APP_AWS_MAIN_FOLDER_NAME;

app.config.globalProperties.privacy_policy = docs_path + "/privacy_policy.pdf";
app.config.globalProperties.terms_of_use = docs_path + "/terms_of_use.pdf";
app.config.globalProperties.offer_recurring_payments =
  docs_path + "/offer_recurring_payments.pdf";
app.config.globalProperties.paygine_security_policy =
  docs_path + "/paygine_security_policy.pdf";
app.config.globalProperties.subscription_agreement =
  docs_path + "/subscription_agreement.pdf";
app.config.globalProperties.payment_and_refund =
  docs_path + "/payment_and_refund.pdf";

app.config.globalProperties.trailer = docs_path + "/trailer.mp4";

app.use(router);
app.use(store);
app.use(VueInputAutowidth);

app.component("inline-svg", InlineSvg);
app.use(metaManager);

app.mount("#app");
