import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import HomePage from "@/views/HomePage.vue";
import SearchPage from "@/views/SearchPage.vue";
import ProfilePage from "@/views/ProfilePage.vue";
import RoomPage from "@/views/RoomPage.vue";
import PreviewPage from "@/views/PreviewPage.vue";
import GamePage from "@/views/GamePage.vue";
import TotalPage from "@/views/TotalPage.vue";
import StatisticPage from "@/views/StatisticPage.vue";
import DescriptionPage from "@/views/DescriptionPage.vue";
import HowToPlayPage from "@/views/HowToPlayPage.vue";
import ReviewsPage from "@/views/ReviewsPage.vue";
import ArticlesPage from "@/views/BlogPage/ArticlesPage.vue";
import Article1Page from "@/views/BlogPage/Article1Page.vue";
import Article2Page from "@/views/BlogPage/Article2Page.vue";
import Article3Page from "@/views/BlogPage/Article3Page.vue";
import Article4Page from "@/views/BlogPage/Article4Page.vue";
import Article5Page from "@/views/BlogPage/Article5Page.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: HomePage,
  },
  {
    path: "/search",
    component: SearchPage,
  },
  {
    path: "/profile",
    component: ProfilePage,
  },
  {
    path: "/room",
    component: RoomPage,
  },
  {
    path: "/preview",
    component: PreviewPage,
  },
  {
    path: "/game",
    component: GamePage,
  },
  {
    path: "/total",
    component: TotalPage,
  },
  {
    path: "/statistic",
    component: StatisticPage,
  },
  {
    path: "/description",
    component: DescriptionPage,
  },
  {
    path: "/how_to_play",
    component: HowToPlayPage,
  },
  {
    path: "/reviews",
    component: ReviewsPage,
  },
  {
    path: "/blog",
    children: [
      {
        path: "",
        component: ArticlesPage,
      },
      {
        path: "rassledovanie_partygames_lol",
        component: Article1Page,
      },
      {
        path: "stream_partygames_lol",
        component: Article2Page,
      },
      {
        path: "memi_i_prikoli_partygames_lol",
        component: Article3Page,
      },
      {
        path: "druzia_i_komandi_partygames_lol",
        component: Article4Page,
      },
      {
        path: "semia_partygames_lol",
        component: Article5Page,
      },
    ],
  },
  { path: "/:pathMatch(.*)*", component: HomePage },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
