
import { defineComponent } from "vue";

import draggable from "vuedraggable";

export default defineComponent({
  name: "AnswerDraggableGP",
  components: {
    draggable,
  },
  emits: ["update"],
  props: {
    list: {
      type: Array as any,
      required: true,
    },
    correctAnswer: {
      type: [Array, Object] as any,
      default: () => [],
    },
    playerAnswer: {
      type: [Array, Object] as any,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isApply: {
      type: Boolean,
      required: true,
    },
    isDone: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    localList: [] as any,
  }),
  watch: {
    list() {
      this.localList = this.list;
    },
    playerAnswer() {
      Array.isArray(this.playerAnswer) && (this.localList = this.playerAnswer);
    },
  },
  computed: {
    resultList: {
      get(): any[] {
        return this.disabled ? this.list : this.localList;
      },
      set(list: any[]) {
        this.localList = list;
        this.$emit("update", this.localList);
      },
    },
    isSuccess(): boolean {
      return this.localList.every(
        (item: any, index: number) => item === this.correctAnswer[index]
      );
    },
    isError(): boolean {
      return this.isDone && (this.disabled || !this.isSuccess);
    },
    isAbled(): boolean {
      return !this.disabled && !this.isDone && !this.isApply;
    },
    tipText(): string {
      return !this.isDone
        ? !this.isApply
          ? "Поставь в правильном порядке"
          : "Твой порядок"
        : !this.isSuccess
        ? "Неправильно, твой порядок"
        : "Ответ правильный";
    },
    resultClass(): string {
      return this.isDone
        ? this.isSuccess
          ? "AnswerDraggableGP-Main_success"
          : "AnswerDraggableGP-Main_error"
        : this.isApply
        ? "AnswerDraggableGP-Main_applied"
        : "";
    },
  },
  created() {
    this.localList = this.list;
  },
});
