import { HTTP } from "@/api/api";
// import { EventSourcePolyfill } from "event-source-polyfill";
import router from "@/router/index";
import { useCookies } from "vue3-cookies";
import {
  BotAuth,
  ProfileAuth,
  ProfileData,
  ProfileState,
  ProfileContext,
} from "@/types/types";

const { cookies } = useCookies();
const cookiesConfig = JSON.stringify({
  expireTimes: "30d",
  path: "/",
  domain: "",
  secure: true,
  sameSite: "Lax",
});
let socket = {} as any;

const room = {
  namespaced: true,
  state: (): ProfileState => ({
    is_auth: null,
    bot_auth: {
      link: "",
      qr_code: "",
      starting_value: "",
      expires: 0,
    }, // Данные для авторизации в бота
    profile_auth: {
      access_token: "",
      expires: 0,
      token_type: "",
    }, // Данные для авторизации в личном кабинете
    profile_data: {}, // Данные пользователя
    payments: [], // Данные о платежах пользователя
    sponsor_link_success: false,
    sponsor_link_result: "",
  }),
  getters: {
    // Срок жизни куки
    expiresTime(state: ProfileState): boolean {
      return state.profile_auth.expires > new Date().getTime() / 1000;
    },
    // Проверка на локального игрока
    isLocal:
      (state: ProfileState) =>
      (profile: ProfileData): boolean => {
        return state.profile_data.unique_id === profile?.unique_id;
      },
  },
  actions: {
    // Авторизация
    async auth(context: ProfileContext) {
      let errorMsg = "";

      try {
        await HTTP("GET", "users/auth/login")
          .then((data: any) => {
            const message = data?.message;

            if (message) {
              context.commit("SET_BOT_AUTH", message);
              context.commit(
                "SET_POPUP_DATA",
                { status: "auth" },
                { root: true }
              );

              context.dispatch("initAuthConnection", message);
            } else {
              errorMsg = "Не удалось получить данные авторизации.";
            }
          })
          .catch(() => {
            errorMsg = "Не удалось отправить запрос на авторизацию.";
          });
      } catch (error: any) {
        errorMsg = "Не удалось отправить запрос на авторизацию.";
      }

      if (errorMsg) {
        context.commit(
          "SET_POPUP_DATA",
          {
            status: "error",
            title: "Ошибка",
            description: errorMsg,
          },
          { root: true }
        );

        context.commit("SET_IS_AUTH", false);
      }
    },
    // Выход из ЛК
    signOut(context: ProfileContext) {
      context.commit("SET_PROFILE_AUTH", null);
      context.commit("SET_PROFILE_DATA", null);
      context.commit("SET_IS_AUTH", false);

      router.currentRoute.value?.name === "profile" &&
        router.push({ path: "/" });
    },
    // Создание подключения профиля
    initAuthConnection(context: ProfileContext, data: BotAuth) {
      try {
        socket = new WebSocket(
          `${process.env.VUE_APP_CURRENT_WS_ENV}${
            "auth/" + data.starting_value
          }`
        );

        socket.onerror = () => {
          context.commit(
            "SET_POPUP_DATA",
            {
              status: "error",
              title: "Ошибка",
              description: "Ошибка соединения.",
            },
            { root: true }
          );

          context.dispatch("signOut");
        };
        socket.onmessage = (event: any) => {
          const response = JSON.parse(event.data);

          context.commit("SET_PROFILE_AUTH", response.user);
          context.commit(
            "SET_POPUP_DATA",
            {
              status: "",
            },
            { root: true }
          );

          context.dispatch("getAllInfo", true);
        };
      } catch (error: any) {
        context.commit(
          "SET_POPUP_DATA",
          {
            status: "error",
            title: "Ошибка",
            description: "Ошибка соединения.",
          },
          { root: true }
        );

        context.commit("SET_IS_AUTH", false);
      }
    },
    // Получение полной инфы о профиле
    async getAllInfo(context: ProfileContext, redirect = false) {
      let errorMsg = "";

      try {
        await HTTP("GET", "users/me/payments")
          .then((data: any) => {
            if (data) {
              context.commit("SET_IS_AUTH", true);
              context.commit("SET_PROFILE_DATA", data);
              redirect && router.push({ path: "/profile" });
            } else {
              errorMsg = "Не удалось получить данные пользователя.";
            }
          })
          .catch(() => {
            errorMsg =
              "Не удалось отправить запрос на получение данных о пользователе.";
          });
      } catch (error: any) {
        errorMsg =
          "Не удалось отправить запрос на получение данных о пользователе.";
      }

      if (errorMsg) {
        context.commit(
          "SET_POPUP_DATA",
          {
            status: "error",
            title: "Ошибка",
            description: errorMsg,
          },
          { root: true }
        );

        context.commit("SET_IS_AUTH", false);
      }
    },
    // Проверка аутентификации
    async checkAuthConnection(context: ProfileContext) {
      const profile_auth = cookies.get(
        "profile_auth"
      ) as unknown as ProfileAuth;

      if (
        profile_auth &&
        Number(profile_auth.expires) > new Date().getTime() / 1000
      ) {
        let errorMsg = "";

        try {
          await HTTP("GET", "users/me")
            .then((data: any) => {
              if (data) {
                context.commit("SET_PROFILE_AUTH", profile_auth);
                context.commit("SET_PROFILE_DATA", data);
                context.commit("SET_IS_AUTH", true);
              } else {
                errorMsg = "Не удалось получить данные пользователя.";
              }
            })
            .catch(() => {
              errorMsg =
                "Не удалось отправить запрос на получение данных о пользователе.";
            });
        } catch (error: any) {
          errorMsg =
            "Не удалось отправить запрос на получение данных о пользователе.";
        }

        if (errorMsg) {
          context.commit(
            "SET_POPUP_DATA",
            {
              status: "error",
              title: "Ошибка",
              description: errorMsg,
            },
            { root: true }
          );
          context.commit("SET_IS_AUTH", false);
        }
      } else {
        const bot_auth = cookies.get("bot_auth") as unknown as BotAuth;

        if (Number(bot_auth?.expires) > new Date().getTime() / 1000) {
          context.dispatch("initAuthConnection", bot_auth);
        } else {
          context.dispatch("signOut");
        }
      }
    },
    // Проверка спонсорской ссылки
    async checkSponsorToken(context: ProfileContext) {
      const sponsor_token =
        router.currentRoute.value?.query?.sponsor_token ||
        cookies.get("sponsor_token");
      let errorMsg = "";

      if (sponsor_token) {
        if (context.state.profile_data?.phone) {
          try {
            await HTTP("GET", `users/sponsored?sponsor_token=${sponsor_token}`)
              .then((data: any) => {
                if (data) {
                  if (data.message) {
                    context.commit("SET_SPONSOR_LINK_RESULT", data.message);
                  } else {
                    context.commit("SET_PROFILE_DATA", data);
                    context.commit("SET_SPONSOR_LINK_RESULT", "");
                  }

                  cookies.remove("sponsor_token");
                  context.commit(
                    "SET_POPUP_DATA",
                    {
                      root_status: "subscribe",
                      status: "",
                    },
                    { root: true }
                  );
                } else {
                  errorMsg =
                    "Не удалось получить данные о получении спонсорской подписки.";
                }
              })
              .catch(() => {
                errorMsg = "Не удалось отправить запрос на получение подписки.";
              });
          } catch (error: any) {
            errorMsg = "Не удалось отправить запрос на получение подписки.";
          }
        } else {
          errorMsg = "Нужно авторизоваться для получения спонсорской подписки.";

          cookies.set(
            "sponsor_token",
            JSON.stringify(sponsor_token),
            cookiesConfig
          );
        }
      }

      errorMsg &&
        context.commit(
          "SET_POPUP_DATA",
          {
            status: "error",
            title: "Ошибка",
            description: errorMsg,
          },
          { root: true }
        );
    },
    // Подписка
    async subscribe(context: ProfileContext, payload: ProfileData) {
      let errorMsg = "";

      if (context.getters.expiresTime) {
        try {
          await HTTP("POST", "payment/subscribe", payload)
            .then((data: any) => {
              if (data) {
                if (data.url) {
                  const anchor = document.createElement("a");

                  anchor.href = data.url;
                  anchor.target = "_blank";
                  anchor.click();
                } else {
                  errorMsg = "В ответе отсутствует ссылка.";
                }

                // const token = (
                //   cookies.get("profile_auth") as unknown as ProfileAuth
                // )?.access_token;

                // const eventSource = new EventSourcePolyfill(
                //   process.env.VUE_APP_CURRENT_ENV +
                //     `payment/status/${data.payment_id}`,
                //   {
                //     headers: {
                //       Authorization: token && `Bearer ${token}`,
                //     },
                //   }
                // );

                // eventSource.onmessage = (event: any) => {
                //   const data = JSON.parse(event.data);

                //   if (data) {
                //     context.commit("SET_PROFILE_DATA", data);
                //   }
                // };
              } else {
                errorMsg = "Не удалось получить данные о получении подписки.";
              }
            })
            .catch(() => {
              errorMsg = "Не удалось отправить запрос на получение подписки.";
            });
        } catch (error: any) {
          errorMsg = "Не удалось отправить запрос на получение подписки.";
        }
      } else {
        errorMsg = "Время жизни токена истекло.";
      }

      errorMsg &&
        context.commit(
          "SET_POPUP_DATA",
          {
            status: "error",
            title: "Ошибка",
            description: errorMsg,
          },
          { root: true }
        );
    },
    // Отписка
    async unsubscribe(context: ProfileContext) {
      let errorMsg = "";

      if (context.getters.expiresTime) {
        try {
          await HTTP("GET", "payment/cancel-subscriptions")
            .then((data: any) => {
              if (data) {
                context.commit("SET_PROFILE_DATA", data);
              } else {
                errorMsg = "Не удалось получить данные об отмене подписки.";
              }
            })
            .catch(() => {
              errorMsg = "Не удалось отправить запрос на отмену подписки.";
            });
        } catch (error: any) {
          errorMsg = "Не удалось отправить запрос на отмену подписки.";
        }
      } else {
        errorMsg = "Время жизни токена истекло.";
      }

      errorMsg &&
        context.commit(
          "SET_POPUP_DATA",
          {
            status: "error",
            title: "Ошибка",
            description: errorMsg,
          },
          { root: true }
        );
    },
    // Возобновление подписки
    async renew(context: ProfileContext) {
      let errorMsg = "";

      if (context.getters.expiresTime) {
        try {
          await HTTP("GET", "payment/subscribe/renew")
            .then((data: any) => {
              if (data) {
                context.commit("SET_PROFILE_DATA", data);
              } else {
                errorMsg = "Не удалось отправить запрос на получение подписки.";
              }
            })
            .catch(() => {
              errorMsg = "Не удалось отправить запрос на получение подписки.";
            });
        } catch (error: any) {
          errorMsg = "Не удалось отправить запрос на получение подписки.";
        }
      } else {
        errorMsg = "Время жизни токена истекло.";
      }

      errorMsg &&
        context.commit(
          "SET_POPUP_DATA",
          {
            status: "error",
            title: "Ошибка",
            description: errorMsg,
          },
          { root: true }
        );
    },
    // Отмена спонсорства
    async cancelSponsorship(context: ProfileContext) {
      let errorMsg = "";

      if (context.getters.expiresTime) {
        try {
          await HTTP("GET", "users/cancel-sponsorship")
            .then((data: any) => {
              if (data) {
                context.commit("SET_PROFILE_DATA", data);
              } else {
                errorMsg = "Ошибка при получении данных.";
              }
            })
            .catch(() => {
              errorMsg =
                "Не удалось отправить запрос на получение списка платежей.";
            });
        } catch (error: any) {
          errorMsg =
            "Не удалось отправить запрос на получение списка платежей.";
        }
      } else {
        errorMsg = "Время жизни токена истекло.";
      }

      errorMsg &&
        context.commit(
          "SET_POPUP_DATA",
          {
            status: "error",
            title: "Ошибка",
            description: errorMsg,
          },
          { root: true }
        );
    },
    // Получение списка платежей
    async getPayments(context: ProfileContext) {
      let errorMsg = "";

      if (context.getters.expiresTime) {
        try {
          await HTTP("GET", "payment/all")
            .then((data: any) => {
              const payments = data?.payments;

              if (payments?.length) {
                context.commit("SET_PAYMENTS", payments);
                context.commit(
                  "SET_POPUP_DATA",
                  {
                    root_status: "refund",
                  },
                  { root: true }
                );
              } else {
                errorMsg = "На аккаунте нет завершенных платежей.";
              }
            })
            .catch(() => {
              errorMsg =
                "Не удалось отправить запрос на получение списка платежей.";
            });
        } catch (error: any) {
          errorMsg =
            "Не удалось отправить запрос на получение списка платежей.";
        }
      } else {
        errorMsg = "Время жизни токена истекло.";
      }

      errorMsg &&
        context.commit(
          "SET_POPUP_DATA",
          {
            status: "error",
            title: "Ошибка",
            description: errorMsg,
          },
          { root: true }
        );
    },
    // Возврат ДС
    async refund(context: ProfileContext, payload: any) {
      let errorMsg = "";

      if (context.getters.expiresTime) {
        try {
          await HTTP("POST", "payment/refund", payload)
            .then((data: any) => {
              const status = data?.status;

              if (status === "success") {
                context.commit(
                  "SET_POPUP_DATA",
                  {
                    status: "error",
                    title: "Запрос принят",
                    description: "Данные отправлены на обработку.",
                    autoclose: 1500,
                  },
                  { root: true }
                );
              } else {
                errorMsg =
                  "Не удалось отправить запрос на возврат денежных средств.";
              }
            })
            .catch(() => {
              errorMsg =
                "Не удалось отправить запрос на возврат денежных средств.";
            });
        } catch (error: any) {
          errorMsg = "Не удалось отправить запрос на возврат денежных средств.";
        }
      } else {
        errorMsg = "Время жизни токена истекло.";
      }

      errorMsg &&
        context.commit(
          "SET_POPUP_DATA",
          {
            status: "error",
            title: "Ошибка",
            description: errorMsg,
          },
          { root: true }
        );
    },
    // Генерация спонсорской ссылки
    async generateSponsorLink(context: ProfileContext) {
      let errorMsg = "";

      if (context.getters.expiresTime) {
        try {
          await HTTP("GET", "users/sponsor-token")
            .then((data: any) => {
              if (data?.sponsor_token) {
                const textArea = document.createElement("textarea");

                textArea.value = data.sponsor_token;
                textArea.style.position = "fixed";
                textArea.style.opacity = "0";

                document.body.prepend(textArea);
                textArea.focus();
                textArea.select();

                try {
                  document.execCommand("copy");
                  context.commit("SET_SPONSOR_LINK_SUCCESS", true);

                  setTimeout(() => {
                    context.commit("SET_SPONSOR_LINK_SUCCESS", false);
                  }, 3000);
                } catch (error) {
                  console.error(error);
                } finally {
                  textArea.remove();
                }
              } else {
                errorMsg = "Ошибка получения данных.";
              }
            })
            .catch(() => {
              errorMsg =
                "Не удалось отправить запрос на изменение параметров пользователя.";
            });
        } catch (error: any) {
          errorMsg =
            "Не удалось отправить запрос на изменение параметров пользователя.";
        }
      } else {
        errorMsg = "Время жизни токена истекло.";
      }

      errorMsg &&
        context.commit(
          "SET_POPUP_DATA",
          {
            status: "error",
            title: "Ошибка",
            description: errorMsg,
          },
          { root: true }
        );
    },
    // Обновление профиля после изменения одного из параметров
    async updateProfile(context: ProfileContext, payload: ProfileData) {
      let errorMsg = "";

      if (context.getters.expiresTime) {
        try {
          await HTTP("PATCH", "users/me/update", payload)
            .then((data: any) => {
              if (data) {
                context.commit("SET_PROFILE_DATA", data);
              } else {
                errorMsg =
                  "Не удалось отправить запрос на изменение параметров пользователя.";
              }
            })
            .catch(() => {
              errorMsg =
                "Не удалось отправить запрос на изменение параметров пользователя.";
            });
        } catch (error: any) {
          errorMsg =
            "Не удалось отправить запрос на изменение параметров пользователя.";
        }
      } else {
        errorMsg = "Время жизни токена истекло.";
      }

      errorMsg &&
        context.commit(
          "SET_POPUP_DATA",
          {
            status: "error",
            title: "Ошибка",
            description: errorMsg,
          },
          { root: true }
        );
    },
  },
  mutations: {
    // Установка флага авторизации
    SET_IS_AUTH(state: ProfileState, is_auth = false) {
      state.is_auth = is_auth;
    },
    // Установка данных авторизации
    SET_BOT_AUTH(state: ProfileState, bot_auth: BotAuth) {
      if (bot_auth) {
        state.bot_auth = {
          ...state.bot_auth,
          ...bot_auth,
        };

        cookies.set(
          "bot_auth",
          JSON.stringify({
            starting_value: bot_auth.starting_value,
            expires: bot_auth.expires,
          }),
          cookiesConfig
        );
      } else {
        state.bot_auth = {
          link: "",
          qr_code: "",
          starting_value: "",
          expires: 0,
        };

        cookies.remove("bot_auth");
      }
    },
    // Установка данных для входа в личный кабинет
    SET_PROFILE_AUTH(state: ProfileState, profile_auth: ProfileAuth) {
      if (profile_auth) {
        state.profile_auth = {
          ...state.profile_auth,
          ...profile_auth,
        };

        cookies.set(
          "profile_auth",
          JSON.stringify({
            access_token: profile_auth.access_token,
            expires: profile_auth.expires,
          }),
          cookiesConfig
        );
      } else {
        state.profile_auth = {
          access_token: "",
          expires: 0,
          token_type: "",
        };

        cookies.remove("profile_auth");
      }
    },
    // Установка данных для входа в личный кабинет
    SET_PROFILE_DATA(state: ProfileState, profile_data: ProfileData) {
      if (profile_data) {
        state.profile_data = {
          ...state.profile_data,
          ...profile_data,
        };
      } else {
        state.profile_data = {};
      }
    },
    // Установка данных о платежах пользователя
    SET_PAYMENTS(state: ProfileState, payments: any[]) {
      state.payments = payments || [];
    },
    SET_SPONSOR_LINK_SUCCESS(
      state: ProfileState,
      sponsor_link_success: boolean
    ) {
      state.sponsor_link_success = sponsor_link_success;
    },
    SET_SPONSOR_LINK_RESULT(state: ProfileState, sponsor_link_result: string) {
      state.sponsor_link_result = sponsor_link_result;
    },
  },
};

export default room;
