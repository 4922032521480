import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d189a48"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "PopupSettings-Main Frame"
}
const _hoisted_2 = { class: "PopupSettings-Block" }
const _hoisted_3 = { class: "PopupSettings-Inner" }
const _hoisted_4 = { class: "PopupSettings-Block" }
const _hoisted_5 = { class: "PopupSettings-Inner" }
const _hoisted_6 = { class: "PopupSettings-Inner" }
const _hoisted_7 = {
  key: 0,
  class: "PopupSettings-Inner"
}
const _hoisted_8 = {
  key: 1,
  class: "PopupSettings-Inner"
}
const _hoisted_9 = {
  key: 2,
  class: "PopupSettings-Inner"
}
const _hoisted_10 = {
  key: 0,
  class: "PopupSettings-Block"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CalcUI = _resolveComponent("CalcUI")!
  const _component_InputUI = _resolveComponent("InputUI")!
  const _component_CheckboxUI = _resolveComponent("CheckboxUI")!
  const _component_ButtonUI = _resolveComponent("ButtonUI")!
  const _component_SwitchUI = _resolveComponent("SwitchUI")!
  const _component_PopupUI = _resolveComponent("PopupUI")!

  return (_openBlock(), _createBlock(_component_PopupUI, {
    class: "PopupSettings",
    onClose: _cache[13] || (_cache[13] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createVNode(_Transition, { name: "slide-top" }, {
        default: _withCtx(() => [
          (_ctx.isMain)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_CalcUI, {
                      value: _ctx.localQuestionCount,
                      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localQuestionCount) = $event)),
                      label: "Количество вопросов",
                      class: "PopupSettings-Input",
                      "is-buttons": "",
                      min: 0,
                      max: _ctx.game_options.question_max || 50,
                      validation: _ctx.questionCountValid
                    }, null, 8, ["value", "max", "validation"]),
                    _createVNode(_component_CalcUI, {
                      value: _ctx.response_time,
                      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.response_time) = $event)),
                      label: "Время на ответ",
                      unit: "секунд",
                      class: "PopupSettings-Input",
                      min: 0,
                      max: 60
                    }, null, 8, ["value"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_InputUI, {
                      value: _ctx.room_id,
                      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.room_id) = $event)),
                      type: "number",
                      label: "Комната",
                      class: "PopupSettings-Input",
                      disabled: ""
                    }, null, 8, ["value"]),
                    _createVNode(_component_InputUI, {
                      value: _ctx.localPassword,
                      "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localPassword) = $event)),
                      label: "Пароль",
                      class: "PopupSettings-Input",
                      validation: _ctx.passwordValid
                    }, null, 8, ["value", "validation"])
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_CalcUI, {
                      value: _ctx.max_players,
                      "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.max_players) = $event)),
                      label: "Максимум игроков",
                      class: "PopupSettings-Input",
                      "is-buttons": "",
                      min: _ctx.playersOnly.length || 1,
                      max: 50
                    }, null, 8, ["value", "min"]),
                    (_ctx.is_open)
                      ? (_openBlock(), _createBlock(_component_CalcUI, {
                          key: 0,
                          value: _ctx.max_random_players,
                          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.max_random_players) = $event)),
                          label: "Максимум рандомов",
                          class: "PopupSettings-Input",
                          "is-buttons": "",
                          min: 1,
                          max: _ctx.max_players - _ctx.playersOnly.length
                        }, null, 8, ["value", "max"]))
                      : _createCommentVNode("", true)
                  ]),
                  (
              !_ctx.room_data.system && (_ctx.active_player !== null || _ctx.is_open !== null)
            )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        (_ctx.active_player !== null)
                          ? (_openBlock(), _createBlock(_component_CheckboxUI, {
                              key: 0,
                              value: _ctx.active_player,
                              "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.active_player) = $event)),
                              label: "Дашборд-игрок",
                              hint: "Админ комнаты становится игроком",
                              class: "PopupSettings-Input"
                            }, null, 8, ["value"]))
                          : _createCommentVNode("", true),
                        (_ctx.is_open !== null)
                          ? (_openBlock(), _createBlock(_component_CheckboxUI, {
                              key: 1,
                              value: _ctx.is_open,
                              "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.is_open) = $event)),
                              label: "Позвать гостей",
                              class: "PopupSettings-Input"
                            }, null, 8, ["value"]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.is_boost !== null || _ctx.use_hints !== null)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        (_ctx.is_boost !== null)
                          ? (_openBlock(), _createBlock(_component_CheckboxUI, {
                              key: 0,
                              value: _ctx.is_boost,
                              "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.is_boost) = $event)),
                              label: "Буст раунда",
                              hint: "Умножает баллы при верном ответе, при неверном - забирает",
                              class: "PopupSettings-Input"
                            }, null, 8, ["value"]))
                          : _createCommentVNode("", true),
                        (_ctx.use_hints !== null)
                          ? (_openBlock(), _createBlock(_component_CheckboxUI, {
                              key: 1,
                              value: _ctx.use_hints,
                              "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.use_hints) = $event)),
                              label: "Подсказки",
                              hint: "Убирает один неверный ответ",
                              class: "PopupSettings-Input"
                            }, null, 8, ["value"]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true),
                  (!_ctx.room_data.system && _ctx.dashboardsOnly.length < 2)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createVNode(_component_ButtonUI, {
                          text: "Новый дашборд",
                          class: "PopupSettings-Button",
                          hint: "Назначить нового админа комнаты",
                          onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.setPopupData({ status: 'set_dashboard' })))
                        })
                      ]))
                    : _createCommentVNode("", true)
                ]),
                (_ctx.game_options.tags)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createVNode(_component_SwitchUI, {
                        value: _ctx.tags,
                        "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.tags) = $event)),
                        label: "Темы",
                        class: "PopupSettings-Search",
                        list: _ctx.game_options.tags,
                        condition: _ctx.profile_data.is_signed,
                        excluded: _ctx.excludedTags,
                        onWarning: _cache[12] || (_cache[12] = ($event: any) => (_ctx.setPopupData({ status: '18+' })))
                      }, null, 8, ["value", "list", "condition", "excluded"])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}