
import { defineComponent } from "vue";

import { mapGetters } from "vuex";

import PopupUI from "@/components/UI/PopupUI.vue";
import ButtonUI from "@/components/UI/ButtonUI.vue";

export default defineComponent({
  name: "PopupPause",
  emits: ["resume", "reset", "kick", "to_lobby"],
  components: {
    PopupUI,
    ButtonUI,
  },
  computed: {
    ...mapGetters({
      isDashboard: "room/isDashboard",
    }),
  },
  methods: {
    onKeydown(event: any) {
      if (event.keyCode === 13 && this.isDashboard) {
        event.preventDefault();
        this.$emit("resume");
      }
    },
  },
  mounted() {
    window.addEventListener("keydown", this.onKeydown);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.onKeydown);
  },
});
