
import { defineComponent } from "vue";
import { mapState, mapMutations } from "vuex";
import { useMeta } from "vue-meta";

import HeaderHome from "@/components/HeaderBlock/HeaderHome.vue";
import VideoBlock from "@/components/HomePage/VideoBlock.vue";
import GamesBlock from "@/components/HomePage/GamesBlock.vue";
import InfoBlock from "@/components/HomePage/InfoBlock.vue";
import Footer from "@/components/HomePage/Footer.vue";
import PopupQR from "@/components/Popups/PopupQR.vue";

const siteData = {
  title: "Partygames.lol Игры для  вечеринок",
  description:
    "Время круто повеселиться! Топ - подборка игр 2024 для веселой вечеринки здесь: мозговые штурмы, лайтовые или абсурдные квизы и многое другое! Выбирай любую игру и растворись в эндорфинах, как маршмеллоу в теплом молоке!",
  keywords:
    "игры онлайн без регистрации, игры онлайн, онлайн игры без скачивания, играть вместе, онлайн игры с друзьями, квиз, квиз игра, квиз онлайн, квиз тесты, викторина онлайн, квиз бесплатно, игры для вечеринок игры для компаний онлайн, интерактивные игры онлайн, игры для зума, веселые викторины, бесплатные викторины онлайн, игры для стрима, тесты онлайн, partygames",
  href: process.env.VUE_APP_CURRENT_ADDRESS,
};

export default defineComponent({
  name: "HomePage",
  components: {
    HeaderHome,
    VideoBlock,
    InfoBlock,
    GamesBlock,
    Footer,
    PopupQR,
  },
  setup() {
    useMeta({
      title: siteData.title,
      description: siteData.description,
      keywords: siteData.keywords,
      link: [
        {
          rel: "canonical",
          href: siteData.href,
        },
      ],
      og: {
        title: siteData.title,
        description: siteData.description,
      },
    });
  },
  data: () => ({
    roomId: "",
    password: "",
  }),
  computed: {
    ...mapState(["popup_data"]),
  },
  methods: {
    ...mapMutations({
      setPopupData: "SET_POPUP_DATA",
    }),
    scrollFix(refName: string) {
      const el: any = this.$refs[refName.slice(1)];
      el.$el.scrollIntoView({ behavior: "smooth" });
    },
  },
  mounted() {
    setTimeout(() => {
      this.$route.hash && this.scrollFix(this.$route.hash);

      const query = this.$route.query;

      if (query?.room_id) {
        this.roomId = query.room_id as string;
        this.password = (query.password as string) || "";

        this.setPopupData({ root_status: "qr" });
      }
    }, 1);
  },
});
