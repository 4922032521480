
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { useMeta } from "vue-meta";

import BackgroundUI from "@/components/UI/BackgroundUI.vue";
import HeaderHome from "@/components/HeaderBlock/HeaderHome.vue";

const siteData = {
  title: "Квиз в мемах и приколах: Как оставаться в теме, играя в квизы",
  description: "Как оставаться в теме, играя в квизы",
  keywords:
    "квиз по мемам, сленг зумеров, Бритни Спирс, реклама, эффект Манделы, логика, мемы, рилс, из какого фильма, квиз, играть онлайн",
  href:
    process.env.VUE_APP_CURRENT_ADDRESS + "blog/memi_i_prikoli_partygames_lol",
};

export default defineComponent({
  name: "DescriptionPage",
  setup() {
    useMeta({
      title: siteData.title,
      description: siteData.description,
      keywords: siteData.keywords,
      link: [
        {
          rel: "canonical",
          href: siteData.href,
        },
      ],
      og: {
        title: siteData.title,
        description: siteData.description,
      },
    });
  },
  components: {
    BackgroundUI,
    HeaderHome,
  },
  computed: {
    ...mapState(["mobile_flag"]),
  },
});
