<template>
  <PopupUI class="Popup" @close="$emit('close')">
    <div
      v-if="!successSendReport"
      class="Popup-Wrapper Frame"
      :class="pendingSendReport && 'Popup-Wrapper_pending'"
    >
      <h3 class="Popup-Title">
        Поддержка,<br />
        сообщить об ошибке
      </h3>
      <input
        v-model="email"
        type="email"
        class="Popup-Input"
        placeholder="Ваш email"
        :class="!emailValid && 'Popup-Input_error'"
      />
      <textarea
        v-model="question"
        class="Popup-Input Popup-Input_textarea"
        placeholder="Вопрос / предложение"
        :class="!questionValid && 'Popup-Input_error'"
      />
      <div v-if="file" class="Popup-Selected">
        <img
          src="@/assets/icons/clip.svg"
          alt="файл"
          class="Popup-SelectedImg"
        />
        <span class="Popup-SelectedName">{{ file.name }}</span>
        <button class="Popup-SelectedButton" @click="handleFileDelete">
          <img src="@/assets/icons/closeFile.svg" alt="удалить" />
        </button>
      </div>
      <label v-else class="Popup-File"
        >Прикрепить фаил...
        <input
          class="Popup-FileInput"
          type="file"
          id="file"
          ref="file"
          @change="handleFileUpload"
        />
      </label>
      <ButtonUI
        class="Popup-Button"
        dark
        :text="pendingSendReport ? 'Отправка...' : 'Отправить'"
        :disabled="!emailValid || !questionValid || pendingSendReport"
        @click="sendReport"
      />
    </div>
    <div v-else class="Popup-Wrapper Frame">
      <h3 class="Popup-Title">Сообщение отправлено</h3>
      <ButtonUI
        class="Popup-Button"
        text="Хорошо, назад"
        dark
        @click="$emit('close')"
      />
    </div>
  </PopupUI>
</template>

<script>
import { defineComponent } from "vue";
import { HTTP } from "@/api/api";

import ButtonUI from "@/components/UI/ButtonUI.vue";
import PopupUI from "@/components/UI/PopupUI.vue";

export default defineComponent({
  name: "ReportSupport",
  emits: ["close"],
  components: { PopupUI, ButtonUI },
  data: () => ({
    email: "",
    emailValid: true,
    question: "",
    questionValid: true,
    file: "",
    pendingSendReport: false,
    successSendReport: false,
  }),
  methods: {
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    handleFileDelete() {
      this.file = "";
    },
    async sendReport() {
      let errorCnt = 0;

      if (!this.question) {
        this.questionValid = false;
        errorCnt++;
      }

      if (
        !this.email
          ?.trim()
          .match(/^[\wа-яё.]((-?[\wа-яё.])?)+@[\wа-яё.]+\.[a-zа-яё]{2,}$/i)
      ) {
        this.emailValid = false;
        errorCnt++;
      }

      if (!errorCnt) {
        let attachment = new FormData();

        this.file.name && attachment.append("attachment", this.file);
        this.pendingSendReport = true;

        try {
          await HTTP(
            "POST",
            `support?email=${this.email}&message=${this.question}`,
            this.file.name && attachment
          )
            .then(() => {
              this.pendingSendReport = false;
              this.successSendReport = true;
              this.email = "";
              this.question = "";
              this.file = "";

              setTimeout(() => {
                this.successSendReport = false;
              }, 2000);
            })
            .catch(() => {
              this.pendingSendReport = false;

              this.setPopupData({
                status: "error",
                title: "Ошибка",
                description: "Не удалось отправить запрос.",
              });
            });
        } catch {
          this.setPopupData({
            status: "error",
            title: "Ошибка",
            description: "Не удалось отправить запрос.",
          });
        }
      }
    },
  },
  watch: {
    email() {
      this.emailValid = true;
    },
    question() {
      this.questionValid = true;
    },
  },
});
</script>

<style lang="sass" scoped>
.Popup
  &-Wrapper
    align-items: flex-start
    max-width: 1000px
    padding: 40px
    text-align: left

    &_pending
      pointer-events: none

  &-Title
    margin: 0 0 50px 0

  &-Input
    width: 100%
    margin-bottom: 20px
    padding: 20px
    font-size: 20px
    font-weight: 600
    color: #6F6F6F
    border: 3px solid #5A5A5A
    box-sizing: border-box

    &_textarea
      min-height: 170px
      max-height: 300px
      resize: none

    &_error
      color: $red1
      border-color: $red1
      box-shadow: 0px 0px 4px 0px $red1

  &-Selected
    display: flex
    align-items: center
    justify-content: space-between
    max-width: 300px
    margin-bottom: 40px

  &-SelectedImg
    width: 30px
    height: 30px
    margin-right: 8px

  &-SelectedName
    margin-right: 8px
    font-size: 20px
    color: #6F6F6F

  &-SelectedButton
    width: 36px
    height: 36px
    background: unset
    border: unset

  &-File
    max-width: 200px
    margin-bottom: 40px
    padding: 6px 12px
    font-size: 24px
    background: #E0E0E0
    cursor: pointer

  &-FileInput
    display: none

  &-Button
    margin: 0
</style>
