import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23fa0888"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "InputUI-Tip Tip"
}
const _hoisted_2 = {
  key: 1,
  class: "InputUI-Error"
}
const _hoisted_3 = { class: "InputUI-Inner" }
const _hoisted_4 = ["value", "placeholder", "disabled"]
const _hoisted_5 = ["readonly", "type", "value", "placeholder", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IMaskComponent = _resolveComponent("IMaskComponent")!
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["InputUI", [
      _ctx.disabled && 'InputUI_disabled',
      !_ctx.validation && 'InputUI_error',
      _ctx.styled && 'InputUI_styled',
    ]])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    (!_ctx.validation)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.errorText), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.mask)
        ? (_openBlock(), _createBlock(_component_IMaskComponent, {
            key: 0,
            class: "InputUI-Input",
            autocomplete: "on",
            readonly: _ctx.readonly,
            value: _ctx.value,
            mask: _ctx.mask,
            disabled: _ctx.disabled,
            placeholder: _ctx.placeholder,
            onInput: _ctx.onInput
          }, null, 8, ["readonly", "value", "mask", "disabled", "placeholder", "onInput"]))
        : (_ctx.textarea)
          ? (_openBlock(), _createElementBlock("textarea", {
              key: 1,
              class: "InputUI-Input InputUI-Input_textarea",
              rows: "3",
              value: _ctx.value,
              placeholder: _ctx.placeholder,
              disabled: _ctx.disabled,
              onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
            }, null, 40, _hoisted_4))
          : (_openBlock(), _createElementBlock("input", {
              key: 2,
              class: "InputUI-Input",
              autocomplete: "on",
              readonly: _ctx.readonly,
              type: _ctx.type,
              value: _ctx.value,
              placeholder: _ctx.placeholder,
              disabled: _ctx.disabled,
              onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
            }, null, 40, _hoisted_5)),
      (!_ctx.readonly && !_ctx.disabled && !_ctx.styled)
        ? (_openBlock(), _createElementBlock("div", {
            key: 3,
            class: _normalizeClass(["InputUI-Helpers", _ctx.textarea && 'InputUI-Helpers_textarea'])
          }, [
            (_ctx.value)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "InputUI-Icon Icon Icon_black",
                  onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClear && _ctx.onClear(...args)), ["prevent"]))
                }, [
                  _createVNode(_component_inline_svg, {
                    title: "Очистить поле",
                    class: "Icon-Image",
                    src: require('@/assets/icons/delete.svg')
                  }, null, 8, ["src"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.type === 'text' && _ctx.random)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  class: "InputUI-Icon Icon Icon_black",
                  onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onRandom && _ctx.onRandom(...args)), ["prevent"]))
                }, [
                  _createVNode(_component_inline_svg, {
                    title: "Случайное имя",
                    class: "Icon-Image",
                    src: require('@/assets/icons/random.svg')
                  }, null, 8, ["src"])
                ]))
              : _createCommentVNode("", true)
          ], 2))
        : _createCommentVNode("", true)
    ])
  ], 2))
}