import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-650558b7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ButtonUI-Text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_HintUI = _resolveComponent("HintUI")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.href ? 'a' : _ctx.to ? 'router-link' : 'button'), {
    class: _normalizeClass(["ButtonUI", _ctx.styleClass]),
    href: _ctx.href,
    to: _ctx.to,
    target: _ctx.noblank ? '_self' : '_blank'
  }, {
    default: _withCtx(() => [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_inline_svg, {
            key: 0,
            class: "ButtonUI-Icon",
            src: _ctx.icon
          }, null, 8, ["src"]))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.text), 1),
      (_ctx.isChecked !== null)
        ? (_openBlock(), _createBlock(_component_inline_svg, {
            key: 1,
            class: "ButtonUI-Check",
            src: 
        require(_ctx.isChecked
          ? '@/assets/icons/check.svg'
          : '@/assets/icons/dot.svg')
      
          }, null, 8, ["src"]))
        : _createCommentVNode("", true),
      (_ctx.hint)
        ? (_openBlock(), _createBlock(_component_HintUI, { key: 2 }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.hint), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["href", "to", "target", "class"]))
}