
import { defineComponent } from "vue";
import { mapState, mapMutations, mapActions } from "vuex";

import HeaderHome from "@/components/HeaderBlock/HeaderHome.vue";
import ButtonUI from "@/components/UI/ButtonUI.vue";
import CloudUI from "@/components/UI/CloudUI.vue";
import PopupQR from "@/components/Popups/PopupQR.vue";

export default defineComponent({
  name: "SearchPage",
  components: {
    HeaderHome,
    ButtonUI,
    CloudUI,
    PopupQR,
  },
  data: () => ({
    isFilter: false,
    selectedGames: {} as any,
    currentRoom: {} as any,
  }),
  computed: {
    ...mapState(["popup_data"]),
    ...mapState("room", ["room_data", "rooms", "games", "excludedTags"]),
  },
  methods: {
    ...mapMutations({
      setPopupData: "SET_POPUP_DATA",
    }),
    ...mapActions({
      getGames: "room/getGames",
      initSearchConnection: "room/initSearchConnection",
      filterGames: "room/filterGames",
    }),
    setCurrentRoom(room: any) {
      this.currentRoom = room;
      this.setPopupData({ root_status: "join_fast" });
    },
    setIsfilter(isFilter: boolean) {
      this.isFilter = isFilter;
    },
    onFilter() {
      const tags = this.selectedGames.tags?.map((item: any) => item.name) || [];

      this.filterGames(tags);
      this.setIsfilter(false);
    },
  },
  created() {
    this.getGames();
    this.initSearchConnection();
  },
});
