
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import { checkRoom, checkName } from "@/api/api";

import InputUI from "@/components/UI/InputUI.vue";
import ButtonUI from "@/components/UI/ButtonUI.vue";
import PopupUI from "@/components/UI/PopupUI.vue";
import PasswordUI from "@/components/UI/PasswordUI.vue";

export default defineComponent({
  name: "PopupJoin",
  components: {
    InputUI,
    ButtonUI,
    PopupUI,
    PasswordUI,
  },
  emits: ["close"],
  data: () => ({
    passwordPopup: false,
    roomId: "",
    roomIdValid: true,
    name: "",
    nameValid: true,
    password: "",
  }),
  watch: {
    roomId() {
      this.roomIdValid = true;
    },
    name() {
      this.nameValid = true;
    },
  },
  methods: {
    ...mapActions({
      joinToRoom: "room/joinToRoom",
    }),
    setPasswordPopup(passwordPopup: boolean) {
      this.passwordPopup = passwordPopup;
    },
    onJoin() {
      this.joinToRoom({
        room_id: this.roomId,
        name: this.name,
        password: this.password,
      });

      this.password = "";
      this.setPasswordPopup(false);
    },
    onKeydown(event: any) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.validation(() => this.setPasswordPopup(true));
      }
    },
    async validation(callback: () => any) {
      const sameName = await checkName(this.roomId, this.name);
      const isRoomExist = await checkRoom(this.roomId);

      this.nameValid =
        this.name.length > 2 && this.name.length < 10 && !sameName;
      this.roomIdValid = Boolean(this.roomId) && isRoomExist;

      this.roomIdValid && this.nameValid && callback();
    },
  },
  mounted() {
    window.addEventListener("keydown", this.onKeydown);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.onKeydown);
  },
});
