
import { defineComponent } from "vue";

import { mapState, mapMutations, mapActions } from "vuex";

import PopupUI from "@/components/UI/PopupUI.vue";
import InputUI from "@/components/UI/InputUI.vue";
import ButtonUI from "@/components/UI/ButtonUI.vue";

export default defineComponent({
  name: "PopupEmail",
  components: {
    PopupUI,
    InputUI,
    ButtonUI,
  },
  data: (): any => ({
    email: "",
    emailValid: true,
  }),
  computed: {
    ...mapState("profile", ["profile_data"]),
  },
  watch: {
    email() {
      this.emailValid = true;
    },
  },
  methods: {
    ...mapMutations({
      setPopupData: "SET_POPUP_DATA",
    }),
    ...mapActions({
      updateProfile: "profile/updateProfile",
    }),
    onKeydown(event: any) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.onSubmit();
      }
    },
    async onSubmit() {
      this.emailValid = Boolean(
        this.email
          ?.trim()
          .match(/^[\wа-яё.]((-?[\wа-яё.])?)+@[\wа-яё.]+\.[a-zа-яё]{2,}$/i)
      );

      if (this.emailValid) {
        await this.updateProfile({ email: this.email });
        this.setPopupData({ root_status: "" });
      }
    },
  },
  created() {
    this.email = this.profile_data.email || "";
  },
  mounted() {
    window.addEventListener("keydown", this.onKeydown);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.onKeydown);
  },
});
