
import { defineComponent } from "vue";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

import BackgroundUI from "@/components/UI/BackgroundUI.vue";
import TableTP from "@/components/TotalPage/TableTP.vue";

export default defineComponent({
  name: "TotalPage",
  components: { BackgroundUI, TableTP },
  data: () => ({
    isMain: false,
    isButton: false,
  }),
  computed: {
    ...mapState(["mobile_flag"]),
    ...mapState("room", ["leaderboard", "game_status"]),
    ...mapGetters({
      isDashboard: "room/isDashboard",
    }),
  },
  methods: {
    ...mapMutations({
      setPopupData: "SET_POPUP_DATA",
    }),
    ...mapActions({
      pause: "room/pause",
    }),
    onPause() {
      this.isDashboard
        ? this.game_status
          ? this.pause()
          : this.setPopupData({ status: "reset" })
        : this.setPopupData({ status: "to_lobby" });
    },
  },
  created() {
    !this.leaderboard && this.$router.push("/room");
  },
  mounted() {
    setTimeout(() => (this.isMain = true), 250);
    setTimeout(() => (this.isButton = true), 750);
  },
});
