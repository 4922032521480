
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import { checkName } from "@/api/api";

import InputUI from "@/components/UI/InputUI.vue";
import ButtonUI from "@/components/UI/ButtonUI.vue";
import PopupUI from "@/components/UI/PopupUI.vue";

export default defineComponent({
  name: "PopupQR",
  emits: ["close"],
  components: {
    InputUI,
    ButtonUI,
    PopupUI,
  },
  props: {
    title: {
      type: String,
      default: "Ооо, да тебя тут ждали!",
    },
    roomId: {
      type: String,
      default: "",
    },
    password: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    name: "",
    nameValid: true,
  }),
  watch: {
    name() {
      this.nameValid = true;
    },
  },
  methods: {
    ...mapActions({
      joinToRoom: "room/joinToRoom",
    }),
    onJoin() {
      this.joinToRoom({
        room_id: this.roomId,
        name: this.name,
        password: this.password,
      });
    },
    onKeydown(event: any) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.validation(this.onJoin);
      }
    },
    async validation(callback: () => any) {
      const sameName = await checkName(this.roomId, this.name);

      this.nameValid =
        this.name.length > 2 && this.name.length < 10 && !sameName;
      this.nameValid && callback();
    },
  },
  mounted() {
    window.addEventListener("keydown", this.onKeydown);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.onKeydown);
  },
});
