import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-479b83c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ChatUI" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_InputBlock = _resolveComponent("InputBlock")!
  const _component_TextBlock = _resolveComponent("TextBlock")!
  const _component_EmojisBlock = _resolveComponent("EmojisBlock")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { name: "slide-left" }, {
      default: _withCtx(() => [
        (!_ctx.isChat)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: _normalizeClass(["ChatUI-Button Icon", _ctx.isNotice && 'ChatUI-Button_notice']),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setIsChat(true)))
            }, [
              _createVNode(_component_inline_svg, {
                title: "Чат",
                class: "Icon-Image",
                src: require('@/assets/icons/chat.svg')
              }, null, 8, ["src"])
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "slide-right" }, {
      default: _withCtx(() => [
        (_ctx.isChat)
          ? (_openBlock(), _createBlock(_component_InputBlock, {
              key: 0,
              class: "ChatUI-InputBlock",
              onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setIsChat(false)))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "slide-left" }, {
      default: _withCtx(() => [
        (_ctx.isChat && _ctx.chat_messages?.length)
          ? (_openBlock(), _createBlock(_component_TextBlock, {
              key: 0,
              class: "ChatUI-TextBlock",
              chat_messages: _ctx.chat_messages,
              onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setIsChat(false)))
            }, null, 8, ["chat_messages"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_EmojisBlock, { class: "ChatUI-EmojisBlock" })
  ]))
}