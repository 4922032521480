import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, Transition as _Transition } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_metainfo = _resolveComponent("metainfo")!
  const _component_MusicUI = _resolveComponent("MusicUI")!
  const _component_ChatUI = _resolveComponent("ChatUI")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_PopupLeaderboard = _resolveComponent("PopupLeaderboard")!
  const _component_PopupCreate = _resolveComponent("PopupCreate")!
  const _component_PopupJoin = _resolveComponent("PopupJoin")!
  const _component_PopupAdult = _resolveComponent("PopupAdult")!
  const _component_PopupNeedAuth = _resolveComponent("PopupNeedAuth")!
  const _component_PopupError = _resolveComponent("PopupError")!
  const _component_PopupExit = _resolveComponent("PopupExit")!
  const _component_PopupPause = _resolveComponent("PopupPause")!
  const _component_PopupReset = _resolveComponent("PopupReset")!
  const _component_PopupKick = _resolveComponent("PopupKick")!
  const _component_PopupToLobby = _resolveComponent("PopupToLobby")!
  const _component_PopupLogout = _resolveComponent("PopupLogout")!
  const _component_PopupAuth = _resolveComponent("PopupAuth")!
  const _component_PopupAvatars = _resolveComponent("PopupAvatars")!
  const _component_PopupSubscribe = _resolveComponent("PopupSubscribe")!
  const _component_PopupContacts = _resolveComponent("PopupContacts")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_metainfo, null, {
      title: _withCtx(({ content }) => [
        _createTextVNode(_toDisplayString(content ? content : `SITE_NAME`), 1)
      ]),
      _: 1
    }),
    (['/room', '/preview', '/game', '/total'].includes(_ctx.$route.path))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.$route.path !== '/room')
            ? (_openBlock(), _createBlock(_component_MusicUI, { key: 0 }))
            : _createCommentVNode("", true),
          _createVNode(_component_ChatUI)
        ], 64))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_component_router_view, {
      key: _ctx.$route.fullPath
    })),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.popup_data.root_status === 'leaderboard')
          ? (_openBlock(), _createBlock(_component_PopupLeaderboard, {
              key: 0,
              onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setPopupData({ root_status: '' })))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.popup_data.root_status === 'create')
          ? (_openBlock(), _createBlock(_component_PopupCreate, {
              key: 0,
              onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setPopupData({ root_status: '' })))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.popup_data.root_status === 'join')
          ? (_openBlock(), _createBlock(_component_PopupJoin, {
              key: 0,
              onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setPopupData({ root_status: '' })))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.popup_data.status === '18+')
          ? (_openBlock(), _createBlock(_component_PopupAdult, {
              key: 0,
              onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setPopupData({ status: '' }))),
              onTaged: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onChangeTags($event)))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.popup_data.status === 'need_auth')
          ? (_openBlock(), _createBlock(_component_PopupNeedAuth, {
              key: 0,
              onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setPopupData({ status: '' })))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.popup_data.status === 'error')
          ? (_openBlock(), _createBlock(_component_PopupError, {
              key: 0,
              onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.setPopupData({ status: '' })))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.popup_data.status === 'exit')
          ? (_openBlock(), _createBlock(_component_PopupExit, {
              key: 0,
              onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.setPopupData({ status: '' })))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.popup_data.root_status === 'pause')
          ? (_openBlock(), _createBlock(_component_PopupPause, {
              key: 0,
              onResume: _ctx.resume,
              onReset: _cache[8] || (_cache[8] = ($event: any) => (_ctx.setPopupData({ status: 'reset' }))),
              onKick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.setPopupData({ status: 'kick' }))),
              onTo_lobby: _cache[10] || (_cache[10] = ($event: any) => (_ctx.setPopupData({ status: 'to_lobby' })))
            }, null, 8, ["onResume"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.popup_data.status === 'reset')
          ? (_openBlock(), _createBlock(_component_PopupReset, {
              key: 0,
              onClose: _cache[11] || (_cache[11] = ($event: any) => (_ctx.setPopupData({ status: '' }))),
              onReset: _ctx.reset,
              onDisconnect: _ctx.disconnect
            }, null, 8, ["onReset", "onDisconnect"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.popup_data.status === 'kick')
          ? (_openBlock(), _createBlock(_component_PopupKick, {
              key: 0,
              onClose: _cache[12] || (_cache[12] = ($event: any) => (_ctx.setPopupData({ status: '' }))),
              onKick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.kickPlayer($event)))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.popup_data.status === 'to_lobby')
          ? (_openBlock(), _createBlock(_component_PopupToLobby, {
              key: 0,
              onClose: _cache[14] || (_cache[14] = ($event: any) => (_ctx.setPopupData({ status: '' }))),
              onDisconnect: _ctx.disconnect
            }, null, 8, ["onDisconnect"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.popup_data.status === 'logout')
          ? (_openBlock(), _createBlock(_component_PopupLogout, {
              key: 0,
              onClose: _cache[15] || (_cache[15] = ($event: any) => (_ctx.setPopupData({ status: '' }))),
              onDisconnect: _ctx.disconnect
            }, null, 8, ["onDisconnect"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.popup_data.status === 'auth')
          ? (_openBlock(), _createBlock(_component_PopupAuth, {
              key: 0,
              onClose: _cache[16] || (_cache[16] = ($event: any) => (_ctx.setPopupData({ status: '' })))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "slide-right" }, {
      default: _withCtx(() => [
        (_ctx.popup_data.root_status === 'avatars')
          ? (_openBlock(), _createBlock(_component_PopupAvatars, {
              key: 0,
              onClose: _cache[17] || (_cache[17] = ($event: any) => (_ctx.setPopupData({ root_status: '' })))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "slide-right" }, {
      default: _withCtx(() => [
        (_ctx.popup_data.root_status === 'subscribe')
          ? (_openBlock(), _createBlock(_component_PopupSubscribe, {
              key: 0,
              onClose: _cache[18] || (_cache[18] = ($event: any) => (_ctx.setPopupData({ root_status: '' })))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "slide-right" }, {
      default: _withCtx(() => [
        (_ctx.popup_data.root_status === 'contacts')
          ? (_openBlock(), _createBlock(_component_PopupContacts, {
              key: 0,
              onClose: _cache[19] || (_cache[19] = ($event: any) => (_ctx.setPopupData({ root_status: '' })))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}