import { createStore } from "vuex";

import { PopupData, State } from "@/types/types";

import room from "./modules/room";
import profile from "./modules/profile";

export default createStore<State>({
  state: {
    mobile_flag: false, // Флаг переключения десктоп/мобилка
    music_flag: false, // Флаг переключения фоновой музыки
    popup_data: {
      status: "",
      root_status: "",
      title: "",
      description: "",
    }, // Данные попапа
    music: "", // Фоновая музыка
    voice: "", // Авторская озвучка
  } as State,
  mutations: {
    // Установка флага переключения десктоп/мобилка
    SET_MOBILE_FLAG(state: State, mobile_flag: boolean) {
      state.mobile_flag = mobile_flag;
    },
    // Установка флага переключения фоновой музыки
    SET_MUSIC_FLAG(state: State, music_flag: boolean) {
      state.music_flag = music_flag;
    },
    // Установка данных попапа
    SET_POPUP_DATA(state: State, popup_data: PopupData) {
      state.popup_data = {
        ...state.popup_data,
        ...popup_data,
      };
    },
    // Установка авторской озвучки
    SET_VOICE(state: State, voice: string) {
      state.voice = voice;
    },
    // Установка фоновой музыки
    SET_MUSIC(state: State, music: string) {
      state.music = music;
    },
  },
  modules: {
    room,
    profile,
  },
});
