
import { defineComponent } from "vue";
import { mapState } from "vuex";

import PopupUI from "@/components/UI/PopupUI.vue";
import VideoUI from "@/components/UI/VideoUI.vue";

export default defineComponent({
  name: "PopupRules",
  emits: ["close"],
  components: { PopupUI, VideoUI },
  data: () => ({
    isMain: false,
  }),
  computed: {
    ...mapState(["mobile_flag"]),
    ...mapState("room", ["selected_game"]),
  },
  mounted() {
    setTimeout(() => (this.isMain = true), 500);
  },
});
