
import { defineComponent } from "vue";
import { mapState, mapMutations, mapActions } from "vuex";

import PopupUI from "@/components/UI/PopupUI.vue";
import CloudUI from "@/components/UI/CloudUI.vue";
import ButtonUI from "@/components/UI/ButtonUI.vue";
import PasswordUI from "@/components/UI/PasswordUI.vue";

export default defineComponent({
  name: "PopupCreate",
  components: {
    PopupUI,
    CloudUI,
    ButtonUI,
    PasswordUI,
  },
  emits: ["close"],
  data: () => ({
    selectedGame: {} as any,
    passwordPopup: false,
    password: "",
  }),
  computed: {
    ...mapState("room", ["games", "excludedTags"]),
    ...mapState("profile", ["profile_data"]),
    disabledCondition(): boolean {
      return !this.selectedGame.tags?.length;
    },
  },
  methods: {
    ...mapMutations({
      setPopupData: "SET_POPUP_DATA",
    }),
    ...mapActions({
      getGames: "room/getGames",
      createRoom: "room/createRoom",
    }),
    setPasswordPopup(passwordPopup: boolean) {
      this.passwordPopup = passwordPopup;
    },
    onCreate() {
      this.createRoom({
        password: this.password,
        selected_game: this.selectedGame,
      });

      this.setPasswordPopup(false);
    },
    onKeydown(event: any) {
      if (event.keyCode === 13 && !this.disabledCondition) {
        event.preventDefault();
        this.setPasswordPopup(true);
      }
    },
  },
  created() {
    this.getGames();
  },
  mounted() {
    window.addEventListener("keydown", this.onKeydown);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.onKeydown);
  },
});
