
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { useMeta } from "vue-meta";

import BackgroundUI from "@/components/UI/BackgroundUI.vue";
import HeaderHome from "@/components/HeaderBlock/HeaderHome.vue";

const siteData = {
  title: "Креативные вечеринки с квизами от Partygames.lol: Расследование",
  description:
    "Откройте тайну незабываемых вечеринок с квизами Partygames.lol! Наш детектив выяснил, как простые вопросы превращают вечера в феерии смеха и радости. Узнайте, как стать героем своей вечеринки с Partygames.lol",
  keywords:
    "вечеринка, квиз, игра,  онлайн игра, викторина, юмор, развлечение, игры поиграть, играть онлайн, игры для вечеринок, онлайн игры без скачивания, канал детектив, лучшие детективы, интеллектуальные игры, головоломки играть",
  href:
    process.env.VUE_APP_CURRENT_ADDRESS + "blog/rassledovanie_partygames_lol",
};

export default defineComponent({
  name: "DescriptionPage",
  setup() {
    useMeta({
      title: siteData.title,
      description: siteData.description,
      keywords: siteData.keywords,
      link: [
        {
          rel: "canonical",
          href: siteData.href,
        },
      ],
      og: {
        title: siteData.title,
        description: siteData.description,
      },
    });
  },
  components: {
    BackgroundUI,
    HeaderHome,
  },
  computed: {
    ...mapState(["mobile_flag"]),
  },
});
