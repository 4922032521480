import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "PopupQR-Wrapper" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "PopupQR-Description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputUI = _resolveComponent("InputUI")!
  const _component_ButtonUI = _resolveComponent("ButtonUI")!
  const _component_PopupUI = _resolveComponent("PopupUI")!

  return (_openBlock(), _createBlock(_component_PopupUI, {
    class: "PopupQR",
    noparallax: "",
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h2", {
          class: "PopupQR-Title",
          innerHTML: _ctx.title
        }, null, 8, _hoisted_2),
        _createElementVNode("p", _hoisted_3, "Вход в комнату " + _toDisplayString(_ctx.roomId), 1),
        _createVNode(_component_InputUI, {
          value: _ctx.name,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
          class: "PopupQR-Field",
          label: "Имя (от 3 до 9 символов)",
          placeholder: "Введи имя",
          random: "",
          errorText: "Некорректное, либо уже занятое!",
          validation: _ctx.nameValid
        }, null, 8, ["value", "validation"]),
        _createVNode(_component_ButtonUI, {
          class: "PopupQR-Button",
          text: "Войти",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validation(_ctx.onJoin)))
        })
      ])
    ]),
    _: 1
  }))
}