
import { defineComponent } from "vue";
import { HTTP } from "@/api/api";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";

import { mapGetters, mapMutations } from "vuex";

import PopupUI from "@/components/UI/PopupUI.vue";

export default defineComponent({
  name: "PopupLeaderboard",
  components: {
    PopupUI,
    Swiper,
    SwiperSlide,
  },
  data: () => ({
    leaderboard: {
      all_time: [],
      daily: [],
      monthly: [],
      weekly: [],
    } as any,
    prizes: [] as any,
    filters: [
      { text: "Всё время", value: "all_time" },
      { text: "Сегодня", value: "daily" },
      { text: "Месяц", value: "monthly" },
      { text: "Неделя", value: "weekly" },
    ],
    currentFilter: "all_time",
  }),
  computed: {
    ...mapGetters({
      isLocal: "profile/isLocal",
    }),
  },
  methods: {
    ...mapMutations({
      setPopupData: "SET_POPUP_DATA",
    }),
    shortText(text: string): string {
      return text.length > 60 ? text.substr(0, 60) + "..." : text;
    },
    setFilter(filter: string) {
      this.currentFilter = filter;
    },
  },
  async created() {
    let errorMsg = "";

    try {
      await HTTP("GET", "raffles/leaderboard")
        .then((data: any) => {
          this.leaderboard = data.leaderboard || {};
          this.prizes = data.raffle?.prizes || {};
        })
        .catch(() => {
          errorMsg = "Не удалось получить данные о розыгрышах.";
        });
    } catch (error: any) {
      errorMsg = "Не удалось получить данные о розыгрышах.";
    }

    errorMsg &&
      this.setPopupData({
        status: "error",
        title: "Ошибка",
        description: errorMsg,
      });
  },
});
