import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ab2ddc84"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "HomePage" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderHome = _resolveComponent("HeaderHome")!
  const _component_VideoBlock = _resolveComponent("VideoBlock")!
  const _component_GamesBlock = _resolveComponent("GamesBlock")!
  const _component_InfoBlock = _resolveComponent("InfoBlock")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_PopupQR = _resolveComponent("PopupQR")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderHome, { class: "HomePage-HeaderHome" }),
    _createVNode(_component_VideoBlock, { class: "HomePage-VideoBlock" }),
    _createVNode(_component_GamesBlock, {
      ref: "gamesBlock",
      class: "HomePage-GamesBlock"
    }, null, 512),
    _createVNode(_component_InfoBlock, {
      ref: "infoBlock",
      class: "HomePage-InfoBlock"
    }, null, 512),
    _createVNode(_component_Footer),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.popup_data.root_status === 'qr')
          ? (_openBlock(), _createBlock(_component_PopupQR, {
              key: 0,
              roomId: _ctx.roomId,
              password: _ctx.password,
              onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setPopupData({ root_status: '' })))
            }, null, 8, ["roomId", "password"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}