import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "Popup-Wrapper Frame" }
const _hoisted_2 = { class: "Popup-Title" }
const _hoisted_3 = { class: "Popup-Description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopupUI = _resolveComponent("PopupUI")!

  return (_openBlock(), _createBlock(_component_PopupUI, {
    class: "Popup",
    autoclose: _ctx.popup_data.autoclose < 0 ? 0 : _ctx.popup_data.autoclose || 3000,
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.popup_data.title || "Ошибка"), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.popup_data.description), 1)
      ])
    ]),
    _: 1
  }, 8, ["autoclose"]))
}