
import { defineComponent } from "vue";
import { mapState, mapMutations, mapActions } from "vuex";

import PopupUI from "@/components/UI/PopupUI.vue";
import InputUI from "@/components/UI/InputUI.vue";
import ButtonUI from "@/components/UI/ButtonUI.vue";

export default defineComponent({
  name: "PopupRefund",
  components: {
    PopupUI,
    InputUI,
    ButtonUI,
  },
  data: (): any => ({
    selectedPayment: {},
    reason: "",
  }),
  computed: {
    ...mapState("profile", ["payments"]),
  },
  methods: {
    ...mapMutations({
      setPopupData: "SET_POPUP_DATA",
    }),
    ...mapActions({
      refund: "profile/refund",
    }),
    onKeydown(event: any) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.refund({
          payment_id: this.selectedPayment.id,
          content_type: this.selectedPayment.content_type,
          reason: this.reason,
        });
      }
    },
  },
  mounted() {
    window.addEventListener("keydown", this.onKeydown);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.onKeydown);
  },
});
