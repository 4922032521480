import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackgroundUI = _resolveComponent("BackgroundUI")!
  const _component_TableRP = _resolveComponent("TableRP")!
  const _component_AsideRP = _resolveComponent("AsideRP")!
  const _component_PopupSettings = _resolveComponent("PopupSettings")!
  const _component_PopupRules = _resolveComponent("PopupRules")!
  const _component_PopupNickname = _resolveComponent("PopupNickname")!
  const _component_PopupSetDashboard = _resolveComponent("PopupSetDashboard")!
  const _component_PopupVpn = _resolveComponent("PopupVpn")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["RoomPage", _ctx.mobile_flag && 'GridBg'])
  }, [
    (!_ctx.mobile_flag)
      ? (_openBlock(), _createBlock(_component_BackgroundUI, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_Transition, { name: "slide-bottom" }, {
      default: _withCtx(() => [
        (_ctx.isMain)
          ? (_openBlock(), _createBlock(_component_TableRP, {
              key: 0,
              class: "RoomPage-Main"
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "slide-left" }, {
      default: _withCtx(() => [
        (!_ctx.mobile_flag && _ctx.isAside)
          ? (_openBlock(), _createBlock(_component_AsideRP, {
              key: 0,
              class: "RoomPage-Aside"
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "slide-right" }, {
      default: _withCtx(() => [
        (_ctx.popup_data.root_status === 'settings')
          ? (_openBlock(), _createBlock(_component_PopupSettings, {
              key: 0,
              onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setPopupData({ root_status: '' })))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "slide-right" }, {
      default: _withCtx(() => [
        (_ctx.popup_data.root_status === 'rules')
          ? (_openBlock(), _createBlock(_component_PopupRules, {
              key: 0,
              onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setPopupData({ root_status: '' })))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "slide-right" }, {
      default: _withCtx(() => [
        (_ctx.popup_data.root_status === 'nickname')
          ? (_openBlock(), _createBlock(_component_PopupNickname, {
              key: 0,
              onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setPopupData({ root_status: '' })))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.popup_data.status === 'set_dashboard')
          ? (_openBlock(), _createBlock(_component_PopupSetDashboard, {
              key: 0,
              onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setPopupData({ status: '' }))),
              onSet: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setDashboard($event)))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.isPopupVpn)
      ? (_openBlock(), _createBlock(_component_PopupVpn, {
          key: 1,
          class: "RoomPage-VpnPopup",
          onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setIsPopupVpn(false, true)))
        }))
      : _createCommentVNode("", true)
  ], 2))
}