
import { defineComponent } from "vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

import BackgroundUI from "@/components/UI/BackgroundUI.vue";

export default defineComponent({
  name: "PreviewPage",
  components: {
    BackgroundUI,
  },
  data: () => ({
    isMain: false,
    isButton: false,
  }),
  computed: {
    ...mapState(["mobile_flag"]),
    ...mapState("room", ["round_data", "game_status"]),
    ...mapGetters({
      isDashboard: "room/isDashboard",
    }),
  },
  methods: {
    ...mapMutations({
      setPopupData: "SET_POPUP_DATA",
    }),
    ...mapActions({
      pause: "room/pause",
    }),
    onPause() {
      this.isDashboard
        ? this.pause()
        : this.setPopupData({ status: "to_lobby" });
    },
  },
  created() {
    !this.game_status && this.$router.push("/room");
  },
  mounted() {
    setTimeout(() => (this.isMain = true), 500);
    setTimeout(() => (this.isButton = true), 750);
  },
});
