import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57a9e1db"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "CardRP-Wrapper"
}
const _hoisted_2 = { class: "CardRP-Avatar" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "CardRP-Title" }
const _hoisted_5 = { class: "CardRP-TitleInner" }
const _hoisted_6 = { class: "CardRP-Name" }
const _hoisted_7 = { class: "CardRP-TitleInner" }
const _hoisted_8 = {
  key: 0,
  class: "CardRP-Local Status Status_local"
}
const _hoisted_9 = {
  key: 1,
  class: "CardRP-Status Status"
}
const _hoisted_10 = {
  key: 1,
  class: "CardRP-Wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["CardRP", [
      _ctx.table && 'CardRP_table',
      !_ctx.player && 'CardRP_empty',
      _ctx.isLocal(_ctx.player) && 'CardRP_local',
    ]])
  }, [
    (_ctx.player)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.isActivePlayer && _ctx.isLocal(_ctx.player))
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "CardRP-Icon CardRP-Icon_avatar Icon Icon_black",
                  onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.setPopupData({ root_status: 'avatars' })), ["prevent"]))
                }, [
                  _createVNode(_component_inline_svg, {
                    title: "Сменить аватар",
                    class: "Icon-Image",
                    src: require('@/assets/icons/edit.svg')
                  }, null, 8, ["src"])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("img", {
              alt: "Avatar",
              class: "CardRP-Image",
              src: _ctx.player.avatar || require('@/assets/icons/avatar.svg')
            }, null, 8, _hoisted_3)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.player.name), 1),
              (_ctx.isActivePlayer && _ctx.isLocal(_ctx.player))
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "CardRP-Icon CardRP-Icon_nickname Icon Icon_black",
                    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.setPopupData({ root_status: 'nickname' })), ["prevent"]))
                  }, [
                    _createVNode(_component_inline_svg, {
                      title: "Сменить имя",
                      class: "Icon-Image",
                      src: require('@/assets/icons/edit.svg')
                    }, null, 8, ["src"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_7, [
              (_ctx.isLocal(_ctx.player))
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, "Вы"))
                : _createCommentVNode("", true),
              (_ctx.player.is_ready && !_ctx.isFastGame)
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, "Готов"))
                : _createCommentVNode("", true)
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_10, "?"))
  ], 2))
}