
import { defineComponent } from "vue";

import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

export default defineComponent({
  name: "NavBar",
  computed: {
    ...mapState("profile", ["profile_data"]),
    ...mapGetters({
      expiresTime: "profile/expiresTime",
    }),
  },
  methods: {
    ...mapMutations({
      setPopupData: "SET_POPUP_DATA",
    }),
    ...mapActions({
      auth: "profile/auth",
    }),
  },
});
