
import { defineComponent } from "vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

import ButtonUI from "@/components/UI/ButtonUI.vue";

export default defineComponent({
  name: "AsideRP",
  components: {
    ButtonUI,
  },
  computed: {
    ...mapState("room", [
      "room_data",
      "player_data",
      "selected_game",
      "is_room_private",
    ]),
    ...mapGetters({
      isDashboard: "room/isDashboard",
      playersOnly: "room/playersOnly",
      isReadyAll: "room/isReadyAll",
      isStartDisabled: "room/isStartDisabled",
    }),
  },
  methods: {
    ...mapMutations({
      setPopupData: "SET_POPUP_DATA",
    }),
    ...mapActions({
      playerReady: "room/playerReady",
      startGame: "room/startGame",
      makeRoomPublic: "room/makeRoomPublic",
      makeRoomPrivate: "room/makeRoomPrivate",
    }),
    onCopy() {
      const textArea = document.createElement("textarea");

      textArea.value = this.room_data.join;
      textArea.style.position = "fixed";
      textArea.style.opacity = "0";

      document.body.prepend(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand("copy");
      } catch (error) {
        console.error(error);
      } finally {
        textArea.remove();
      }
    },
  },
});
