import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bde0fd8e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "AsideRP" }
const _hoisted_2 = { class: "AsideRP-Main" }
const _hoisted_3 = { class: "AsideRP-QR" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "AsideRP-Group" }
const _hoisted_6 = { class: "AsideRP-Item" }
const _hoisted_7 = { class: "AsideRP-ItemInner" }
const _hoisted_8 = { class: "AsideRP-ItemTitle" }
const _hoisted_9 = { class: "AsideRP-ItemSubtitle" }
const _hoisted_10 = { class: "AsideRP-ItemSubtitle" }
const _hoisted_11 = { class: "AsideRP-Buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_ButtonUI = _resolveComponent("ButtonUI")!

  return (_openBlock(), _createElementBlock("aside", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("figure", _hoisted_3, [
        (_ctx.room_data.qr_code)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: `data:image/png;base64,${_ctx.room_data.qr_code}`,
              alt: "QR код",
              class: "AsideRP-QRImage"
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true),
        _createElementVNode("span", {
          class: "AsideRP-QRCopy",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCopy && _ctx.onCopy(...args)))
        }, "Копировать ссылку")
      ]),
      _createElementVNode("ul", _hoisted_5, [
        _createElementVNode("li", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.selected_game.name), 1),
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.selected_game.question_count) + " вопросов ", 1),
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.selected_game.response_time) + " секунд на вопрос ", 1)
          ]),
          (_ctx.isDashboard && !_ctx.room_data.system)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "AsideRP-ItemButton",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setPopupData({ root_status: 'settings' })))
              }, [
                _createVNode(_component_inline_svg, {
                  title: "Настройки",
                  src: require('@/assets/icons/settings.svg')
                }, null, 8, ["src"])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      (!_ctx.room_data.system)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_ButtonUI, {
              class: "AsideRP-Button",
              text: "Правила",
              bordered: "",
              anim: "",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setPopupData({ root_status: 'rules' })))
            }),
            (!_ctx.isDashboard)
              ? (_openBlock(), _createBlock(_component_ButtonUI, {
                  key: 0,
                  class: "AsideRP-Button",
                  anim: "",
                  green: "",
                  "opacity-dark": _ctx.player_data.is_ready,
                  text: _ctx.player_data.is_ready ? 'Не готов...' : 'Готов!',
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.playerReady(!_ctx.player_data.is_ready)))
                }, null, 8, ["opacity-dark", "text"]))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true),
      (_ctx.isDashboard)
        ? (_openBlock(), _createBlock(_component_ButtonUI, {
            key: 1,
            class: "AsideRP-Button",
            text: "Начать игру",
            green: "",
            anim: "",
            disabled: _ctx.isStartDisabled,
            onClick: _ctx.startGame
          }, null, 8, ["disabled", "onClick"]))
        : _createCommentVNode("", true)
    ])
  ]))
}