import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_BurgerMenu = _resolveComponent("BurgerMenu")!

  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass(["HeaderHome", _ctx.yellow && 'HeaderHome_yellow'])
  }, [
    (!_ctx.mobile_flag)
      ? (_openBlock(), _createBlock(_component_NavBar, { key: 0 }))
      : (_openBlock(), _createBlock(_component_BurgerMenu, { key: 1 }))
  ], 2))
}