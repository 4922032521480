
import { defineComponent } from "vue";
import { HTTP } from "@/api/api";

import { mapState, mapMutations } from "vuex";

import PopupUI from "@/components/UI/PopupUI.vue";
import InputUI from "@/components/UI/InputUI.vue";
import ButtonUI from "@/components/UI/ButtonUI.vue";

export default defineComponent({
  name: "PopupPromocode",
  components: {
    PopupUI,
    InputUI,
    ButtonUI,
  },
  data: (): any => ({
    promocode: "",
    promocodeText: "",
    promocodeValid: false,
    errorMsg: "",
  }),
  computed: {
    ...mapState("profile", ["profile_data"]),
  },
  watch: {
    promocode(promocode: string) {
      !promocode && (this.errorMsg = "");
    },
  },
  methods: {
    ...mapMutations({
      setPopupData: "SET_POPUP_DATA",
      setProfileData: "profile/SET_PROFILE_DATA",
    }),
    onKeydown(event: any) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.onActivate();
      }
    },
    async checkPromocode() {
      let errorMsg = "";

      try {
        await HTTP(
          "GET",
          `payment/check-promo-code?promo_code=${this.promocode}`
        )
          .then((data: any) => {
            if (data) {
              this.promocodeValid = true;
              this.promocodeText = data.popup_text;
            } else {
              errorMsg = "Не удалось получить данные о промокоде.";
            }
          })
          .catch((error: any) => {
            errorMsg = error.response?.data?.detail;
          });
      } catch (error: any) {
        errorMsg = "Не удалось получить данные о промокоде.";
      }

      if (errorMsg) {
        this.promocodeValid = false;
        this.promocodeText = "";
      }

      this.errorMsg = errorMsg;
    },
    async applyPromocode() {
      await this.checkPromocode();

      if (this.promocodeValid) {
        HTTP(
          "GET",
          `payment/activate-promo-code?promo_code=${this.promocode}`
        ).then((data: any) => {
          if (data) {
            this.setProfileData(data);
          }

          this.setPopupData({
            status: "error",
            title: "Ура!",
            description: "Промокод успешно активирован!",
          });
          this.setPopupData({
            root_status: this.profile_data.is_signed ? "" : "oferta",
          });

          this.promocodeValid = false;
          this.promocodeText = "";
          this.promocode = "";
          this.errorMsg = "";
        });
      }
    },
    onActivate() {
      if (!this.promocodeValid) {
        this.checkPromocode();
      } else {
        this.applyPromocode();
      }
    },
  },
  mounted() {
    window.addEventListener("keydown", this.onKeydown);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.onKeydown);
  },
});
