
import { defineComponent } from "vue";
import { mapState, mapMutations, mapGetters } from "vuex";
import { HTTP } from "@/api/api";

import LeftGroupImg from "@/components/HomePage/LeftGroupImg.vue";
import RightGroupImg from "@/components/HomePage/RightGroupImg.vue";
import ButtonUI from "@/components/UI/ButtonUI.vue";
import PopupQR from "@/components/Popups/PopupQR.vue";

export default defineComponent({
  name: "VideoBlock",
  components: {
    LeftGroupImg,
    RightGroupImg,
    ButtonUI,
    PopupQR,
  },
  data: () => ({
    keywords: [
      "игры онлайн без регистрации",
      "русские онлайн игры",
      "игры онлайн",
      "онлайн игры без скачивания",
      "онлайн игры без регистрации +и скачивания",
      "игры онлайн +на телефоне без скачивания",
      "играть вместе",
      "онлайн игры +с друзьями",
      "крутые игры онлайн",
      "многопользовательские онлайн игры",
    ],
    currentRoom: {} as any,
  }),
  computed: {
    ...mapState(["popup_data"]),
    ...mapState("room", ["room_data"]),
    ...mapState("profile", ["profile_data"]),
    ...mapGetters({
      checkInRoom: "room/checkInRoom",
    }),
  },
  methods: {
    ...mapMutations({
      setPopupData: "SET_POPUP_DATA",
    }),
    async createFastRoom() {
      let errorMsg = "";

      if (!this.checkInRoom) {
        try {
          await HTTP("GET", `room/system`)
            .then((data: any) => {
              data?.room_id
                ? this.setCurrentRoom(data)
                : (errorMsg = "Не удалось создать комнату.");
            })
            .catch(() => {
              errorMsg = "Не удалось создать комнату.";
            });
        } catch (error: any) {
          errorMsg = "Не удалось создать комнату.";
        }
      } else {
        errorMsg = "Не удалось создать комнату.";
      }

      errorMsg &&
        this.setPopupData({
          status: "error",
          title: "Ошибка",
          description: errorMsg,
        });
    },
    setCurrentRoom(room: any) {
      this.currentRoom = room;
      this.setPopupData({ root_status: "join_fast" });
    },
  },
});
