
import { defineComponent } from "vue";

import HintUI from "@/components/UI/HintUI.vue";

export default defineComponent({
  name: "ButtonUI",
  components: {
    HintUI,
  },
  props: {
    text: {
      type: String,
      default: "Нажать",
    },
    hint: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    anim: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    gray: {
      type: Boolean,
      default: false,
    },
    green: {
      type: Boolean,
      default: false,
    },
    greenLight: {
      type: Boolean,
      default: false,
    },
    opacity: {
      type: Boolean,
      default: false,
    },
    opacityDark: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    pink: {
      type: Boolean,
      default: false,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    styled: {
      type: Boolean,
      default: false,
    },
    pulse: {
      type: Boolean,
      default: false,
    },
    isChecked: {
      type: Boolean,
      default: null,
    },
    href: {
      type: String,
      default: "",
    },
    to: {
      type: String,
      default: "",
    },
    noblank: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    styleClass(): string {
      let result = "";

      this.dark && (result += "ButtonUI_dark ");
      this.gray && (result += "ButtonUI_gray ");
      this.green && (result += "ButtonUI_green ");
      this.greenLight && (result += "ButtonUI_greenLight ");
      this.opacity && (result += "ButtonUI_opacity ");
      this.opacityDark && (result += "ButtonUI_opacityDark ");
      this.light && (result += "ButtonUI_light ");
      this.pink && (result += "ButtonUI_pink ");
      this.bordered && (result += "ButtonUI_bordered ");
      this.disabled && (result += "ButtonUI_disabled ");
      this.styled && (result += "ButtonUI_styled ");
      this.pulse && (result += "ButtonUI_pulse ");

      this.anim && (result += " ButtonUI_anim");

      return result;
    },
  },
});
