
import { defineComponent } from "vue";
import "swiper/css";

export default defineComponent({
  name: "GameSlide",
  props: {
    slide: {
      type: Object as any,
      default: () => ({}),
    },
    setCurrentItem: {
      type: Function,
      default: () => ({}),
    },
    disabledCondition: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    itemPrice(item: any): string {
      return item.is_free
        ? "БЕС-ПЛАТ-НО"
        : item.price
        ? item.price + "₽ в месяц"
        : "";
    },
  },
});
