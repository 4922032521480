
import { defineComponent } from "vue";

import Parallax from "parallax-js";

export default defineComponent({
  name: "BackgroundUI",
  mounted() {
    new Parallax(this.$refs.scene);
  },
});
