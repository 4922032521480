
import { defineComponent } from "vue";

import { mapState } from "vuex";

import PopupUI from "@/components/UI/PopupUI.vue";
import ButtonUI from "@/components/UI/ButtonUI.vue";

export default defineComponent({
  name: "PopupAuth",
  emits: ["close"],
  components: {
    PopupUI,
    ButtonUI,
  },
  computed: {
    ...mapState(["mobile_flag"]),
    ...mapState("profile", ["bot_auth"]),
  },
  methods: {
    onKeydown(event: any) {
      if (event.keyCode === 13) {
        event.preventDefault();
        window.open(this.bot_auth.link, "_blank");
      }
    },
  },
  mounted() {
    window.addEventListener("keydown", this.onKeydown);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.onKeydown);
  },
});
