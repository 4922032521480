
import { defineComponent } from "vue";
import { mapGetters, mapMutations } from "vuex";

export default defineComponent({
  name: "CardRP",
  props: {
    player: {
      type: [Object, null] as any,
      default: null,
    },
    isFastGame: {
      type: Boolean,
      default: false,
    },
    table: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      isLocal: "room/isLocal",
      isActivePlayer: "room/isActivePlayer",
    }),
  },
  methods: {
    ...mapMutations({
      setPopupData: "SET_POPUP_DATA",
    }),
  },
});
