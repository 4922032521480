
import { defineComponent } from "vue";
import { mapMutations } from "vuex";

import ReportSupport from "@/components/HomePage/ReportSupport.vue";

export default defineComponent({
  name: "FooterHome",
  components: { ReportSupport },
  data(): any {
    return {
      rsPopup: false,
    };
  },
  methods: {
    ...mapMutations({
      setPopupData: "SET_POPUP_DATA",
    }),
    setOpenPopUp(openPopUp: boolean) {
      this.rsPopup = openPopUp;
    },
  },
});
