import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79b9460c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "SearchPage" }
const _hoisted_2 = { class: "SearchPage-Main" }
const _hoisted_3 = { class: "SearchPage-Title" }
const _hoisted_4 = {
  key: 0,
  class: "SearchPage-Filter Frame"
}
const _hoisted_5 = {
  key: 1,
  class: "SearchPage-Rooms"
}
const _hoisted_6 = { class: "SearchPage-RoomText" }
const _hoisted_7 = { class: "SearchPage-RoomTags" }
const _hoisted_8 = { class: "SearchPage-RoomCount" }
const _hoisted_9 = {
  key: 2,
  class: "SearchPage-Empty"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderHome = _resolveComponent("HeaderHome")!
  const _component_ButtonUI = _resolveComponent("ButtonUI")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_CloudUI = _resolveComponent("CloudUI")!
  const _component_PopupQR = _resolveComponent("PopupQR")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderHome, { class: "SearchPage-HeaderHome" }),
    _createElementVNode("main", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, [
        _createTextVNode(" Публичные игры "),
        _createVNode(_component_ButtonUI, {
          text: "Фильтр",
          dark: "",
          class: "SearchPage-TitleFilter",
          disabled: _ctx.isFilter,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setIsfilter(true)))
        }, null, 8, ["disabled"])
      ]),
      (_ctx.isFilter)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("button", {
              class: "SearchPage-FilterClose Icon Icon_black",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setIsfilter(false)))
            }, [
              _createVNode(_component_inline_svg, {
                title: "Закрыть окно",
                class: "Icon-Image",
                src: require('@/assets/icons/cross.svg')
              }, null, 8, ["src"])
            ]),
            _createVNode(_component_CloudUI, {
              value: _ctx.selectedGames,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedGames) = $event)),
              class: "SearchPage-Cloud",
              controls: "",
              max: 9999,
              list: _ctx.games,
              excluded: _ctx.excludedTags,
              onWarning: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setPopupData({ status: '18+' })))
            }, null, 8, ["value", "list", "excluded"]),
            _createVNode(_component_ButtonUI, {
              text: "Отфильтровать",
              dark: "",
              class: "SearchPage-FilterButton",
              onClick: _ctx.onFilter
            }, null, 8, ["onClick"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.rooms.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rooms, (room) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "SearchPage-Room",
                key: room.id
              }, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("h2", _hoisted_7, _toDisplayString(room.tags.join(", ")), 1),
                  _createElementVNode("div", _hoisted_8, _toDisplayString(room.count_players) + "/" + _toDisplayString(room.max_players) + " игроков ", 1)
                ]),
                _createVNode(_component_ButtonUI, {
                  text: "Войти",
                  green: "",
                  class: "SearchPage-RoomButton",
                  onClick: ($event: any) => (_ctx.setCurrentRoom(room))
                }, null, 8, ["onClick"])
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_9, "Пусто..."))
    ]),
    (_ctx.popup_data.root_status === 'join_fast')
      ? (_openBlock(), _createBlock(_component_PopupQR, {
          key: 0,
          title: "Присоединиться",
          roomId: String(_ctx.currentRoom.room_id),
          password: String(_ctx.currentRoom.password),
          onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setPopupData({ root_status: '' })))
        }, null, 8, ["roomId", "password"]))
      : _createCommentVNode("", true)
  ]))
}