import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-33e85fca"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "container",
  class: "TextBlock Frame"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "TextBlock-Item",
        key: index
      }, [
        _createElementVNode("span", {
          class: "TextBlock-ItemPlayer",
          style: _normalizeStyle({ color: _ctx.randomColor(item.player) })
        }, _toDisplayString(item.player) + ": ", 5),
        _createTextVNode(_toDisplayString(item.message), 1)
      ]))
    }), 128))
  ], 512))
}