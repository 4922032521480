
import { defineComponent } from "vue";
import { mapState, mapMutations } from "vuex";

import ButtonUI from "@/components/UI/ButtonUI.vue";

export default defineComponent({
  name: "MusicUI",
  components: {
    ButtonUI,
  },
  data: () => ({
    isAudio: false,
  }),
  computed: {
    ...mapState(["music_flag", "music"]),
  },
  watch: {
    music_flag() {
      this.isAudio && this.onToggleMusic();
    },
  },
  methods: {
    ...mapMutations({
      setMusicFlag: "SET_MUSIC_FLAG",
    }),
    checkActivity() {
      window.removeEventListener("click", this.checkActivity);

      this.isAudio = true;

      this.onToggleMusic();
    },
    onToggleMusic() {
      setTimeout(() => {
        const audio = this.$refs.music as HTMLAudioElement;

        if (audio) {
          this.music_flag ? audio.play() : audio.pause();
        }
      }, 100);
    },
  },
  mounted() {
    window.addEventListener("click", this.checkActivity);
  },
  beforeUnmount() {
    window.removeEventListener("click", this.checkActivity);
  },
});
