import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2682d334"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "PopupUI-Main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackgroundUI = _resolveComponent("BackgroundUI")!
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["PopupUI", _ctx.mobile_flag && 'PopupUI_mobile'])
  }, [
    (!_ctx.noparallax && !_ctx.mobile_flag)
      ? (_openBlock(), _createBlock(_component_BackgroundUI, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isClose)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "PopupUI-Close Icon Icon_black",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
          }, [
            _createVNode(_component_inline_svg, {
              title: "Закрыть окно",
              class: "Icon-Image",
              src: require('@/assets/icons/cross.svg')
            }, null, 8, ["src"])
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 2))
}