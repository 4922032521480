
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { useMeta } from "vue-meta";

import BackgroundUI from "@/components/UI/BackgroundUI.vue";
import HeaderHome from "@/components/HeaderBlock/HeaderHome.vue";

const siteData = {
  title: "О проекте онлайн игр Partygames.lol",
  description:
    "Partygames.lol - это веселые онлайн-викторины для друзей по всему миру. Повод устроить вечеринку, собраться в зуме на тимбилдинг или запустить свой стрим. Никаких душных вопросов, только юмор, смекалка и ностальгия.",
  keywords:
    "платформа для игр онлайн, командные игры, коллаборации, квиз викторина, вопросы с вариантами ответов, сплочение, тимбилдинг, квиз на русском, играть с другом по сети, стрим, тест квиз, русские онлайн игры, играть бесплатно",
  href: process.env.VUE_APP_CURRENT_ADDRESS + "description",
};

export default defineComponent({
  name: "DescriptionPage",
  setup() {
    useMeta({
      title: siteData.title,
      description: siteData.description,
      keywords: siteData.keywords,
      link: [
        {
          rel: "canonical",
          href: siteData.href,
        },
      ],
      og: {
        title: siteData.title,
        description: siteData.description,
      },
    });
  },
  components: {
    BackgroundUI,
    HeaderHome,
  },
  computed: {
    ...mapState(["mobile_flag"]),
  },
});
