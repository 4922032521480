
import { defineComponent } from "vue";
import { mapState } from "vuex";

import PopupUI from "@/components/UI/PopupUI.vue";
import ButtonUI from "@/components/UI/ButtonUI.vue";

export default defineComponent({
  name: "PopupToLobby",
  emits: ["close", "disconnect"],
  components: {
    PopupUI,
    ButtonUI,
  },
  computed: {
    ...mapState("room", ["room_data"]),
  },
  methods: {
    onExit() {
      this.$emit("disconnect");
      this.$emit("close");
    },
    onKeydown(event: any) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.onExit();
      }
    },
  },
  mounted() {
    window.addEventListener("keydown", this.onKeydown);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.onKeydown);
  },
});
