
import { defineComponent } from "vue";
import { mapState, mapActions } from "vuex";
import ButtonUI from "@/components/UI/ButtonUI.vue";

export default defineComponent({
  name: "ReviewTP",
  computed: {
    ...mapState("room", ["player_data"]),
  },
  methods: {
    ...mapActions({
      addRating: "room/addRating",
    }),
    setGrade(rate: number) {
      this.addRating(rate);
    },
  },
  components: { ButtonUI },
});
