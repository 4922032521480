
import { defineComponent } from "vue";
import { HTTP } from "@/api/api";

import { mapState, mapMutations, mapActions } from "vuex";

import PopupUI from "@/components/UI/PopupUI.vue";
import InputUI from "@/components/UI/InputUI.vue";
import ButtonUI from "@/components/UI/ButtonUI.vue";

export default defineComponent({
  name: "PopupOferta",
  components: {
    PopupUI,
    InputUI,
    ButtonUI,
  },
  data: (): any => ({
    email: "",
    emailValid: true,
    profileFlag: false,
    profileFlagValid: true,
    ofertaFlag: false,
    ofertaFlagValid: true,
    promocodeText: "",
  }),
  computed: {
    ...mapState("profile", ["profile_data"]),
  },
  watch: {
    email() {
      this.emailValid = true;
    },
    profileFlag() {
      this.profileFlagValid = true;
    },
    ofertaFlag() {
      this.ofertaFlagValid = true;
    },
  },
  methods: {
    ...mapMutations({
      setPopupData: "SET_POPUP_DATA",
    }),
    ...mapActions({
      subscribe: "profile/subscribe",
    }),
    onKeydown(event: any) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.onSubmit();
      }
    },
    async onSubmit() {
      this.emailValid = Boolean(
        this.email
          ?.trim()
          .match(/^[\wа-яё.]((-?[\wа-яё.])?)+@[\wа-яё.]+\.[a-zа-яё]{2,}$/i)
      );
      this.profileFlagValid = this.profileFlag;
      this.ofertaFlagValid = this.ofertaFlag;

      if (this.emailValid && this.profileFlagValid && this.ofertaFlagValid) {
        await this.subscribe({
          email: this.email,
        });
        this.setPopupData({ root_status: "" });
      }
    },
  },
  async created() {
    this.email = this.profile_data.email;

    try {
      await HTTP("GET", `payment/active-user-promo`)
        .then((data: any) => {
          if (data) {
            this.promocodeText = data.popup_text;
          } else {
            console.log(data);
          }
        })
        .catch((error: any) => {
          console.log(error);
        });
    } catch (error: any) {
      console.log(error);
    }
  },
  mounted() {
    window.addEventListener("keydown", this.onKeydown);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.onKeydown);
  },
});
