
import { HTTP } from "@/api/api";

import { defineComponent } from "vue";
import { mapState, mapActions, mapMutations } from "vuex";

import PopupUI from "@/components/UI/PopupUI.vue";
import ButtonUI from "@/components/UI/ButtonUI.vue";

export default defineComponent({
  name: "PopupAvatars",
  emits: ["close"],
  components: {
    PopupUI,
    ButtonUI,
  },
  data: () => ({
    isMain: false,
    isButton: false,
    list: [] as any,
    currentAvatar: "",
  }),
  computed: {
    ...mapState("room", ["player_data"]),
  },
  methods: {
    ...mapActions({
      playerSettings: "room/playerSettings",
    }),
    ...mapMutations({
      setPopupData: "SET_POPUP_DATA",
    }),
    setAvatar() {
      this.playerSettings({ avatar: this.currentAvatar });
      this.$emit("close");
    },
    onKeydown(event: any) {
      if (event.keyCode === 13 && this.currentAvatar) {
        event.preventDefault();
        this.setAvatar();
      }
    },
  },
  async created() {
    let errorMsg = "";

    try {
      await HTTP("GET", "avatars")
        .then((data: any) => {
          if (data?.message) {
            this.list = data.message;
          } else {
            errorMsg = "Не удалось получить список аватарок.";
          }
        })
        .catch(() => {
          errorMsg = "Не удалось получить список аватарок.";
        });
    } catch (error: any) {
      errorMsg = "Не удалось получить список аватарок.";
    }

    errorMsg &&
      this.setPopupData({
        status: "error",
        title: "Ошибка",
        description: errorMsg,
      });
  },
  mounted() {
    setTimeout(() => (this.isMain = true), 250);
    setTimeout(() => (this.isButton = true), 500);

    window.addEventListener("keydown", this.onKeydown);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.onKeydown);
  },
});
