
import { defineComponent } from "vue";
import { mapState, mapActions } from "vuex";
import { checkName } from "@/api/api";

import PopupUI from "@/components/UI/PopupUI.vue";
import ButtonUI from "@/components/UI/ButtonUI.vue";
import InputUI from "@/components/UI/InputUI.vue";

export default defineComponent({
  name: "PopupNickname",
  components: {
    PopupUI,
    ButtonUI,
    InputUI,
  },
  data: () => ({
    name: "",
    nameValid: true,
  }),
  watch: {
    name() {
      this.nameValid = true;
    },
  },
  computed: {
    ...mapState("room", ["room_data", "player_data"]),
  },
  methods: {
    ...mapActions({
      playerSettings: "room/playerSettings",
    }),
    async rename() {
      const sameName = await checkName(
        this.room_data.room_id,
        this.name,
        this.player_data.client_id || null
      );

      if (this.name.length < 3 || this.name.length > 9 || sameName) {
        this.nameValid = false;
      } else {
        this.playerSettings({ name: this.name });
        this.$emit("close");
      }
    },
    onKeydown(event: any) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.rename();
      }
    },
  },
  mounted() {
    window.addEventListener("keydown", this.onKeydown);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.onKeydown);
  },
});
