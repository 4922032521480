import axios from "axios";
import { useCookies } from "vue3-cookies";
import { ProfileAuth } from "@/types/types";

const { cookies } = useCookies();

const instance = axios.create({
  baseURL: process.env.VUE_APP_CURRENT_ENV,
});

const HTTP = async (method: string, url: string, data = {}) => {
  const token = (cookies.get("profile_auth") as unknown as ProfileAuth)
    ?.access_token;
  const response = await instance({
    method,
    url,
    data,
    headers: {
      Authorization: token && `Bearer ${token}`,
    },
  });

  return response.data;
};

const checkRoom = async (room_id: string) => {
  let result = false;

  try {
    await HTTP("GET", `room/exists?room_id=${room_id}`).then(
      (data: any) => (result = data?.exists)
    );
  } catch (error: any) {
    return false;
  }

  console.log(`is room ${room_id} exists? - ` + result);

  return result;
};

const checkName = async (
  room_id: string,
  name: string,
  client_id: number | null = null
) => {
  let result = false;

  try {
    await HTTP(
      "GET",
      `room/name/exists?room_id=${room_id}&name=${name}${
        client_id ? "&client_id=" + client_id : ""
      }`
    ).then((data: any) => (result = data?.exists));
  } catch (error: any) {
    return false;
  }

  return result;
};

export { HTTP, checkRoom, checkName };
