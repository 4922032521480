
import { defineComponent } from "vue";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import PopupUI from "@/components/UI/PopupUI.vue";
import InputUI from "@/components/UI/InputUI.vue";
import CalcUI from "@/components/UI/CalcUI.vue";
import SwitchUI from "@/components/UI/SwitchUI.vue";
import CheckboxUI from "@/components/UI/CheckboxUI.vue";
import ButtonUI from "@/components/UI/ButtonUI.vue";

export default defineComponent({
  name: "PopupSettings",
  emits: ["close"],
  components: { PopupUI, InputUI, CalcUI, SwitchUI, CheckboxUI, ButtonUI },
  data: () => ({
    isMain: false,
    localPassword: "",
    localQuestionCount: 0,
  }),
  watch: {
    localPassword(password: string) {
      if (this.passwordValid) {
        this.changeRoomSettings({ field: "password", value: password });
      }
    },
    localQuestionCount(question_count: number) {
      if (this.questionCountValid) {
        this.changeGameSettings({
          field: "question_count",
          value: question_count,
        });
      }
    },
  },
  computed: {
    ...mapState("room", [
      "player_data",
      "room_data",
      "selected_game",
      "game_options",
      "players",
      "games",
      "excludedTags",
    ]),
    ...mapState("profile", ["profile_data"]),
    ...mapGetters({
      dashboardsOnly: "room/dashboardsOnly",
      playersOnly: "room/playersOnly",
      isDashboard: "room/isDashboard",
    }),
    room_id: {
      get(): string {
        return this.room_data.room_id;
      },
      set(room_id: string) {
        this.changeRoomSettings({ field: "room_id", value: room_id });
      },
    },
    passwordValid(): boolean {
      return (
        this.localPassword?.length === 5 &&
        /^\d+\.?\d*$/.test(this.localPassword)
      );
    },
    max_players: {
      get(): number {
        return this.room_data.max_players;
      },
      set(max_players: number) {
        if (max_players - this.playersOnly.length < this.max_random_players) {
          this.max_random_players = max_players - this.playersOnly.length;
        }

        this.changeRoomSettings({ field: "max_players", value: max_players });
      },
    },
    max_random_players: {
      get(): number {
        return this.room_data.max_random_players;
      },
      set(max_random_players: number) {
        this.changeMaxRandomPlayers(max_random_players);
      },
    },
    questionCountValid(): boolean {
      return (
        this.localQuestionCount > 0 &&
        this.localQuestionCount <= (this.game_options.question_max || 50)
      );
    },
    response_time: {
      get(): number {
        return this.selected_game.response_time;
      },
      set(response_time: number) {
        this.changeGameSettings({
          field: "response_time",
          value: response_time,
        });
      },
    },
    is_boost: {
      get(): boolean | null {
        const is_boost = this.selected_game.is_boost;

        return typeof is_boost === "boolean" ? is_boost : null;
      },
      set(is_boost: boolean) {
        this.changeGameSettings({
          field: "is_boost",
          value: is_boost,
        });
      },
    },
    use_hints: {
      get(): boolean | null {
        const use_hints = this.selected_game.use_hints;

        return typeof use_hints === "boolean" ? use_hints : null;
      },
      set(use_hints: boolean) {
        this.changeGameSettings({
          field: "use_hints",
          value: use_hints,
        });
      },
    },
    active_player: {
      get(): boolean | null {
        const active_player = this.player_data.active_player;

        return typeof active_player === "boolean" ? active_player : null;
      },
      set(active_player: boolean) {
        this.playerSettings({ active_player });
      },
    },
    is_open: {
      get(): boolean | null {
        const is_open = this.room_data.is_open;

        return typeof is_open === "boolean" ? is_open : null;
      },
      set(is_open: boolean) {
        this.makeRoomPrivate(is_open);
      },
    },
    tags: {
      get(): any[] {
        return this.selected_game.tags;
      },
      set(tags: any[]) {
        this.changeGameSettings({
          field: "tags",
          value: tags,
        });
      },
    },
  },
  methods: {
    ...mapActions({
      createConnection: "room/createConnection",
      playerSettings: "room/playerSettings",
      changeRoomSettings: "room/changeRoomSettings",
      changeGameSettings: "room/changeGameSettings",
      makeRoomPrivate: "room/makeRoomPrivate",
      changeMaxRandomPlayers: "room/changeMaxRandomPlayers",
    }),
    ...mapMutations({
      setPopupData: "SET_POPUP_DATA",
    }),
  },
  created() {
    this.localPassword = this.room_data.password;
    this.localQuestionCount = this.selected_game.question_count;
  },
  mounted() {
    setTimeout(() => (this.isMain = true), 500);
  },
});
