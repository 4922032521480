import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d3dfe78"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "PopupAvatars-Main Frame"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonUI = _resolveComponent("ButtonUI")!
  const _component_PopupUI = _resolveComponent("PopupUI")!

  return (_openBlock(), _createBlock(_component_PopupUI, {
    class: "PopupAvatars",
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createVNode(_Transition, { name: "slide-top" }, {
        default: _withCtx(() => [
          (_ctx.isMain)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (avatar, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(["PopupAvatars-Item", [
            _ctx.currentAvatar === avatar && 'PopupAvatars-Item_active',
            _ctx.player_data.avatar === avatar && 'PopupAvatars-Item_disable',
          ]]),
                    key: index,
                    onClick: ($event: any) => (_ctx.currentAvatar = avatar)
                  }, [
                    _createElementVNode("img", {
                      alt: "Avatar",
                      class: "PopupAvatars-ItemImage",
                      src: avatar
                    }, null, 8, _hoisted_3)
                  ], 10, _hoisted_2))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (_ctx.isButton)
            ? (_openBlock(), _createBlock(_component_ButtonUI, {
                key: 0,
                class: "PopupAvatars-Button",
                text: "Выбрать",
                dark: "",
                disabled: !_ctx.currentAvatar,
                onClick: _ctx.setAvatar
              }, null, 8, ["disabled", "onClick"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}