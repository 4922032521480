
import { defineComponent } from "vue";

export default defineComponent({
  name: "AnswerSelectGP",
  emits: ["update:value", "select"],
  props: {
    list: {
      type: Array as any,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    correctAnswer: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isApply: {
      type: Boolean,
      defalt: false,
    },
    isDone: {
      type: Boolean,
      defalt: false,
    },
  },
  computed: {
    isAbled(): boolean {
      return !this.isDone && !this.isApply;
    },
    isSuccess(): boolean {
      return this.value.id === this.correctAnswer?.id;
    },
    isError(): boolean {
      return this.isDone && (this.disabled || !this.isSuccess);
    },
    tipText(): string {
      return !this.isDone
        ? !this.isApply
          ? "Выбери ответ"
          : "Ответ выбран"
        : !this.isSuccess
        ? "Неправильно, твой ответ"
        : "Ответ правильный";
    },
    resultClass(): string {
      return this.isDone
        ? this.isSuccess
          ? "AnswerSelectGP-Main_success"
          : "AnswerSelectGP-Main_error"
        : this.isApply
        ? "AnswerSelectGP-Main_applied"
        : "";
    },
  },
  methods: {
    onSelect(item: any) {
      this.$emit("update:value", item);
      this.$emit("select");
    },
  },
});
