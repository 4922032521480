
import { defineComponent } from "vue";
import { mapState, mapActions } from "vuex";

export default defineComponent({
  name: "InputBlock",
  emits: ["close"],
  data: () => ({
    message: "",
    messageValid: true,
    reaction: "",
    emojis: [
      { icon: "&#128514;", value: ":face_with_tears_of_joy:" },
      { icon: "&#128169;", value: ":pile_of_poo:" },
      { icon: "&#128525;", value: ":smiling_face_with_heart-eyes:" },
      { icon: "&#128556;", value: ":grimacing_face:" },
      { icon: "&#128539;", value: ":face_with_tongue:" },
    ],
  }),
  computed: {
    ...mapState(["mobile_flag"]),
    ...mapState("profile", ["profile_data"]),
    messageValidText(): string {
      return this.message
        ? "Длина сообщения превышает 40 символов."
        : "Пустое поле текста.";
    },
  },
  methods: {
    ...mapActions({
      sendChatMessage: "room/sendChatMessage",
      sendChatReaction: "room/sendChatReaction",
    }),
    onKeydown(event: any) {
      if (event.keyCode === 13) {
        this.onSendMessage();
      }
    },
    setMessage(event: Event) {
      const input = event.target as HTMLInputElement;

      this.messageValid = input.value?.length <= 40;
      this.messageValid
        ? (this.message = input.value)
        : (input.value = this.message);
    },
    setReaction(reaction: string) {
      this.reaction = reaction;
      this.sendChatReaction(reaction);
    },
    onClose() {
      this.$emit("close");
    },
    onSendMessage() {
      this.messageValid = Boolean(this.message);
      if (this.messageValid) {
        this.sendChatMessage(this.message);
        this.message = "";
      }
    },
  },
  mounted() {
    window.addEventListener("keydown", this.onKeydown);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.onKeydown);
  },
});
