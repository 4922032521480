
import { defineComponent } from "vue";

import { directive as VueInputAutowidth } from "vue-input-autowidth";

export default defineComponent({
  name: "CalcUI",
  emits: ["update:value"],
  directives: { autowidth: VueInputAutowidth },
  props: {
    value: {
      type: Number,
      required: true,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100000,
    },
    row: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Boolean,
      default: true,
    },
    unit: {
      type: String,
      default: "",
    },
    isButtons: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
  },
  methods: {
    onChange(event: Event) {
      const input = event.target as HTMLInputElement;
      let n = Number(input.value);

      n < this.min ? (n = this.min) : n > this.max && (n = this.max);

      this.$emit("update:value", n);

      input.value = String(n);
    },
    onIncrement(flag: boolean) {
      let n = Number(this.value);

      flag ? n++ : n--;

      n < this.min ? (n = this.min) : n > this.max && (n = this.max);

      this.$emit("update:value", n);
    },
  },
});
