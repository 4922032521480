
import { defineComponent } from "vue";

export default defineComponent({
  name: "AnswerImagesGP",
  emits: ["update:value"],
  props: {
    list: {
      type: Array as any,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    correctAnswer: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isApply: {
      type: Boolean,
      default: false,
    },
    isDone: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isAbled(): boolean {
      return !this.disabled && !this.isDone && !this.isApply;
    },
  },
  methods: {
    onSelect(item: any) {
      this.isAbled && this.$emit("update:value", item);
    },
  },
});
