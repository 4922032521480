import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d2bae128"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "CheckboxUI-Tip Tip"
}
const _hoisted_2 = ["checked", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HintUI = _resolveComponent("HintUI")!

  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["CheckboxUI Switchbox", [_ctx.row && 'CheckboxUI_row', _ctx.disabled && 'CheckboxUI_disabled']])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.hint)
            ? (_openBlock(), _createBlock(_component_HintUI, { key: 0 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.hint), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      type: "checkbox",
      class: "Switchbox-Hidden",
      checked: _ctx.value,
      disabled: _ctx.disabled,
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.setValue && _ctx.setValue(...args)), ["prevent"]))
    }, null, 8, _hoisted_2),
    _createElementVNode("div", {
      class: _normalizeClass(["CheckboxUI-Visible Switchbox-Visible", [_ctx.row && 'Switchbox-Visible_small']])
    }, null, 2)
  ], 2))
}