
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  name: "EmojisBlock",
  computed: {
    ...mapState("room", ["emojis"]),
  },
  methods: {
    reactionIcon(emoji: string) {
      switch (emoji) {
        case ":pile_of_poo:":
          return "&#128169;";

        case ":face_with_tongue:":
          return "&#128539;";

        case ":face_with_tears_of_joy:":
          return "&#128514;";

        case ":smiling_face_with_heart-eyes:":
          return "&#128525;";

        case ":grimacing_face:":
          return "&#128556;";
      }
    },
  },
});
