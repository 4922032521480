
import { defineComponent } from "vue";
import { mapMutations } from "vuex";

import { HTTP } from "@/api/api";

import { IMaskComponent } from "vue-imask";

export default defineComponent({
  name: "InputUI",
  components: {
    IMaskComponent,
  },
  props: {
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: [Number, String],
      required: true,
    },
    textarea: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Boolean,
      default: true,
    },
    errorText: {
      type: String,
      default: "Некорректно!",
    },
    random: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    styled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:value"],
  data: () => ({
    numberModel: "",
  }),
  methods: {
    ...mapMutations({
      setPopupData: "SET_POPUP_DATA",
    }),
    onInput(event: Event) {
      const input = event.target as HTMLInputElement;

      this.$emit("update:value", input.value);
    },
    onClear() {
      this.$emit("update:value", "");
    },
    async onRandom() {
      let nickname = "";
      let errorMsg = "";

      try {
        await HTTP("GET", "nickname")
          .then((data: any) => {
            nickname = data?.message || "";
          })
          .catch(() => {
            errorMsg = "Не удалось создать случайное имя.";
          });

        this.$emit("update:value", nickname);
      } catch (error: any) {
        errorMsg = "Не удалось создать случайное имя.";
      }

      errorMsg &&
        this.setPopupData({
          status: "error",
          title: "Ошибка",
          description: errorMsg,
        });
    },
  },
});
