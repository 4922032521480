import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6319b300"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "EmojisBlock" }
const _hoisted_2 = ["innerHTML", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.emojis, (emoji, index) => {
      return (_openBlock(), _createElementBlock("span", {
        innerHTML: _ctx.reactionIcon(emoji),
        class: "EmojisBlock-Emoji",
        key: index,
        title: emoji
      }, null, 8, _hoisted_2))
    }), 128))
  ]))
}