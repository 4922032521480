
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import PopupUI from "@/components/UI/PopupUI.vue";

export default defineComponent({
  name: "PopupAnsweredPlayers",
  components: {
    PopupUI,
  },
  data: () => ({
    isList: false,
  }),
  computed: {
    ...mapGetters({
      isLocal: "room/isLocal",
      slowPlayers: "room/slowPlayers",
    }),
  },
  mounted() {
    setTimeout(() => (this.isList = true), 500);
  },
});
