
import { defineComponent } from "vue";
import { mapState, mapMutations, mapActions } from "vuex";

import InputUI from "@/components/UI/InputUI.vue";
import ButtonUI from "@/components/UI/ButtonUI.vue";
import HintUI from "@/components/UI/HintUI.vue";
import HeaderHome from "@/components/HeaderBlock/HeaderHome.vue";
import PopupEmail from "@/components/Popups/PopupEmail.vue";
import PopupOferta from "@/components/Popups/PopupOferta.vue";
import PopupRefund from "@/components/Popups/PopupRefund.vue";
import PopupPromocode from "@/components/Popups/PopupPromocode.vue";

export default defineComponent({
  name: "ProfilePage",
  components: {
    InputUI,
    ButtonUI,
    HintUI,
    HeaderHome,
    PopupEmail,
    PopupOferta,
    PopupRefund,
    PopupPromocode,
  },
  data: () => ({
    isMain: false,
    isShowAll: false,
  }),
  computed: {
    ...mapState(["popup_data"]),
    ...mapState("profile", [
      "is_auth",
      "profile_auth",
      "profile_data",
      "sponsor_link_success",
    ]),
    subscribeDate(): string {
      return (
        this.profile_data.payments_histories?.find(
          (item: any) => item.status === "Подписка оформлена"
        )?.date || ""
      );
    },
  },
  watch: {
    is_auth(is_auth: boolean) {
      this.isAuthHandler(is_auth);
    },
  },
  methods: {
    ...mapMutations({
      setPopupData: "SET_POPUP_DATA",
    }),
    ...mapActions({
      signOut: "profile/signOut",
      getAllInfo: "profile/getAllInfo",
      subscribe: "profile/subscribe",
      onRenew: "profile/renew",
      unsubscribe: "profile/unsubscribe",
      getPayments: "profile/getPayments",
      generateSponsorLink: "profile/generateSponsorLink",
      cancelSponsorship: "profile/cancelSponsorship",
    }),
    showAll() {
      this.isShowAll = true;
    },
    isAuthHandler(is_auth: boolean) {
      if (is_auth === null) {
        setTimeout(() => this.isAuthHandler(this.is_auth), 500);
      } else if (is_auth) {
        this.getAllInfo();
        this.isMain = true;
      } else {
        this.$router.push("/");
      }
    },
  },
  mounted() {
    this.isAuthHandler(this.is_auth);
  },
});
