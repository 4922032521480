import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53ecced9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "PasswordUI-Wrapper" }
const _hoisted_2 = { class: "PasswordUI-Title" }
const _hoisted_3 = { class: "PasswordUI-Inner" }
const _hoisted_4 = ["onKeyup", "onInput"]
const _hoisted_5 = { class: "PasswordUI-Hint" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopupUI = _resolveComponent("PopupUI")!

  return (_openBlock(), _createBlock(_component_PopupUI, {
    class: "PasswordUI",
    noparallax: "",
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h2", _hoisted_2, [
          _renderSlot(_ctx.$slots, "title", {}, undefined, true)
        ]),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.length, (item, index) => {
            return (_openBlock(), _createElementBlock("input", {
              ref_for: true,
              ref: "inputs",
              type: "number",
              placeholder: "✖",
              class: "PasswordUI-Input",
              key: index,
              onKeyup: ($event: any) => (_ctx.onKeyup($event, index)),
              onInput: ($event: any) => (_ctx.onInput($event, index))
            }, null, 40, _hoisted_4))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "hint", {}, undefined, true)
        ])
      ])
    ]),
    _: 3
  }))
}