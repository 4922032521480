
import { defineComponent } from "vue";
import { mapState, mapMutations, mapActions } from "vuex";
import { useCookies } from "vue3-cookies";

import BackgroundUI from "@/components/UI/BackgroundUI.vue";

import TableRP from "@/components/RoomPage/TableRP.vue";
import AsideRP from "@/components/RoomPage/AsideRP.vue";

import PopupSettings from "@/components/Popups/PopupSettings.vue";
import PopupRules from "@/components/Popups/PopupRules.vue";
import PopupNickname from "@/components/Popups/PopupNickname.vue";
import PopupSetDashboard from "@/components/Popups/PopupSetDashboard.vue";
import PopupVpn from "@/components/Popups/PopupVpn.vue";

const { cookies } = useCookies();
const cookiesConfig = JSON.stringify({
  expireTimes: "30d",
  path: "/",
  domain: "",
  secure: true,
  sameSite: "Lax",
});

export default defineComponent({
  name: "RoomPage",
  components: {
    BackgroundUI,
    TableRP,
    AsideRP,
    PopupSettings,
    PopupRules,
    PopupNickname,
    PopupSetDashboard,
    PopupVpn,
  },
  data: () => {
    return {
      isMain: false,
      isAside: false,
      isPopupVpn: false,
    };
  },
  computed: {
    ...mapState(["mobile_flag", "popup_data"]),
  },
  methods: {
    ...mapMutations({
      setPopupData: "SET_POPUP_DATA",
      setLeaderboard: "room/SET_LEADERBOARD",
    }),
    ...mapActions({
      setDashboard: "room/setDashboard",
    }),
    setIsPopupVpn(flag: boolean, cookie = false) {
      this.isPopupVpn = flag;
      cookie && cookies.set("vpn_notice", "true", cookiesConfig);
    },
  },
  created() {
    if (!cookies.get("room_data")) {
      this.$router.push("/");
    }

    this.setLeaderboard(null);
  },
  mounted() {
    fetch("https://ipapi.co/json/").then((result: any) =>
      result.json().then((data: any) => {
        if (data.country === "RU" || cookies.get("vpn_notice")) {
          this.setIsPopupVpn(false);
        }
      })
    );

    setTimeout(() => (this.isMain = true), 250);
    setTimeout(() => (this.isAside = true), 750);
  },
});
