import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackgroundUI = _resolveComponent("BackgroundUI")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_TableTP = _resolveComponent("TableTP")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["TotalPage", _ctx.mobile_flag && 'GridBg'])
  }, [
    (!_ctx.mobile_flag)
      ? (_openBlock(), _createBlock(_component_BackgroundUI, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_Transition, { name: "slide-left" }, {
      default: _withCtx(() => [
        (_ctx.isButton)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "Pause Icon Icon_black",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onPause && _ctx.onPause(...args)))
            }, [
              _createVNode(_component_inline_svg, {
                title: "Приостановить игру",
                class: "Icon-Image",
                src: 
            require(_ctx.isDashboard && _ctx.game_status
              ? '@/assets/icons/game-pause.svg'
              : '@/assets/icons/exit.svg')
          
              }, null, 8, ["src"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "slide-top" }, {
      default: _withCtx(() => [
        (_ctx.isMain && _ctx.leaderboard?.players)
          ? (_openBlock(), _createBlock(_component_TableTP, {
              key: 0,
              class: "TotalPage-Table",
              leaderboard: _ctx.leaderboard.players,
              "is-last": !_ctx.game_status
            }, null, 8, ["leaderboard", "is-last"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}